import React, { ReactElement } from 'react';
import { ThemeProvider } from '@nivo/core';
import { GaugeProps } from '../../types';
import GaugeBase from './GaugeBase';
import { buildGaugeGradients } from '../../utils';

/** Text theme to outline labels with a shadow */
const theme = {
    labels: {
        text: {
            fontWeight: 'bold',
            textShadow: '.1px .1px 2px  #000, -.1px -.1px 2px  #000, .1px -.1px 2px  #000, -.1px .1px 2px  #000'
        }
    }
};

/**
 * Emissions Fuel gauge, built from 2 Responsive Pie charts.
 *
 * @example
 * <Gauge value={5} max={10} chartHeight={400} />
 */
const Gauge = ({
    activeOuterRadiusOffset = 8,
    arcLabel = ({ value }) => `${value}%`,
    arcLabelsSkipAngle = 0,
    arcLabelsTextColor = 'white',
    arcLinkLabelsColor = { from: 'color' },
    arcLinkLabelsSkipAngle = 0,
    arcLinkLabelsTextColor = '#333333',
    arcLinkLabelsThickness = 1,
    borderColor,
    borderWidth = 0,
    colors,
    cornerRadius = 0.0,
    enableArcLinkLabels,
    endAngle = 90,
    fit = false,
    gradientColors,
    innerRadius = 0.7,
    isInteractive = false,
    margin = { top: 10, bottom: 20, left: 0, right: 0 },
    padAngle = 0,
    pinColor,
    startAngle = -90,
    ...otherProps
}: GaugeProps): ReactElement => {
    const { data, defs, fill } = buildGaugeGradients(gradientColors, pinColor);

    const props = {
        activeOuterRadiusOffset,
        arcLabel,
        arcLabelsSkipAngle,
        arcLabelsTextColor,
        arcLinkLabelsColor,
        arcLinkLabelsSkipAngle,
        arcLinkLabelsTextColor,
        arcLinkLabelsThickness,
        borderColor,
        borderWidth,
        colors,
        cornerRadius,
        data,
        defs,
        enableArcLinkLabels,
        endAngle,
        fill,
        fit,
        innerRadius,
        isInteractive,
        margin,
        padAngle,
        startAngle,
        ...otherProps
    };

    return (
        <ThemeProvider theme={theme}>
            <GaugeBase {...props} theme={theme} />
        </ThemeProvider>
    );
};

export default Gauge;
