import { Grid, Typography } from '@methanesat/ui-components';
import { OneValueBar } from '../../../../../components/Data/OneValueBar';
import { useTranslate } from '../../../../../hooks';

export const OGISummary = ({ features }: { features: { layer_name: string }[] }) => {
    const t = useTranslate();

    const ogiTypeCounts = features
        .map((feature) => feature.layer_name)
        .reduce(
            (acc: { [key: string]: number }, curr: string) => {
                if (curr in acc) {
                    acc[curr]++;
                } else {
                    acc[curr] = 1;
                }
                return acc;
            },
            {} as { [key: string]: number }
        );

    return (
        <Grid container marginTop={4} rowSpacing={1}>
            {/* header */}
            <Grid item xs>
                <Typography variant="h5">
                    {t('emissionsMapPage.infrastructure.ogiNearEmissions.nearbyFacilitiesTitle', {
                        count: features.length
                    })}
                </Typography>
            </Grid>
            {/* bars */}
            <Grid item xs={12}>
                {/* holds all bars */}
                <Grid container rowSpacing={1} flexDirection="column">
                    {Object.entries(ogiTypeCounts).map(([key, value]) => {
                        return (
                            <Grid item key={key}>
                                {/* one bar & labels */}
                                <OneValueBar
                                    percentFill={(100 * value) / features.length}
                                    valueLabel={value}
                                    text={t(`emissionsMapPage.infrastructure.categoriesPlural.${key}`)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            {/* footnote */}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Typography variant="subtitle2" component="span">
                    {t('emissionsMapPage.infrastructure.ogiNearEmissions.plumes.nearbyFacilitiesDistanceNote', {
                        radiusMeters: 500
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
};
