import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectGlobalDate, selectPlatform, setFetchingDrawerInfo } from '../../reducers';
import {
    AreaEmissionsProducts,
    MethaneLayerIds,
    MSatPickInfo,
    PickInfoToPrioritize,
    Platforms,
    StacFeature,
    StacFeatureWithLayerId
} from '../../types';
import {
    getGriddedDataAndPropertiesFromStac,
    getTimeBoundCaptures,
    modifySearchByPoint,
    modifySearchCollection,
    modifySearchItem
} from './';

import type { RootState } from '../../store';
import { getDateRangeFromPlatform } from '../../utils';
export type StacSearchParams = {
    lat: number;
    lng: number;
    date: number;
    platform: Platforms;
    collectionId: string;
    itemId: string;
    featureType: string;
};

/**
 * Custom hook that retrieves layer information as a `PickInfoToPrioritize` based on the selected STAC feature.
 * @returns The layer information or null if there is no selection or an error.
 */
export const useGetLayerInfoFromUrl = () => {
    const dispatch = useDispatch();
    const { selectedStacCollectionId, selectedStacItemId, selectedFeatureType, selectedFeatureCoordinates } =
        useSelector((state: RootState) => state.pages.emissions.selectedFeature.selectedMethaneFeature);
    const selectedPlume = useSelector((state: RootState) => state.pages.emissions.selectedFeature.selectedPlume);
    const globalDate = useSelector(selectGlobalDate);
    const platform = useSelector(selectPlatform);

    const [layerInfo, setLayerInfo] = useState<PickInfoToPrioritize | null>(null);

    useEffect(() => {
        const fetchLayerInfo = async () => {
            if (
                selectedStacCollectionId &&
                selectedStacItemId &&
                selectedFeatureType &&
                selectedFeatureCoordinates.length !== 0
            ) {
                dispatch(setFetchingDrawerInfo(true));

                const searchParams: StacSearchParams = {
                    lat: selectedFeatureCoordinates[1],
                    lng: selectedFeatureCoordinates[0],
                    date: globalDate,
                    platform: platform,
                    collectionId: selectedStacCollectionId,
                    itemId: selectedStacItemId,
                    featureType: selectedFeatureType
                };

                try {
                    let info: Partial<PickInfoToPrioritize> | null = null;
                    if (selectedFeatureType === MethaneLayerIds.plumeEmissionRate) {
                        info = selectedPlume && getPlumeInfo(selectedPlume);
                    } else {
                        info = await buildLayerInfo(searchParams);
                    }
                    setLayerInfo(info as PickInfoToPrioritize);
                } catch (error) {
                    console.error('Error fetching layer info:', error); //eslint-disable-line
                    setLayerInfo(null);
                } finally {
                    dispatch(setFetchingDrawerInfo(false));
                }
            }
        };

        fetchLayerInfo();
    }, [
        selectedStacCollectionId,
        selectedFeatureCoordinates,
        selectedStacItemId,
        selectedFeatureType,
        selectedPlume,
        globalDate,
        platform
    ]);

    return layerInfo;
};

/**
 * This function is used to build the drawer info from the URL for targets and area emissions.
 */
export const buildLayerInfo = async (searchParams: StacSearchParams): Promise<Partial<PickInfoToPrioritize>> => {
    switch (searchParams.featureType) {
        case MethaneLayerIds.targets: {
            // TODO: revisit getCaptureInfo in DP-4233. It may not do what we want.
            // https://methanesat.atlassian.net/browse/DP-4233
            const info = await getCaptureInfo(searchParams);
            return {
                layer: {
                    id: MethaneLayerIds.targets
                } as MSatPickInfo['layer'],
                object: info
            };
        }
        case MethaneLayerIds.areaEmissionRaster: {
            const info = await getAreaEmissionsInfo(searchParams);
            return {
                layer: {
                    id: MethaneLayerIds.areaEmissionRaster
                } as MSatPickInfo['layer'],
                object: info as StacFeature,
                coordinate: [searchParams.lng, searchParams.lat]
            };
        }
        // Other layers won't be called with this function as they will not pass validation, but the
        // function can't lack an ending return statement as its return type does not include 'undefined'
        default:
            throw new Error(`Unsupported layer type: ${searchParams.featureType}`);
    }
};

const getProductFromCollectionId = (collectionId: string) => {
    if (collectionId.includes('Level4')) return AreaEmissionsProducts.l4;
    if (collectionId.includes('Level3')) return AreaEmissionsProducts.l3;
};

/**
 * Fetches a specific STAC item/one capture of a target
 */
export const getCaptureInfo = async (params: StacSearchParams): Promise<StacFeature> => {
    const { lat, lng, date, platform, collectionId, itemId } = params;
    const product = getProductFromCollectionId(collectionId);
    const search = modifySearchItem(itemId, modifySearchCollection(product, platform, modifySearchByPoint(lng, lat)));
    const dateRange = getDateRangeFromPlatform(platform);

    try {
        const captures = await getTimeBoundCaptures(new Date(date), search, dateRange);
        const dataObject = captures && captures.features && captures.features[0];
        if (!dataObject) {
            throw new Error('No target found');
        }
        return dataObject;
    } catch (error) {
        throw new Error('Error fetching target info');
    }
};

/**
 * Fetches the methane value at a coordinate, as well as the properties of the STAC item that the
 * area emissions belongs to.
 */
export const getAreaEmissionsInfo = async (params: StacSearchParams) => {
    const { lat, lng, platform, itemId, collectionId } = params;
    const product = getProductFromCollectionId(collectionId);

    try {
        const dataObject = await getGriddedDataAndPropertiesFromStac(lng, lat, itemId, product, platform);
        if (!dataObject) {
            throw new Error('No area emission found');
        }
        return dataObject;
    } catch (error) {
        throw new Error('Error fetching area emission info');
    }
};

/**
 * Formats the STAC feature for a plume into a `PickInfoToPrioritize` that can be used by `handleMapInfo`
 */
export const getPlumeInfo = (plume: StacFeatureWithLayerId): Partial<PickInfoToPrioritize> => {
    return {
        layer: {
            id: plume.layerId
        } as MSatPickInfo['layer'],
        object: plume,
        coordinate: (plume?.geometry as GeoJSON.Point).coordinates,
        index: plume.index
    };
};
