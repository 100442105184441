import { useSelector } from 'react-redux';

import {
    Accordion,
    AccordionSummary,
    ExpandMoreIcon,
    AccordionDetails,
    Typography,
    Box,
    CircleIcon,
    Grid,
    Divider
} from '@methanesat/ui-components';
import { useTranslate } from '../../../../../hooks';
import { PointOGIMGeoJSONProperties, PipelineFeatureProperties } from '../../../../../types';
import { DataRowsModalBody } from '../../Layout';
import { selectOgimColorByCompany } from '../../../../../reducers';
import { categoricalColors, colorArrayToHex } from '@methanesat/colors';

export const OGIDetails = ({ features }: { features: (PointOGIMGeoJSONProperties | PipelineFeatureProperties)[] }) => {
    const t = useTranslate();
    const ogimColors = useSelector(selectOgimColorByCompany);
    const unknownOperatorName = t('emissionsMapPage.mapModals.infrastructure.unknownOperator').toUpperCase();

    const companies = features
        .map((f) => ({
            companyName: (f.operators && f.operators[0]?.operator_name) || unknownOperatorName,
            companyId: (f.operators && f.operators[0]?.operator_id) ?? undefined
        }))
        .reduce<{ companyName: string; companyId: number | undefined }[]>((acc, current) => {
            const foundEntry = acc.find((entry) => entry.companyId === current.companyId);
            if (current.companyId && !foundEntry) {
                acc.push(current);
            }
            return acc;
        }, []);

    const featuresByCompany: { [key: string]: (PointOGIMGeoJSONProperties | PipelineFeatureProperties)[] } = {};
    features.forEach((f) => {
        const companyName = (f.operators && f.operators[0]?.operator_name) || unknownOperatorName;
        if (companyName in featuresByCompany) {
            featuresByCompany[companyName].push(f);
        } else {
            featuresByCompany[companyName] = [f];
        }
    });

    return (
        <Box marginTop={4}>
            {companies.map(({ companyId, companyName }) => {
                return (
                    <Accordion elevation={0} key={companyName}>
                        {/* company name */}
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 1 }}>
                            <CircleIcon
                                sx={{
                                    marginRight: 2,
                                    color: companyId
                                        ? ogimColors[companyId]
                                        : colorArrayToHex(categoricalColors.brightGreen),
                                    stroke: 'black',
                                    strokeWidth: '0.5px'
                                }}
                            />
                            <Typography variant="h6">
                                {companyName} ({featuresByCompany[companyName].length})
                            </Typography>
                        </AccordionSummary>
                        {/* company features */}
                        <AccordionDetails sx={{ paddingX: 1, marginTop: -2 }}>
                            {/* feature details */}
                            <Grid container rowSpacing={1}>
                                {featuresByCompany[companyName].map((feature, idx) => {
                                    return (
                                        <Grid item xs={12} key={feature.ogim_id}>
                                            <Typography variant="subtitle1">
                                                {t(`emissionsMapPage.infrastructure.categories.${feature.layerName}`)}
                                            </Typography>
                                            <DataRowsModalBody
                                                dataRows={[
                                                    {
                                                        label: t(
                                                            'emissionsMapPage.mapModals.infrastructure.facilityLabel'
                                                        ),
                                                        value: feature.fac_name
                                                    },
                                                    {
                                                        label: `${t(
                                                            'emissionsMapPage.mapModals.infrastructure.commodityLabel'
                                                        )}`,
                                                        value: feature.details.commodity
                                                    },
                                                    {
                                                        label: `${t(
                                                            'emissionsMapPage.mapModals.infrastructure.statusLabel'
                                                        )}`,
                                                        value: feature.ogim_status
                                                    }
                                                ]}
                                            />
                                            {idx < featuresByCompany[companyName].length - 1 && <Divider />}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};
