import { Box, Typography, useTheme } from '@methanesat/ui-components';

import { BelowNavDrawer, BelowNavDrawerProps } from '../BelowNavDrawer';
import { useCurrentBreakpoint, useTranslate } from '../../hooks';
import { useRef, useEffect } from 'react';
import { getBreakpointsDown } from '../../utils/breakpoints';

/**
 * Until there is a use case where we need to expose all of
 * the props from BelowNavDrawerProps, NoDataInfoDrawerProps is
 * only composed of a subset of props.
 *
 */
type NoDataInfoDrawerProps = Pick<BelowNavDrawerProps, 'open' | 'onClose'> & {
    onWidth: (width: number) => void;
};

/**
 * A drawer used to communicate to users that there is no methane data
 * in an area because we didn't look in the location of click or
 * because the environment prevented us from observing a location.
 */
const NoDataInfoDrawer = ({ open, onClose, onWidth }: NoDataInfoDrawerProps) => {
    const t = useTranslate();
    const theme = useTheme();
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    return (
        <BelowNavDrawer data-testid="no-data-drawer" open={open} onClose={onClose}>
            <Box sx={{ margin: 2 }}>
                <Typography variant="h3" gutterBottom>
                    {t('noDataInfoDrawer.title')}
                </Typography>
                <Typography>{t('noDataInfoDrawer.content')}</Typography>
            </Box>
        </BelowNavDrawer>
    );
};

export default NoDataInfoDrawer;
