import { Box } from '@mui/material';
import { CircleIcon } from '.';

interface OgiIconProps {
    colors: string[];
}

const OgiLegendIcon = ({ colors }: OgiIconProps) => {
    return (
        <Box marginLeft={'10px'} display="flex" alignItems="center">
            {colors.slice(0, 4).map((color, idx) => {
                return <CircleIcon sx={{ fill: color, marginLeft: '-10px' }} key={idx}></CircleIcon>;
            })}
        </Box>
    );
};

export default OgiLegendIcon;
