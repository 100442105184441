import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { Dispatch, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { log } from '@methanesat/log';
import {
    BaseMap,
    DeckGLRef,
    getBoundingBox,
    InitialMapViewState,
    Layer,
    MapStyles,
    PickingInfo,
    ViewStateChangeParameters
} from '@methanesat/maps';
import { Box } from '@methanesat/ui-components';
import { ActionCreatorWithPayload, UnknownAction } from '@reduxjs/toolkit';

import { GoogleBasemaps, maxZoomBaseMap, maxZoomOGITiles, wholeWorldBbox } from '../../consts';
import { TITILER_COLORMAP } from '../../consts/colors';
import { GOOGLE_MAPS_API_KEY, MAPBOX_TOKEN, ENVIRONMENT } from '../../environmentVariables';
import {
    useAreaEmissionIcon,
    useMethaneRasterLayers,
    useInfrastructureTileLayer,
    usePlumeEmissionRateLayer,
    useTargetLayers
} from '../../hooks/pages/emissions-map';
import { RootState } from '../../store';
import {
    selectPlatform,
    selectEmissionsMapStyle,
    selectEmissionsMapStyleUrl,
    selectFlooredZoom,
    selectGlobalDate,
    selectMethaneProduct,
    setPlatform,
    setEmissionsMapBBox,
    setEmissionsMapViewState,
    setGlobalDate,
    SLOW_selectEmissionsMapViewState,
    selectVisualizedStacCollectionId,
    selectVisualizedStacItemId,
    selectVisualizedFeatureType,
    setSelectedFeatureParams,
    setUrlParamsInitialized,
    resetSelectedFeatureParams
} from '../../reducers';
import {
    MSatPickInfo,
    PickInfoToPrioritize,
    ViewStatePropsQueryString,
    UrlFeatureToId,
    UrlFeatureParams
} from '../../types';
import {
    pickMultiplePrioritizedFilterdLayers,
    pickValidTopPriorityInfo,
    analytics,
    getDayEnd,
    setInLocalStorage
} from '../../utils';
import { EmissionsPageMapScale } from '../EmissionsPageMapScale';
import {
    validateUrlDateString,
    validateUrlPlatformParam,
    validateUrlCollectionId,
    validateUrlItemId,
    validateUrlFeatureType
} from '../../utils/urlValidators';
import { useGetLayerInfoFromUrl } from '../../hooks/data/getLayerInfoFromUrl';
import { playwrightAddViweport } from '../../../test/e2e/utils';
import { useTranslate } from '../../hooks';

/**
 * Emissions maps props.
 */
export interface EmissionsPageMapProps {
    /** Width of the screen for calculating modal placement when left panel is open. */
    leftWidthOffset?: number;
    /** Called when the map was click with the layer that was clicked if there is one. */
    handleMapInfo?: (info: PickInfoToPrioritize | PickInfoToPrioritize[] | undefined, isNoData: boolean) => void;
    /** Width of the screen for calculating modal placement when right panel is open. */
    rightWidthOffset?: number;
}

type QueryStringObject = Record<string, string>;

/**
 * Type guard using a predicate to ensure item is a Record.
 */
function IsRecord(item: unknown): item is Record<string, unknown> {
    return typeof item === 'object';
}

/**
 * Defines the information needed to add one piece of state to
 * the querystring correctly and later, parse the querystring (could be comma-separated)
 * and load the parsed data into the store.
 */
type StringValidator = (validateItem: string) => boolean;
type ObjectValidator = (validateItem: QueryStringObject) => boolean;

function isStringValidator(validator: unknown): validator is StringValidator {
    if (typeof validator !== 'function') return false;
    const result = validator('test');
    if (typeof result === 'object') return false;
    return true;
}

function isObjectValidator(validator: unknown): validator is ObjectValidator {
    if (typeof validator !== 'function') return false;
    const result = validator('test');
    if (typeof result === 'object') return false;
    return true;
}

interface UrlStateItem {
    /** String to use as the key in the querystring. */
    queryKey: string;
    /** Action creator imported from the page's reducers. Used to add state in to url to the store on reload. */
    action: ActionCreatorWithPayload<any, string>; // eslint-disable-line @typescript-eslint/no-explicit-any
    value: unknown;
    validate: StringValidator | ObjectValidator;
}

/**
 * Function to parse the querystring of a url.
 * Returns an object representing the querystring: { somekey: 'some-value' }.
 * If a key appears multiple times in the querystring, values are concatenated & separated by commas,
 * ex: { key: 'value-1,value-2'}.
 * Parsing comma-separated values must be done separately.
 *
 * TODO: Changing this function results in a server-side change & full refresh (at least sometimes).
 * Investigate possible unintended side-effects.
 */
export function parseQueryString(searchString: string): QueryStringObject {
    let cleanedSearchString = searchString;
    if (searchString[0] === '?') cleanedSearchString = cleanedSearchString.substring(1);

    const pairStrings = cleanedSearchString.split('&');
    const keyValPairs = pairStrings.map((pair) => pair.split('='));

    const result: QueryStringObject = {};

    for (let i = 0, l = keyValPairs.length; i < l; i++) {
        const [key, value] = keyValPairs[i];

        if (!key || !value) {
            continue;
        } else if (key in result) {
            result[key] = `${result[key]},${value}`;
        } else {
            result[key] = value;
        }
    }
    return result;
}

/**
 * Data massaging for URL search param values for some keys before they're added to the URL.
 */
export function modifyURLSearchValue(value: string, key: string) {
    switch (key) {
        case 'latitude':
        case 'longitude':
            /**
             * 5 decimal points gives us ~1m resolution and also keeps the URL only as long as needed.  Ideally keep the
             * URL as short as possible to keep it unobtrusive in contexts where it would be shared, e.g. by email.
             * See https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
             */
            return parseFloat(value).toFixed(5);
        default:
            return value;
    }
}

/**
 * Builds a URL query object from a value.
 * Currently supports numbers, strings, booleans, arrays & objects.
 */
export function buildSearchObject(queryKey: string, value: unknown[] | unknown): QueryStringObject {
    if (!value && typeof value !== 'boolean' && typeof value !== 'number') return {};
    if (Array.isArray(value)) {
        return { [queryKey]: value.map((v) => String(v)).join(',') };
    }

    // Using IsRecord seems silly, but typescript complains otherwise.
    // This prevents other type-related contortions.
    if (IsRecord(value)) {
        return Object.keys(value).reduce((newParams, key) => {
            return {
                ...newParams,
                [`${queryKey}-${key}`]: modifyURLSearchValue(`${value[key]}`, key)
            };
        }, {});
    }
    if (typeof value === 'number' || typeof value === 'boolean') return { [queryKey]: String(value) };

    if (typeof value === 'string') return { [queryKey]: value };

    log.error(`Cannot convert type ${typeof value} to QueryStringObject. ${value}`);

    return {};
}

/** Function to extract a value from a queryObject by key */
export function unpackQueryObject(queryObject: QueryStringObject, searchKey: string) {
    if (searchKey in queryObject) {
        return queryObject[searchKey];
    }
    return unpackNestedObject(queryObject, searchKey);
}

/**
 * Function to check a query object for prefixed keys and extract them into a new object.
 * Ex:
 * Calling a query object with the key 'view':
 * unpackNestedObject({ view-latitude: 31, view-longitude: -100, mapStyle: MapStyles.dark }, 'view')
 * returns: { latitude: 31, longitude: -100 }
 */
export function unpackNestedObject(queryObject: QueryStringObject, searchKey: string) {
    const parsedObject: QueryStringObject = {};
    const relevantKeys = Object.keys(queryObject).filter((key) => key.includes(`${searchKey}-`));

    for (let i = 0, l = relevantKeys.length; i < l; i++) {
        const relevantKey = relevantKeys[i];
        const strippedKey = relevantKey.replace(`${searchKey}-`, '');
        parsedObject[strippedKey] = queryObject[relevantKey];
    }
    return parsedObject;
}

export function validateUrlViewState(viewState: QueryStringObject) {
    for (const [key, value] of Object.entries(viewState)) {
        const newValue = parseFloat(value);
        if (isNaN(newValue) || (key === 'latitude' && (newValue > 90 || newValue < -90))) {
            return false;
        }
    }
    return true;
}

export function validateUrlMapStyles(mapStyle: string) {
    return Object.values(MapStyles).includes(mapStyle as (typeof MapStyles)[keyof typeof MapStyles]);
}

/**
 * Custom hook to keep the url querystring in sync with specific pieces of app state.
 * Updates the url
 * Accepts a list of object defining which keys & values the hook should add to the querystring
 * and how those key/value pairs should be parsed on page load.
 */
export function useSyncQueryString({
    urlStateItems,
    dispatch,
    urlUpdateFn
}: {
    urlStateItems: UrlStateItem[];
    dispatch: Dispatch<UnknownAction>;
    urlUpdateFn: (q: QueryStringObject) => void;
}) {
    /**
     * Handles the initial querystring on page load:
     * 1) extracts state information from the querystring
     * 2) dispatches the associated actions to the store
     */

    useEffect(() => {
        const { search } = window.location;
        const initialQueryStringObject = parseQueryString(search);
        let initialCollectionId = '';
        let initialItemId = '';
        let initialFeatureType = '';

        for (let i = 0, l = urlStateItems.length; i < l; i++) {
            const item = urlStateItems[i];
            const { action: actionFn, queryKey } = item;

            const newValue = unpackQueryObject(initialQueryStringObject, queryKey);

            // if the key isn't in the querystring or has no value, do nothing
            if (
                (!newValue && typeof newValue !== 'number') ||
                (typeof newValue === 'object' && Object.keys(newValue).length === 0)
            ) {
                continue;
            }

            const validator = item.validate;

            if (typeof newValue === 'object' && isObjectValidator(validator)) {
                const isValidated = validator(newValue);

                if (isValidated) {
                    dispatch(actionFn(newValue));
                }
            }

            if (typeof newValue === 'string' && isStringValidator(validator)) {
                const isValidated = validator(newValue);
                if (isValidated) {
                    if (queryKey === 'date') {
                        // Convert date string to timestamp
                        const timestamp = new Date(`${newValue} UTC`);
                        // Set the timestamp to the end of the day to
                        // be in sync with all other end-of-period dates in the app
                        const endOfDay = getDayEnd(timestamp);
                        dispatch(actionFn(endOfDay.getTime()));
                    } else if (queryKey === 'collection-id') {
                        initialCollectionId = newValue;
                    } else if (queryKey === 'item-id') {
                        initialItemId = newValue;
                    } else if (queryKey === 'feature-type') {
                        initialFeatureType = UrlFeatureToId.get(newValue as UrlFeatureParams) || '';
                    } else {
                        dispatch(actionFn(newValue));
                    }
                }
            }
        }
        // The three parameters are required, so the actions must be dispatched together
        if (initialCollectionId !== '' && initialItemId !== '' && initialFeatureType !== '') {
            dispatch(
                setSelectedFeatureParams({
                    collectionId: initialCollectionId,
                    itemId: initialItemId,
                    feature: initialFeatureType,
                    coordinates: [
                        Number(initialQueryStringObject['view-longitude']),
                        Number(initialQueryStringObject['view-latitude'])
                    ]
                })
            );
            analytics.openMethaneLayerDrawerFromUrl({
                collectionId: initialCollectionId,
                itemId: initialItemId,
                layerId: initialFeatureType
            });
            setInLocalStorage('introDismissed', true);
        }
        dispatch(setUrlParamsInitialized());
    }, []);

    // update query string on state changes
    useEffect(() => {
        let newQueryObj = {};
        // each time any dependency changes, get the current state of all dependencies
        // prevents missing some state updates due to throttled router.replace
        for (let i = 0, l = urlStateItems.length; i < l; i++) {
            const item = urlStateItems[i];
            const { queryKey } = item;

            const newSearchParams = buildSearchObject(queryKey, item.value);
            newQueryObj = {
                ...newQueryObj,
                ...newSearchParams
            };
        }
        urlUpdateFn(newQueryObj);
    }, urlStateItems);
}

const PICKING_RADIUS_PIXELS = 10;

/**
 * Emissions map gives context to where methane plumes are, who's emitting them, and how much is being emitted.
 */
function EmissionsPageMap({ handleMapInfo, rightWidthOffset = 0 }: EmissionsPageMapProps): ReactElement {
    const deck = useRef<DeckGLRef | null>(null);
    const dispatch = useDispatch();
    const t = useTranslate();

    //platform
    const platform = useSelector(selectPlatform);

    // viewState
    const viewState = useSelector(SLOW_selectEmissionsMapViewState);
    const flooredZoom = useSelector(selectFlooredZoom);

    const mapStyleUrl = useSelector(selectEmissionsMapStyleUrl);
    const mapStyle = useSelector(selectEmissionsMapStyle);
    const gestureHandling = useSelector((state: RootState) => state.pages.emissions.mapInterface.gestureHandling);

    // methane layers
    // l4 layers
    const methaneRasterLayers = useMethaneRasterLayers(TITILER_COLORMAP);
    const plumeEmissionRateLayer = usePlumeEmissionRateLayer();
    // target layer
    const targetLayer = useTargetLayers(viewState.zoom);

    // date selected
    const globalDate = new Date(useSelector(selectGlobalDate));

    // if the data is L3 or L4
    const product = useSelector(selectMethaneProduct);

    // selected area emissions layers
    const selectedAreaEmissionsLayer = useAreaEmissionIcon();

    const tileInfrastructureLayer = useInfrastructureTileLayer();

    // array of deck.gl layers passed to map
    // These are ordered by the order in which they should be stacked by deck.gl:
    // layers early in the array are stacked below those later in the array
    const layers: Layer[] = [
        targetLayer,
        ...methaneRasterLayers,
        selectedAreaEmissionsLayer,
        plumeEmissionRateLayer,
        tileInfrastructureLayer
    ].filter((a) => !!a) as Layer[];

    // collection selected
    const selectedStacCollectionId = useSelector(selectVisualizedStacCollectionId);

    // item selected
    const selectedStacItemId = useSelector(selectVisualizedStacItemId);

    // feature selected
    const selectedFeatureType = useSelector(selectVisualizedFeatureType);

    const layerInfo = useGetLayerInfoFromUrl();

    // Listens for changes to `layerInfo` which is set after querying for a feature from the URL parameters.
    // If `layerInfo` isn't null, then it calls `handleMapInfo` to open the drawer and add the map highlights.
    useEffect(() => {
        if (layerInfo) {
            handleMapInfo?.(layerInfo, false);
        }
    }, [layerInfo]);

    // function to update the URL no more than once every 500 ms
    // all state-related URL updates are throttled, not just the ones that *must* be throttled
    // because there is no down-side to throttling all updates
    const debouncedRouterReplace = useCallback(
        debounce(
            (newQuery: QueryStringObject) => {
                const searchParams = new URLSearchParams();

                // Add or update parameters based on the new state
                Object.entries(newQuery).forEach(([key, value]) => {
                    if (value !== '') {
                        searchParams.set(key, value);
                    } else {
                        searchParams.delete(key);
                    }
                });

                const querystr = searchParams.toString();
                history.replaceState({ ...history.state }, '', `?${querystr}`);
            },
            500,
            {
                trailing: true
            }
        ),
        []
    );

    const getTooltip = useCallback((info: PickingInfo) => {
        const infoLayer = info.layer;
        if (!infoLayer) return null;
        if (!info.object) return null;

        if (
            (infoLayer.id === 'targets' || infoLayer.id === 'targetsmultiple-capture-icon') &&
            info.object.properties.monthlyCaptureCount > 1
        ) {
            return (
                info.object && {
                    text: `${info.object.properties.monthlyCaptureCount} ${t('readings')}`,
                    style: {
                        position: 'absolute',
                        top: '-50px',
                        left: '-35px',
                        borderRadius: '2px',
                        color: '#ffffff',
                        fontSize: '16px'
                    }
                }
            );
        }
    }, []);

    // List defining the state useSyncQueryString should add to & extract from the URL's querystring
    // Also defines how to handle each querystring parameter's value on page load

    const date = globalDate.toISOString().split('T')[0];
    const { latitude, longitude, zoom } = viewState;
    const cleanViewState = { latitude, longitude, zoom: zoom.toFixed(2) };
    const featureParam =
        [...UrlFeatureToId.entries()].find(([_key, value]) => value === selectedFeatureType)?.[0] || '';
    const urlStateItems: UrlStateItem[] = useMemo(() => {
        return [
            {
                queryKey: 'view',
                value: cleanViewState,
                action: setEmissionsMapViewState,
                validate: validateUrlViewState
            },
            {
                queryKey: 'date',
                value: date,
                action: setGlobalDate,
                validate: validateUrlDateString
            },
            {
                queryKey: 'platform',
                value: platform,
                action: setPlatform,
                validate: validateUrlPlatformParam
            },
            {
                queryKey: 'collection-id',
                value: selectedStacCollectionId,
                action: setSelectedFeatureParams,
                validate: validateUrlCollectionId
            },
            {
                queryKey: 'item-id',
                value: selectedStacItemId,
                action: setSelectedFeatureParams,
                validate: validateUrlItemId
            },
            {
                queryKey: 'feature-type',
                value: featureParam,
                action: setSelectedFeatureParams,
                validate: validateUrlFeatureType
            }
        ];
    }, [mapStyle, viewState, date, platform, selectedStacCollectionId, selectedStacItemId, featureParam]);

    useSyncQueryString({
        urlStateItems,
        dispatch,
        urlUpdateFn: debouncedRouterReplace
    });

    const throttledUpdateBBox = useMemo(() => {
        return throttle(
            function (viewState: InitialMapViewState) {
                const bbox = getBoundingBox(viewState);
                dispatch(setEmissionsMapBBox(bbox));
            },
            500,
            { leading: false, trailing: true }
        );
    }, []);

    async function onMapClick(info: MSatPickInfo) {
        if (!deck?.current) return;

        if (selectedStacCollectionId || selectedStacItemId || selectedFeatureType) {
            dispatch(resetSelectedFeatureParams());
        }

        // get pickinfo from all layers with features
        // at the coordinates the user clicked
        const layersOfInfoAtPoint: PickInfoToPrioritize[] = deck.current.pickMultipleObjects({
            x: info.x,
            y: info.y,
            depth: layers.length,
            radius: PICKING_RADIUS_PIXELS
        }) as PickInfoToPrioritize[];

        // keep infos with valid objects and bitmaps
        const filteredLayersOfInfoAtPoint = layersOfInfoAtPoint.filter((info) => {
            return (typeof info.object === 'object' && info.object) || info.bitmap;
        });

        /** get top priority layer */
        const prioritizedInfo = await pickValidTopPriorityInfo(
            filteredLayersOfInfoAtPoint,
            globalDate,
            product,
            platform
        );

        /**
         * All infos will be features from the same deck.gl layer. Thus, we can show data for multiple
         * features in the OGI layer, or the plumes layer, etc.
         * This will NOT show data for multiple features in different layers in the same drawer.
         */
        const multiplePrioritizedInfos = pickMultiplePrioritizedFilterdLayers(
            filteredLayersOfInfoAtPoint,
            prioritizedInfo?.layer.id
        );

        /**
         * If user clicked a negative emission,
         * they clicked a layer, but got no prioritized info to show
         */
        const clickedNoData = !prioritizedInfo;

        // Reposition the map if what you clicked will be behind a panel
        const deckElementRect =
            !deck.current.deck || !deck.current.deck['canvas']
                ? null
                : deck.current.deck['canvas'].getBoundingClientRect();
        const deckWidth = deckElementRect?.width;
        const repositionMap =
            deckWidth && // have a deck element to work with
            !clickedNoData && // clicked empty data/no layers
            (prioritizedInfo?.x ?? 0) > deckWidth + rightWidthOffset; // feature is hidden behind the drawer

        // call handleMapInfo - opens the info drawer - with the info that was clicked.
        if (
            !clickedNoData &&
            flooredZoom >= maxZoomOGITiles - 1 &&
            multiplePrioritizedInfos?.length &&
            multiplePrioritizedInfos?.length > 1
        ) {
            handleMapInfo?.(multiplePrioritizedInfos, clickedNoData);
        } else if (prioritizedInfo) {
            handleMapInfo?.(prioritizedInfo, clickedNoData);
        } else {
            handleMapInfo?.(undefined, clickedNoData);
        }

        if (repositionMap) {
            // reposition longitudinally only
            const recenteredViewState: ViewStatePropsQueryString = {
                ...viewState,
                longitude: prioritizedInfo?.coordinate[0] || viewState.longitude
            };
            dispatch(setEmissionsMapViewState(recenteredViewState as ViewStatePropsQueryString));
        }
    }

    // handler for viewStateChange event on Deck
    function onViewStateChange({ viewState }: ViewStateChangeParameters) {
        // this allows us to exclude non-serializable properties
        // of viewState from the store (ex. transitionEasing )
        const { latitude, longitude, zoom, bearing, pitch, altitude, height, width } = viewState;

        // We remove any undefineds so the Google base map is able to send a partial
        // viewState update without affecting other values
        const newViewState = Object.entries({
            latitude,
            longitude,
            zoom,
            bearing,
            pitch,
            altitude,
            height,
            width
        }).reduce((createdViewState, [key, value]) => {
            if (value !== undefined) {
                return { ...createdViewState, [key]: value };
            }
            return createdViewState;
        }, {});
        dispatch(setEmissionsMapViewState(newViewState as ViewStatePropsQueryString));

        // adding Viewport to window for playwright e2e tests
        if (ENVIRONMENT !== 'production') {
            playwrightAddViweport(viewState);
        }

        throttledUpdateBBox(viewState as InitialMapViewState);
    }

    const [west, south, east, north] = wholeWorldBbox;
    const latLngBounds = { west, south, east, north };

    return (
        <>
            {GOOGLE_MAPS_API_KEY ? (
                /**
                 * Adding a grayscale filter to images in the basemap makes the
                 * Google base map gray without changing the colors of the
                 * deck.gl layers. The filter values were arrived at by testing
                 * various combinations with Product.
                 */
                <Box
                    id="emissions-map-google-container"
                    data-testid="emissions-map-google-container"
                    component="span"
                    sx={{
                        '& img': {
                            filter: 'grayscale(0.97) brightness(1.13) contrast(1.14) opacity(0.96) saturate(4.26)'
                        }
                    }}
                >
                    <BaseMap
                        id="emissions-map-google"
                        data-testid="emissions-map-google"
                        gestureHandling={gestureHandling}
                        googleMapsAPIKey={GOOGLE_MAPS_API_KEY}
                        viewState={{
                            ...viewState
                        }}
                        onViewStateChange={onViewStateChange}
                        layers={layers}
                        onClick={(info) => onMapClick(info as MSatPickInfo)}
                        ref={deck}
                        restriction={{
                            latLngBounds,
                            strictBounds: false
                        }}
                        maxZoom={maxZoomBaseMap}
                        getTooltip={getTooltip}
                        mapId={GoogleBasemaps.roadmapLight.id}
                    />
                </Box>
            ) : (
                <Box data-testid="emissions-map-deck-container">
                    <BaseMap
                        data-testid="emissions-map-deck"
                        viewState={{
                            ...viewState,
                            maxZoom: maxZoomBaseMap
                        }}
                        onViewStateChange={onViewStateChange}
                        getTooltip={getTooltip}
                        layers={layers}
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                        mapStyle={mapStyleUrl}
                        onClick={(info) => onMapClick(info as MSatPickInfo)}
                        pickingRadiusPixels={PICKING_RADIUS_PIXELS}
                        ref={deck}
                        Scale={<EmissionsPageMapScale />}
                    />
                </Box>
            )}
        </>
    );
}

export default EmissionsPageMap;
