import { AddBoxIcon, IndeterminateBoxIcon } from '.';
import { Grid } from '../Layout';

const ZoomIcon = () => {
    return (
        <Grid container aria-hidden={true} sx={{ height: 20, width: 20, paddingLeft: 0.3 }}>
            <AddBoxIcon sx={{ height: 10, width: 14, float: 'right' }} />
            <IndeterminateBoxIcon sx={{ height: 10, width: 14 }} />
        </Grid>
    );
};

export default ZoomIcon;
