import { startCase, toLower, upperFirst } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { useOGIAPI, useTranslate } from '../../../../../hooks';
import {
    cleanOgiData,
    MSatPickInfo,
    OGILayerIds,
    OGITileFeatureProperties,
    PointOGIMGeoJSONProperties
} from '../../../../../types';
import { analytics, formatCoordinates, formatNumber } from '../../../../../utils';
import { MapControlAccordionProps } from '../../../../MapControls/MapControlAccordion';
import { InfrastructureModalFooter } from '../../Footers';
import { InfrastructureModalHeader } from '../../Headers';
import { DataRowsModalBody, DataSkeleton, MapDataContainer } from '../../Layout';

export const PointInfrastructureDrawer = ({
    info,
    accordion,
    AccordionProps
}: {
    info: MSatPickInfo<GeoJSON.Point, OGITileFeatureProperties>;
    accordion?: boolean;
    AccordionProps?: MapControlAccordionProps;
}) => {
    const t = useTranslate();

    const dataAndLoadingState = useOGIAPI<PointOGIMGeoJSONProperties>(info);

    const pointMetadata = dataAndLoadingState?.data;
    const loadingState = dataAndLoadingState?.loadingState;

    /**
     * `infrastructure_operators` is an array because we may eventually have multiple operators over time.
     */
    const currentOperatorName =
        pointMetadata?.operators === null || !pointMetadata?.operators[0]
            ? t(`emissionsMapPage.mapModals.infrastructure.unknownOperator`)
            : pointMetadata?.operators[0].operator_name;

    const {
        properties: { layerName }
    } = info.object;

    const metadataForAnalytics = useMemo(
        () =>
            pointMetadata && {
                id: `${pointMetadata.ogim_id}`,
                type: layerName,
                latitude: info.object?.geometry.coordinates[1],
                longitude: info.object?.geometry.coordinates[0],
                operatorName: currentOperatorName,
                accordion
            },
        [pointMetadata, layerName, accordion]
    );
    // called from useEffect below or Accordion onChange event
    const logAnalytics = useCallback(
        () => metadataForAnalytics && analytics.openPointInfrastructureDetails(metadataForAnalytics),
        [metadataForAnalytics, accordion]
    );

    // tracks user opening details *not* in an accordion
    // viewing details in accordions are tracked in onChange events
    useEffect(() => {
        !accordion && logAnalytics();
    }, [pointMetadata?.ogim_id, layerName, accordion]);

    // if data has not yet loaded, displays a skeleton
    if (loadingState) {
        return <DataSkeleton />;
    }

    if (!pointMetadata) return <></>;

    // limits OGIM status to one word so we can use it as a key to get the right translated string from locales
    const ogimStatus = pointMetadata.ogim_status;
    let cleanOgimStatus = undefined;
    if (typeof ogimStatus === 'string') {
        cleanOgimStatus = cleanOgiData(ogimStatus, ' ');
    }

    // limits data source to one item
    const source = pointMetadata.sources[0];

    const headerProps = {
        layerName:
            layerName === OGILayerIds.stationsOther && pointMetadata.fac_type
                ? upperFirst(pointMetadata.fac_type)
                : t(`emissionsMapPage.infrastructure.categories.${layerName}`) || upperFirst(layerName),
        operatorName: currentOperatorName,
        sourceDate: source?.src_date
    };

    const dataRows = [
        // metadata relevant to all types of point infrastructure
        ...(pointMetadata.fac_name
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.infrastructure.facilityLabel'),
                      value: pointMetadata.fac_name
                  }
              ]
            : []),
        ...(pointMetadata.install_date
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.installedLabel')}`,
                      value: `${pointMetadata.install_date}`
                  }
              ]
            : []),
        ...(pointMetadata.ogim_status
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.statusLabel')}`,
                      value: upperFirst(t(`emissionsMapPage.mapModals.common.ogimStatus.${cleanOgimStatus}`))
                  }
              ]
            : []),
        // metadata relevant to wells only
        ...(pointMetadata.details.drill_type
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.drillTypeLabel')}`,
                      value: upperFirst(pointMetadata.details.drill_type)
                  }
              ]
            : []),
        // metadata relevant to crude oil refineries only
        ...(pointMetadata.details.liq_capacity_bpd
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.liqCapacityBpdLabel')}`,
                      value: formatNumber(pointMetadata.details.liq_capacity_bpd)
                  }
              ]
            : []),
        // metadata relevant to flares only
        ...(pointMetadata.details.segment_type
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.segmentTypeLabel')}`,
                      value: upperFirst(pointMetadata.details.segment_type)
                  }
              ]
            : []),
        ...(pointMetadata.details.average_flare_temp_k
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.averageFlareTempLabel')}`,
                      value: pointMetadata.details.average_flare_temp_k
                  }
              ]
            : []),
        /**
         * TODO: add gas_flared_mmcf (emissionsMapPage.mapModals.infrastructure.gasFlaredMMCFLabel)
         * back in, once the question in https://methanesat.atlassian.net/browse/DP-2914 has been cleared up
         */
        ...(pointMetadata.details.flare_year
            ? [
                  {
                      label: `Year`,
                      value: pointMetadata.details.flare_year
                  }
              ]
            : []),
        // metadata relevant to all types of point infrastructure
        ...(pointMetadata.state_prov
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.stateProvLabel')}`,
                      value: startCase(toLower(pointMetadata.state_prov))
                  }
              ]
            : []),
        ...(pointMetadata.country
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.countryLabel')}`,
                      value: pointMetadata.country
                  }
              ]
            : []),
        ...(info.object.geometry.coordinates[0] && info.object.geometry.coordinates[1]
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.common.locationLabel')}`,
                      value: formatCoordinates([
                          info.object.geometry.coordinates[0],
                          info.object.geometry.coordinates[1]
                      ])
                  }
              ]
            : info.coordinate
              ? [
                    {
                        label: `${t('emissionsMapPage.mapModals.common.locationLabel')}`,
                        value: formatCoordinates(info.coordinate)
                    }
                ]
              : [])
    ];

    return (
        <MapDataContainer
            HeaderItems={<InfrastructureModalHeader {...headerProps} />}
            Body={<DataRowsModalBody dataRows={dataRows} />}
            Footer={<InfrastructureModalFooter />}
            accordion={accordion}
            AccordionProps={{ ...AccordionProps, onChange: (_ev, expanded) => expanded && logAnalytics() }}
        />
    );
};
