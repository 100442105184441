import { useDispatch } from 'react-redux';
import { PropsWithChildren } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ArrowDropDownIcon,
    Checkbox,
    FormControlLabel,
    Typography,
    VisibilityIcon,
    VisibilityOffIcon
} from '@methanesat/ui-components';

import { isMethaneLayerStateKey, setMethaneLayerConfig, setOGILayerConfig } from '../../../reducers';
import { MethaneLayerState, OGILayerState } from '../../../types';
import { useTranslate } from '../../../hooks';

/**
 * Properties for the Mini Legend 'Advanced' control section
 */
type AdvancedChipFunctionsProps = {
    analyticsEvent: (props: { visible: boolean }) => unknown;
    /** 'hide' label */
    label: string;
    /** Whether the layer is hidden */
    layerEnabled: boolean;
    /** Id of the layer it controls */
    layerId: keyof MethaneLayerState | keyof OGILayerState;
};

/**
 * For toggling layer visibility overall.
 * Should be used when a layer can be partially filtered, as in filtering
 * emissions by specific emission rates.
 */
export const AdvancedChipFunctions = ({
    children,
    label,
    layerEnabled,
    layerId,
    analyticsEvent
}: PropsWithChildren<AdvancedChipFunctionsProps>) => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const handleVisibilityChange = () => {
        const newValue = !layerEnabled;
        if (isMethaneLayerStateKey(layerId)) {
            dispatch(
                setMethaneLayerConfig({
                    layerId: layerId as keyof MethaneLayerState,
                    layerUpdates: { enabled: newValue }
                })
            );
        } else {
            dispatch(
                setOGILayerConfig({
                    layerId: layerId as keyof OGILayerState,
                    layerUpdates: { enabled: newValue }
                })
            );
        }
        analyticsEvent({ visible: newValue });
    };

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        id={`${layerId}-visibility-toggle`}
                        checkedIcon={<VisibilityIcon sx={(theme) => ({ fill: theme.palette.text.primary })} />}
                        checked={layerEnabled}
                        data-testid={`${layerId}-visibility-toggle`}
                        icon={<VisibilityOffIcon sx={(theme) => ({ fill: theme.palette.text.primary })} />}
                        name="layerVisible"
                        onChange={handleVisibilityChange}
                    />
                }
                label={label}
                slotProps={{
                    typography: {
                        variant: 'body2',
                        sx: {
                            '&.MuiFormControlLabel-label.Mui-disabled': { color: 'text.secondary' }
                        }
                    }
                }}
            />
            {children ? (
                <Accordion
                    elevation={0}
                    sx={{
                        '&:before': { height: '0px' },
                        '&.Mui-expanded': {
                            margin: 0
                        }
                    }}
                >
                    <AccordionSummary
                        sx={(theme) => ({
                            minHeight: theme.mixins.chip?.height,
                            height: theme.mixins.chip?.height,
                            margin: 'auto',
                            padding: 0,
                            backgroundColor: 'inherit',
                            '&.Mui-expanded': {
                                minHeight: 0
                            },
                            '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
                                margin: '0px 0'
                            },
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                color: 'inherit'
                            }
                        })}
                        expandIcon={<ArrowDropDownIcon />}
                    >
                        <Typography variant="body2" paddingBottom={0}>
                            {t('emissionsMapPage.miniLegend.generalEmissions.layerControls')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: 'inherit', padding: 1 }}>{children}</AccordionDetails>
                </Accordion>
            ) : null}
        </>
    );
};
