import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { scrollToNextElement, scrollToPreviousElement } from './scrollElements';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';
import Chip from '../Chip';
import { Grid } from '../Layout';
import { Box } from '../Box';
import { SxProps, Theme } from '@mui/material';

interface HorizontalScrollProps {
    elements: ReactNode[];
    centerAlignArrows?: boolean;
    ariaLabelRightScroll?: string;
    ariaLabelLeftScroll?: string;
    /** When set to true, this component must be loaded dynamically using next/dynamic, with {ssr: false}  */
    enableResizeObserver?: boolean;
    sx?: SxProps;
}

/**
 * Takes a list of elements and displays them in a component that allows horizontal scrolling
 */
const HorizontalScroll = ({
    elements,
    ariaLabelRightScroll,
    ariaLabelLeftScroll,
    enableResizeObserver = false,
    centerAlignArrows,
    sx
}: HorizontalScrollProps) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const lhRef = useRef<HTMLDivElement>(null);
    const lhScrollRef = useRef<HTMLDivElement>(null);
    const rhRef = useRef<HTMLDivElement>(null);
    const rhScrollRef = useRef<HTMLDivElement>(null);

    const [isAtLeftEnd, setIsAtLeftEnd] = useState(true);
    const [isAtRightEnd, setIsAtRightEnd] = useState(false);
    const [scrollingElementOffset, setScrollingElementOffset] = useState(0);

    // Callback to onditionally disable and enable next and previous buttons
    // if we are at the end or beginning of the scrolled items.
    const scrollCallback = useCallback(
        (target: Element) => {
            if (target) {
                const { clientWidth, scrollLeft, scrollWidth } = /* target as typeof */ target;

                setIsAtLeftEnd(scrollLeft <= 5);

                // Certain screen sizes do not work with an equality check.
                // A range is given so that the user has a consistent experience regardless
                // of screen size.

                // scroll right = how much further could the item scroll to the right
                const scrollRight = Math.floor(scrollWidth - scrollLeft - clientWidth);
                setIsAtRightEnd(scrollRight <= 5);
            }
        },
        [scrollContainerRef.current]
    );

    // resize observer
    const scrollingContentResizeObserver = useMemo(() => {
        if (enableResizeObserver)
            return new ResizeObserver((entries) => {
                for (let i = 0, l = entries.length; i < l; i++) {
                    scrollCallback(entries[i].target);
                }
            });
        return null;
    }, [enableResizeObserver]);

    // when the scroll container is re-rendered:
    //  * call scrollCallback on scrollContainerRef.current
    //  * add a scroll event listener
    //  * add a resize observer to the
    useEffect(() => {
        // wrapper around scrollCallback
        const eventListenerCallback = (ev: Event) => {
            if (ev.target) scrollCallback(ev.target as Element);
        };

        // initial call on render
        if (scrollContainerRef.current) scrollCallback(scrollContainerRef.current);

        // scroll event listener
        scrollContainerRef.current?.addEventListener('scroll', eventListenerCallback);
        // resize observer
        if (scrollContainerRef.current && scrollingContentResizeObserver)
            scrollingContentResizeObserver.observe(scrollContainerRef.current);

        return () => {
            scrollContainerRef.current?.removeEventListener('scroll', eventListenerCallback);
            if (scrollContainerRef.current && scrollingContentResizeObserver)
                scrollingContentResizeObserver.unobserve(scrollContainerRef.current);
        };
    }, [scrollContainerRef.current]);

    // width of the left & right scrolling buttons combined;
    // used to calculate the max-width of the scolling container
    useEffect(() => {
        setScrollingElementOffset((lhRef.current?.clientWidth || 0) + (rhRef.current?.clientWidth || 0));
    }, [lhRef.current, rhRef.current]);

    const handleNextClick = () => {
        // scroll to next element
        scrollContainerRef.current && scrollToNextElement(scrollContainerRef.current);
        // trigger scroll callback, even if no scrolling happens
        if (scrollContainerRef.current) scrollCallback(scrollContainerRef.current);
        // blur right scroll button
        rhScrollRef.current?.blur();
    };

    const handlePreviousClick = () => {
        // scroll to previous element
        scrollContainerRef.current && scrollToPreviousElement(scrollContainerRef.current);
        // trigger scroll callback, even if no scrolling happens
        if (scrollContainerRef.current) scrollCallback(scrollContainerRef.current);
        // blur left scroll button
        lhScrollRef.current?.blur();
    };

    // style for the left & right scrolling buttons
    // TODO: move to theme?
    const scrollChipStyle = (theme: Theme) => ({
        height: theme.mixins.chip?.height,
        borderColor: theme.palette.grey[700],
        borderStyle: 'solid',
        borderRadius: theme.spacing(3),
        borderWidth: '1px',
        // Make sure that the icon is in the middle of the chip
        '& .MuiChip-label': {
            paddingLeft: 0
        },
        '&.Mui-disabled': {
            pointerEvents: 'none !important',
            '&:hover, &:focus, &.Mui-focusVisible': { backgroundColor: theme.palette.background.paper }
        }
    });

    // Left hand scroller
    const LHScroll = (
        <Box alignItems={'center'} display={'flex'}>
            <Chip
                ref={lhScrollRef}
                color="secondary"
                data-testid="click-back"
                disabled={isAtLeftEnd}
                skipFocusWhenDisabled
                icon={<ChevronLeftIcon />}
                sx={scrollChipStyle}
                onClick={handlePreviousClick}
                aria-label={ariaLabelLeftScroll}
            />
        </Box>
    );

    // Right hand Scroller
    const RHScroll = (
        <Box display={'inline-flex'} alignItems={'center'} textAlign={'right'}>
            <Chip
                ref={rhScrollRef}
                color="secondary"
                data-testid="click-next"
                disabled={isAtRightEnd}
                skipFocusWhenDisabled
                icon={<ChevronRightIcon />}
                sx={scrollChipStyle}
                onClick={handleNextClick}
                aria-label={ariaLabelRightScroll}
            />
        </Box>
    );

    // The list of elements that scroll horizontally
    const scrollableElements = (
        <Grid
            sx={{
                overflow: 'hidden',
                // When scrolling to the next item, we make scrolling
                // smooth instead of a jump cut
                scrollBehavior: 'smooth',
                // Used to hide the scrollbar on some browsers
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                // Used to hide the scrollbar on some browsers
                scrollbarWidth: 'none'
            }}
            wrap="nowrap"
            container
            spacing={1}
            // least hacky way to allow outlines on TimesliderDays
            padding={centerAlignArrows ? '4px' : undefined}
            ref={scrollContainerRef}
            id="horizontal-scroll__elements"
        >
            {elements.map((element, index) => {
                return (
                    <Grid item key={index} xs="auto">
                        {element}
                    </Grid>
                );
            })}
        </Grid>
    );

    // Place the scroll arrows on either side of the elements
    return (
        <Grid container spacing={1} flexWrap="nowrap" sx={{ ...(Array.isArray(sx) ? sx : [sx]) }}>
            {/** Left Hand Side scroll arrow */}
            <Grid item xs="auto" ref={lhRef} alignContent={centerAlignArrows ? 'center' : undefined}>
                {LHScroll}
            </Grid>
            {/** Array of scrollable elements */}
            <Grid
                item
                xs="auto"
                flexBasis={0}
                flexGrow={0}
                flexShrink={1}
                maxWidth={`calc(100% - ${scrollingElementOffset}px) !important`}
                width="fit-content"
            >
                {scrollableElements}
            </Grid>
            {/** Right Hand Side scroll arrow */}
            <Grid item xs="auto" ref={rhRef} alignContent={centerAlignArrows ? 'center' : undefined}>
                {RHScroll}
            </Grid>
        </Grid>
    );
};

export default HorizontalScroll;
