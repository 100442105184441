import React from 'react';
import IconButton, { IconButtonProps } from './IconButton';

type MapButtonProps = {
    ['data-testid']: string;
} & IconButtonProps;

const MapButton = ({ color, 'data-testid': dataTestId, sx, ...otherProps }: MapButtonProps) => {
    return (
        <IconButton
            color={color}
            sx={[(theme) => theme.mixins.mapButton, ...(Array.isArray(sx) ? sx : [sx])]}
            {...otherProps}
            data-testid={dataTestId}
        ></IconButton>
    );
};

export default MapButton;
