import { useCallback, useEffect, useMemo } from 'react';

import { useOGIAPI, useTranslate } from '../../../../../hooks';
import { BasinFeatureProperties, MSatPickInfo, OGITileFeatureProperties } from '../../../../../types';
import { analytics } from '../../../../../utils';
import { MapControlAccordionProps } from '../../../../MapControls/MapControlAccordion';
import { InfrastructureModalFooter } from '../../Footers';
import { GeoFeatureModalHeader } from '../../Headers';
import { DataRowsModalBody, DataSkeleton, MapDataContainer } from '../../Layout';

export const BasinDrawer = ({
    info,
    accordion,
    AccordionProps
}: {
    info: MSatPickInfo<GeoJSON.Geometry, OGITileFeatureProperties>;
    accordion?: boolean;
    AccordionProps?: MapControlAccordionProps;
}) => {
    const t = useTranslate();

    const dataAndLoadingState = useOGIAPI<BasinFeatureProperties>(info);

    const basinMetadata = dataAndLoadingState?.data;
    const loadingState = dataAndLoadingState?.loadingState;

    const metadataForAnalytics = useMemo(
        () =>
            basinMetadata && {
                name: basinMetadata.details.name,
                accordion
            },
        [basinMetadata?.details.name, accordion]
    );
    // called from useEffect below or Accordion onChange event
    const logAnalytics = useCallback(
        () => metadataForAnalytics && analytics.openBasinDetails(metadataForAnalytics),
        [metadataForAnalytics, accordion]
    );

    // tracks user opening details *not* in an accordion
    // viewing details in accordions are tracked in onChange events
    useEffect(() => {
        !accordion && logAnalytics();
    }, [basinMetadata?.details.name, accordion]);

    if (loadingState) {
        return <DataSkeleton />;
    }

    const headerProps = {
        name: `${basinMetadata?.details.name} ${t('emissionsMapPage.mapModals.basin.label')}`.toUpperCase(),
        infraCategory: t('emissionsMapPage.mapModals.basin.category')
    };

    const dataRows = [
        {
            label: t('emissionsMapPage.mapModals.common.featureTypeLabel'),
            value: t('emissionsMapPage.mapModals.basin.label')
        },
        ...((basinMetadata?.details.area_km2 && [
            {
                label: t('emissionsMapPage.mapModals.common.areaLabel'),
                value: `${basinMetadata?.details.area_km2.toLocaleString(undefined, {
                    maximumSignificantDigits: 2
                })} km²`
            }
        ]) ||
            [])
    ];

    const sources = basinMetadata?.sources;

    return (
        <MapDataContainer
            HeaderItems={<GeoFeatureModalHeader {...headerProps} />}
            Body={<DataRowsModalBody dataRows={dataRows} />}
            Footer={
                sources &&
                sources.length &&
                sources.map((source) => <InfrastructureModalFooter key={source.src_id} {...source} />)
            }
            accordion={accordion}
            AccordionProps={{ ...AccordionProps, onChange: (_ev, expanded) => expanded && logAnalytics() }}
        />
    );
};
