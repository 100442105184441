import { SvgIcon, SvgIconProps } from '@mui/material';

const RegionalEmissionsIcon = ({ sx, ...otherProps }: SvgIconProps) => {
    return (
        <SvgIcon sx={[...(Array.isArray(sx) ? sx : [sx])]} {...otherProps}>
            <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.755814"
                    y="23.2442"
                    width="22.4884"
                    height="10.4884"
                    transform="rotate(-90 0.755814 23.2442)"
                    stroke="black"
                    strokeWidth="1.51163"
                />
            </svg>
        </SvgIcon>
    );
};

export default RegionalEmissionsIcon;
