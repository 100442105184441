import {
    CaptureFeature,
    CaptureFeatureProperties,
    isAreaEmissionInfo,
    isBasinInfo,
    isClusteredPointInfrastructureInfo,
    isPipelineInfo,
    isPlumeEmissionInfo,
    isPointInfrastructureInfo,
    isTargetInfo,
    MSatPickInfo
} from '../../../../types';
import {
    MethaneRasterDataDrawer,
    BasinDrawer,
    ClusteredInfrastructureDrawer,
    PipelineDrawer,
    PlumeEmissionsDrawer,
    PointInfrastructureDrawer,
    TargetDrawer
} from '../DataSpecific';

/**
 * Return Target Drawer dependent
 * @param info
 * @param accordion
 * @returns
 */
const getTargetDrawer = (info: MSatPickInfo<GeoJSON.Geometry, CaptureFeatureProperties>) => {
    const targetDetails = info.object as CaptureFeature;
    const targetProperties = {
        ...targetDetails.properties,
        coordinates: targetDetails.geometry.coordinates[0],
        collectionId: targetDetails.collection
    };

    return <TargetDrawer properties={targetProperties} geometry={targetDetails.geometry} id={info.object.id} />;
};

/**
 * Determines which drawer component to use when displaying metadata
 * for selected map layers.
 */
export const LayerDataDrawerContent = ({ info, accordion }: { info: MSatPickInfo; accordion?: boolean }) => {
    return (
        <>
            {/* Plume must be a point feature, must have correct properties, and correct id  */}
            {isPlumeEmissionInfo(info) && <PlumeEmissionsDrawer accordion={!!accordion} info={info} />}

            {/* Area must be a geojson feature, must have correct properties, and correct id  */}
            {isAreaEmissionInfo(info) && <MethaneRasterDataDrawer accordion={!!accordion} info={info} />}

            {/* Pipeline must be a line feature,  correct properties, and have correct id */}
            {isPipelineInfo(info) && <PipelineDrawer accordion={!!accordion} info={info} />}

            {/* Target must have correct properties, and have correct id  */}
            {isTargetInfo(info) && getTargetDrawer(info)}

            {/* cluster of point infrastructure */}
            {isClusteredPointInfrastructureInfo(info) && (
                <ClusteredInfrastructureDrawer
                    count={info.object.properties.point_count}
                    type={info.object.properties.layerName}
                />
            )}

            {/* single point infrastructure */}
            {isPointInfrastructureInfo(info) && !isClusteredPointInfrastructureInfo(info) && (
                <PointInfrastructureDrawer accordion={!!accordion} info={info} />
            )}

            {/* basin */}
            {isBasinInfo(info) && <BasinDrawer accordion={!!accordion} info={info} />}
        </>
    );
};
