import { BaseSyntheticEvent, ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, createFilterOptions, Grid, Typography } from '@methanesat/ui-components';

import { OPERATOR_NAMES_URL, MAX_FILTER_CHIPS } from '../../consts';
import { useDataAPI, useTranslate } from '../../hooks';
import {
    makePerformantSelectAdjustedBBox,
    selectEmissionsMapBBox,
    selectEmissionsMapInfraOperatorFilter,
    selectFlooredZoom,
    selectInfrastructureEnabled,
    selectInfrastructureWithinZoom,
    setOperatorFilter
} from '../../reducers';
import { InfrastructureFilterProps, Operator } from '../../types';
import type { RootState } from '../../store';
import { analytics, stringSortComparison } from '../../utils';
import AutocompleteWithChips from '../AutocompleteWithChips';

/**
 * The filter functionality of the autocomplete element. It is used to filter
 * out possible values when a user enters text into the input.
 */
const filterFn = createFilterOptions<Operator>();

// The id of the select all element in the autocomplete dropdown
const SELECT_ALL_ID = 'SELECT_ALL_ID';

// The input length to start showing the select all option
const INPUT_LENGTH_TO_SHOW_SELECT_ALL = 3;

/**
 * Gives users the ability to filters infrastructure by operator name.
 */
export const InfrastructureOperatorFilter = ({
    GridProps,
    rightWidthOffset,
    isSmall = false
}: InfrastructureFilterProps & {
    /* set to zero if InfrastructureOperatorFilter is not located in a drawer */
    rightWidthOffset: number;
    isSmall?: boolean;
}): ReactElement => {
    const [inputValue, setInputValue] = useState<string>('');
    const t = useTranslate();

    const [shouldFetch, setShouldFetch] = useState(false);
    const selectedOperators = useSelector(selectEmissionsMapInfraOperatorFilter);
    const isSelected = useSelector(selectInfrastructureEnabled);
    const isWithinZoom = useSelector(selectInfrastructureWithinZoom);

    const dispatch = useDispatch();
    const bbox = useSelector(selectEmissionsMapBBox);

    const flooredZoom = useSelector(selectFlooredZoom);

    const selectAdjustedBBox = useMemo(makePerformantSelectAdjustedBBox, []);
    const adjustedBBox = useSelector((state: RootState) => selectAdjustedBBox(state, rightWidthOffset, bbox));
    const [west, south, east, north] = adjustedBBox;

    const disabled = !isSelected || !isWithinZoom;

    const operators: { operator_id: number; operator_name: string }[] | undefined = useDataAPI(
        (shouldFetch &&
            `${OPERATOR_NAMES_URL}?xmin=${west}&ymin=${south}&xmax=${east}&ymax=${north}&z=${flooredZoom}&order=operator_name`) ||
            null
    );
    const selectedOperatorIds = useMemo(() => selectedOperators.map((operator) => operator.id), [selectedOperators]);

    // Memoize value so autocomplete can remove selected items.
    const mappedOperators = useMemo(
        () =>
            operators?.map(({ operator_id, operator_name }) => ({
                id: operator_id,
                label: operator_name
            })) || [],
        [operators]
    );

    // Filtered options represent all of the values that are possible for selection
    // that align with the input value and are not already selected.
    const filteredOptions = filterFn(mappedOperators, {
        inputValue,
        getOptionLabel: ({ label }) => label
    }).filter(({ id }) => !selectedOperatorIds.includes(id));

    // The mui component wants all selected and possible options to be passed
    // to the component so it can figure out selected filtering on its own
    let operatorOptions: (Operator | { id: typeof SELECT_ALL_ID; label: string })[] = [
        ...filteredOptions,
        ...selectedOperators
    ];
    if (inputValue.length >= INPUT_LENGTH_TO_SHOW_SELECT_ALL && filteredOptions.length > 0) {
        operatorOptions = [
            {
                id: SELECT_ALL_ID,
                label: t('emissionsMapPage.mapControls.infrastructureOperatorFilter.selectAll', {
                    input: inputValue.toLocaleUpperCase()
                })
            },
            ...operatorOptions
        ];
    }

    const placeholder = isWithinZoom
        ? t('emissionsMapPage.mapControls.infrastructureOperatorFilter.placeholder')
        : t('emissionsMapPage.mapControls.infrastructureOperatorFilter.zoomIn');

    const handleInputChange = (_event: BaseSyntheticEvent, newValue: string) => {
        setInputValue(newValue);
    };

    return (
        <Grid container spacing={2} {...GridProps}>
            <Grid item>
                <Typography
                    variant={isSmall ? 'body2' : 'subtitle2'}
                    color={disabled ? 'text.disabled' : 'text.primary'}
                >
                    {`${t('emissionsMapPage.mapControls.infrastructureOperatorFilter.prompt')}`}
                </Typography>
                <AutocompleteWithChips
                    maxChips={MAX_FILTER_CHIPS}
                    clearAllButton={!isSmall}
                    onInputChange={handleInputChange}
                    inputValue={inputValue}
                    filterOptions={(option) => option}
                    TextFieldProps={{
                        placeholder: isSmall ? '' : placeholder,
                        InputProps: isSmall
                            ? {
                                  style: { fontSize: 12, padding: 1, paddingLeft: 4 }
                              }
                            : {}
                    }}
                    chipComponent={
                        isSmall ? <Chip size="small" sx={{ fontSize: 8, height: 20, maxWidth: 150 }} /> : undefined
                    }
                    value={selectedOperators}
                    disabled={disabled}
                    disableCloseOnSelect={false}
                    options={operatorOptions}
                    onChange={(allSelectedOperators) => {
                        let selectedOperators = allSelectedOperators;
                        const allExceptSelectAll = allSelectedOperators.filter(({ id }) => id !== SELECT_ALL_ID);
                        // If the select all option is present in the value that was returned
                        // by the autocomplete, then we want to set the value to be all of the
                        // possible options at the time the user clicked select all
                        if (allSelectedOperators.length - 1 === allExceptSelectAll.length) {
                            selectedOperators = [...allExceptSelectAll, ...filteredOptions].sort((a, b) =>
                                stringSortComparison(`${a.label}`, `${b.label}`)
                            );
                        }
                        analytics.ogiFilterByOperator({
                            operators: selectedOperators.map((o) => `${(o as Operator).id}`)
                        });
                        dispatch(setOperatorFilter(selectedOperators as Operator[]));
                    }}
                    onClose={() => {
                        setShouldFetch(false);
                    }}
                    onOpen={async () => {
                        /**
                         * Fetches the list of operators when the autocomplete is first interacted with.  This offloads
                         * loading and processing logic from the initial app load, and waits until the user actually
                         * interacts and needs the data.  Keeping the operator list separate also allows us to
                         * save processing time assembling and deduping operator names from the infrastructure data.
                         */
                        setShouldFetch(true);
                    }}
                    data-testid="infrastructure-operator-filter"
                />
            </Grid>
        </Grid>
    );
};
