import { getLinearColorStops } from '@methanesat/colors';
import { roundUsingSigFigs } from '../../../utils/numbers';
import { useTranslate } from '../../../hooks';

export interface TargetColorStopLabel {
    /** Percentage for positioning. */
    percent: number;
    /** Label to display next to color stop. */
    label: string;
    /** Key for React element uniqueness. */
    key: string;
}

/** Custom values for the target color scale in the mini legend. These are assigned manually
 * since the spacing between them is irregular.
 */
const CUSTOM_TARGET_SCALE_VALUES = [10_000, 50_000, 100_000, 140_000];

/** Assign evenly spaced percentages for the labels */
const stopCount = CUSTOM_TARGET_SCALE_VALUES.length;
export const evenlySpacedTargetScaleLabels: TargetColorStopLabel[] = CUSTOM_TARGET_SCALE_VALUES.map((val, index) => {
    const label = roundUsingSigFigs(val);
    return {
        percent: (index / (stopCount - 1)) * 100,
        label,
        key: label
    };
});

/**
 * Generates labels for color stops from a min/max, segmenting linearly.
 */
export const getTargetColorStopLabels = (
    min: number,
    max: number,
    colorStopCount = 4,
    ascending = false
): TargetColorStopLabel[] => {
    const stops = getLinearColorStops(colorStopCount, min, max);

    return stops.map((stopVal, index) => {
        const percentageStep = parseFloat(((index / (colorStopCount - 1)) * 100).toFixed(2));
        const label = roundUsingSigFigs(stopVal);

        return {
            percent: ascending ? 100 - percentageStep : percentageStep,
            label,
            key: label
        };
    });
};

/**
 * Adds to target labels "or more/less" text (using locales), tooltips, etc.  Intended to be used after running
 * getTargetColorStopLabels().
 */
export const augmentTargetLabels = (
    labels: TargetColorStopLabel[],
    translationFunction: ReturnType<typeof useTranslate>
) => {
    return labels.map((labelObj) => {
        const { percent, label, key } = labelObj;

        // Highest value.
        if (percent === 100) {
            return {
                key,
                label: translationFunction('emissionsMapPage.miniLegend.generalEmissions.orMore', {
                    value: `${label} kg/hr`
                }),
                percent,
                tooltip: translationFunction('emissionsMapPage.miniLegend.target.equivalencyTooltip')
            };
        }

        // Lowest value.
        if (percent === 0) {
            return {
                key,
                label: translationFunction('emissionsMapPage.miniLegend.generalEmissions.orLess', {
                    value: label
                }),
                percent
            };
        }

        // No-op passthrough.
        return labelObj;
    });
};
