import React from 'react';
import { AlertSeverity } from '../../types';
import { Badge, BadgeProps } from './';

export interface AlertBadgeProps extends BadgeProps {
    severity?: AlertSeverity;
}
const AlertBadge = ({ severity, ...props }: AlertBadgeProps) => {
    const badgeColorKey = severity || 'info';
    return (
        <Badge
            sx={(theme) => ({
                '& .MuiBadge-badge': {
                    color: theme.palette[badgeColorKey].contrastText,
                    backgroundColor: theme.palette[badgeColorKey][theme.palette.mode]
                }
            })}
            {...props}
        />
    );
};

export default AlertBadge;
