import { Button, ArrowForwardIcon } from '@methanesat/ui-components';
import { useTranslate } from '../../../../../hooks';

export const OGIMoreInfoButton = ({ onClick }: { onClick: () => void }) => {
    const t = useTranslate();
    return (
        <Button
            data-testid="ogi-more-info-button"
            variant="outlined"
            color="info"
            onClick={onClick}
            endIcon={<ArrowForwardIcon />}
        >
            {t('emissionsMapPage.infrastructure.ogiNearEmissions.nearbyFacilitiesPrompt')}
        </Button>
    );
};
