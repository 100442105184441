import { Grid, Link, Typography } from '@methanesat/ui-components';

import { useDataAPI, useTranslate } from '../../../../hooks';
import { INFRASTRUCTURE_DATA_VERSION_URL, OGIM_DATA_SOURCE_URL } from '../../../../consts/data';
import { LoadedData } from '../../../../types';

export const InfrastructureModalFooter = () => {
    const t = useTranslate();
    const databaseInfo = useDataAPI<{ ogi_description: string }[]>(INFRASTRUCTURE_DATA_VERSION_URL) as LoadedData[];
    const version = databaseInfo?.[0].ogi_description;

    return (
        <Grid container display="flex" flexDirection="column">
            <Grid item xs={12}>
                {version && (
                    <Grid item xs="auto">
                        <Typography variant="caption" fontStyle="italic">
                            {`${t('emissionsMapPage.mapModals.common.versionLabel', { version })}`}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}></Grid>
            </Grid>
            <Grid item xs={12} ellipsis>
                <Typography variant="caption" fontStyle="italic">
                    {`${t('emissionsMapPage.mapModals.common.source')}: `}
                </Typography>
                <Typography variant="caption" fontStyle="italic">
                    <Link
                        variant="caption"
                        fontStyle="italic"
                        underline="always"
                        href={OGIM_DATA_SOURCE_URL}
                        target="_blank"
                        data-testid="infra-modal-data-source-href"
                        whiteSpace="nowrap"
                    >
                        {OGIM_DATA_SOURCE_URL}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};
