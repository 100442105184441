import React, { ForwardedRef, ReactElement, ReactNode } from 'react';

import { Box, Breakpoint, CloseIcon, Drawer, DrawerProps, IconButton, Toolbar } from '@methanesat/ui-components';

type SizeRangeUp = 'xsUp' | 'smUp' | 'mdUp' | 'lgUp' | 'xlUp';

/**
 * Props for BelowNavDrawer. Limits anchor to left & right, requires onClose, adds showAppBar
 */
export interface BelowNavDrawerProps extends DrawerProps {
    'data-testid'?: string;
    anchor?: 'left' | 'right';
    children?: ReactNode;
    onClose: Exclude<DrawerProps['onClose'], undefined>;
    /**
     * Sizes where the drawer should show under the app bar.
     * On smaller screens, the drawer will be full-height &  hide the app bar.
     */
    showAppBar?: SizeRangeUp;
    paperRef?: ForwardedRef<HTMLDivElement>;
}

/**
 * Full-height side drawer for map controls.
 * Under the app bar on larger screens,
 * truly full-height on mobile.
 */
const BelowNavDrawer = ({
    'data-testid': dataTestId,
    anchor = 'right',
    children,
    variant = 'persistent',
    showAppBar = 'smUp',
    paperRef,
    ...drawerProps
}: BelowNavDrawerProps): ReactElement => {
    return (
        <Drawer
            data-testid={dataTestId || 'side-drawer'}
            {...drawerProps}
            anchor={anchor}
            variant={variant}
            PaperProps={{
                ref: paperRef,
                sx: {
                    maxWidth: {
                        sm: '500px'
                    },
                    minWidth: {
                        xs: '100%',
                        sm: '400px'
                    },
                    width: {
                        xs: '100%',
                        sm: '40%'
                    },
                    zIndex: (theme) => {
                        return {
                            // on xs screens show drawer above app bar
                            xs: theme.zIndex.drawer + 2,
                            sm: theme.zIndex.drawer
                        };
                    }
                }
            }}
        >
            {/* empty toolbar to keep drawer under the app bar, and all drawer contents visible */}
            <Toolbar
                sx={(theme) => ({
                    display: 'none',
                    [theme.breakpoints.up(showAppBar.toLowerCase().slice(0, 2) as Breakpoint)]: {
                        display: 'block'
                    }
                })}
            />

            <Box
                position="relative"
                padding={1}
                sx={(theme) => ({
                    paddingBottom: `calc(${theme.mixins?.speedDial?.height} + ${theme.mixins.speedDial?.bottom})`
                })}
            >
                <IconButton
                    data-testid="close-drawer-button"
                    onClick={(ev) => drawerProps.onClose(ev, 'escapeKeyDown')}
                    sx={{
                        position: 'absolute',
                        right: (theme) => theme.spacing(2),
                        top: (theme) => theme.spacing(2)
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ marginTop: 4 }}>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default BelowNavDrawer;
