import { Button, Grid, Typography } from '@methanesat/ui-components';
import CustomizeDownload from './CustomizeDownload';
import CustomizeDownloadHeader from './CustomizeDownloadHeader';
import { useTranslate } from '../../hooks';

// The data here would eventually be fetched from the server
const fileName = 'methane_data.csv';

/**
 * Allows customization of MethaneAIR diffuse methane data to be downloaded.
 */
const CustomizeAreaEmissionsDownload = () => {
    const t = useTranslate();

    return (
        <CustomizeDownload
            header={<CustomizeDownloadHeader fileName={fileName} />}
            body={<div />}
            description={
                <Typography>{t('emissionsMapPage.downloadDataDrawer.customizationFlowDescription')}</Typography>
            }
            footer={
                <Grid container justifyContent="flex-end">
                    <Grid item xs md="auto">
                        <Button data-testid="customize-download" fullWidth variant="contained" disabled>
                            {t('emissionsMapPage.downloadDataDrawer.downloadComingSoon')}
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    );
};

export default CustomizeAreaEmissionsDownload;
