import React from 'react';
import { Box } from '../Box';
import Typography from '../Typography';
import { ArrowDropDownIcon, InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip';

interface BarGradientProps {
    min: number;
    max: number;
    minLabel: string;
    maxLabel: string;
    units?: string;
    value: number;
    valueText: string;
}

const BarGradient = ({ value, valueText, min, minLabel, max, maxLabel, units = 'kg/hr' }: BarGradientProps) => {
    let percentageWidth;
    const range = max - min;
    if (value <= min) {
        /**
         * setting width to -3% if value is at the max of the range to account for spacing
         * */
        percentageWidth = -3;
    } else if (value >= range) {
        /**
         * setting width to 96% if value is at the max of the range to account for spacing
         * */
        percentageWidth = 96;
    } else {
        percentageWidth = (value / range) * 100;
    }
    const heading = `${valueText} ${units}`;
    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Typography
                sx={{
                    fontSize: '42px',
                    lineHeight: '48px',
                    textAlign: 'center'
                }}
            >
                {heading}
            </Typography>
            <ArrowDropDownIcon
                sx={{
                    fontSize: '32px',
                    marginLeft: `${percentageWidth}%`,
                    marginBottom: '-14px'
                }}
            />
            <Box
                sx={{
                    height: '20px',
                    width: '100%',
                    marginBottom: '4px',
                    background:
                        'linear-gradient(-90deg, #FECE31 0%, #F9913B 25%, #C44558 50%, #931181 75%, #000C6B 100%)'
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '12px'
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 700
                        }}
                    >
                        Low
                    </Typography>
                    <Tooltip title={minLabel}>
                        <InfoIcon
                            sx={{
                                fontSize: '14px'
                            }}
                        />
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 700
                        }}
                    >
                        High
                    </Typography>
                    <Tooltip title={maxLabel}>
                        <InfoIcon
                            sx={{
                                fontSize: '14px'
                            }}
                        />
                    </Tooltip>
                </Box>
            </Box>
            {/* Commenting out subtext while we figure out new copy */}
            {/* <Typography variant="body1">
                2-3 lines of text, to use as needed for discussion of uncertainty and explanations of what the target
                is. That would go here in this space and should stay brief if possible.
            </Typography> */}
        </Box>
    );
};

export default BarGradient;
