import { ReactNode, useState } from 'react';

import { AlertBadge, AlertBadgeProps, SpeedDial, SpeedDialIcon } from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';
import { analytics } from '../../utils';

export const EmissionsMapSpeedDial = ({
    actions,
    AlertBadgeProps
}: {
    actions: ReactNode[];
    AlertBadgeProps?: AlertBadgeProps;
}) => {
    const t = useTranslate();
    const [open, setOpen] = useState(false);

    function handleSpeedDialClick() {
        setOpen(!open);
        analytics.toggleSpeedDial({ open: !open });
    }

    return (
        <SpeedDial
            ariaLabel={t('emissionsMapPage.speedDial.helpText')}
            direction="left"
            data-testid="emissions-map-speed-dial"
            FabProps={{
                onClick: handleSpeedDialClick,
                sx: (theme) => ({ height: theme.mixins.speedDial.height, width: theme.mixins.speedDial.width })
            }}
            icon={
                /**
                 * Show the notification badge only when the speed dial is closed.
                 * When the speed dial is open, each action with notifications should have its own badge.
                 */
                AlertBadgeProps && AlertBadgeProps.badgeContent && !open ? (
                    <AlertBadge {...AlertBadgeProps}>
                        <SpeedDialIcon />
                    </AlertBadge>
                ) : (
                    <SpeedDialIcon />
                )
            }
            open={open}
            sx={(theme) => ({
                position: 'absolute',
                right: theme.mixins.speedDial.right,
                bottom: theme.mixins.speedDial.bottom,
                zIndex: theme.zIndex.drawer + 10,
                '& .MuiFab-root': {
                    color: theme.palette.secondary.contrastText,
                    background: theme.palette.secondary.main,
                    borderColor: theme.palette.grey[700],
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    transition: theme.transitions.create('transform', {
                        duration: theme.transitions.duration.standard,
                        easing: theme.transitions.easing.easeInOut
                    }),
                    transitionProperty: 'background-color',
                    '&:hover, &:focus, &:focus-within': {
                        backgroundColor: theme.palette.action.hover,
                        boxShadow:
                            '0 0 5px 3px rgb(255 255 255 / 20%), 0 0 10px 6px rgb(255 255 255 / 14%), 0 0 18px 1px rgb(255 255 255 / 12%)'
                    }
                }
            })}
        >
            {actions.map((action) => action)}
        </SpeedDial>
    );
};
