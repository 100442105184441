import { BaseSyntheticEvent } from 'react';

import { CloseIcon, Dialog, DialogProps, IconButton } from '@methanesat/ui-components';

interface ClosableDialogProps extends DialogProps {
    onClose: (event: BaseSyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void;
}

/**
 * A typical dialog with a close button in the top right hand corner
 * of the dialog.
 */
const ClosableDialog = ({ children, onClose, ...props }: ClosableDialogProps) => {
    const handleClick = (event: BaseSyntheticEvent) => onClose(event, 'closeButtonClick');

    return (
        <Dialog onClose={onClose} {...props}>
            <IconButton
                sx={(theme) => ({
                    position: 'absolute',
                    top: theme.spacing(2),
                    right: theme.spacing(2)
                })}
                data-testid="close-dialog"
                onClick={handleClick}
            >
                <CloseIcon />
            </IconButton>
            {children}
        </Dialog>
    );
};

export default ClosableDialog;
