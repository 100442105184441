import { Fragment, ReactElement } from 'react';

import { Color, colorArrayToRgb } from '@methanesat/colors';
import { Grid, useTheme } from '@methanesat/ui-components';

interface ColorSchemeProps {
    colorValues: Color[];
    /**
     * Labeling begins at the lowest end of the scale.
     *
     * If label position is `'internal'`, the first label will be placed
     * inside the first box.
     *
     * If label position is `'start'` or `'end'`, the first label will be
     * label the minimum end of the scale (before the first box).
     *
     * Including `null` labels allow skipping a label. The relevant box or threshold
     * will not be labeled and will not receive a tickmark (if applicable).
     *
     * If fewer labels than values are provided, items at the
     * upper end of the scale will be unlabeled.
     */
    colorLabels?: (string | number | null)[];
    border?: boolean;
    isVertical?: boolean;
    /**
     * start = left/top (horizontal/vertical)
     * end = right/bottom (horizontal/vertical)
     */
    labelPosition?: 'internal' | 'start' | 'end';
}

/**
 * Displays a color scheme, aka color palette or color ramp.
 */
const ColorScheme = ({
    colorValues,
    colorLabels = [],
    border,
    isVertical,
    labelPosition = 'internal'
}: ColorSchemeProps): ReactElement => {
    const theme = useTheme();

    /**
     * Get color swatch radius
     * @param isFirst - if index is first in the array
     * @param isVertical - if the color scheme is vertically oriented
     * @param isLastValue - if the index of the swatch is the last in the array
     * @returns
     */
    const getBorderRadius = (isFirst: boolean, isVertical: boolean | undefined, isLastValue: boolean) => {
        if (!isFirst) {
            return isLastValue ? '0 0 5px 5px' : '0 0 0 0';
        }

        return isVertical ? '5px 5px 0 0' : '5px 0 0 5px';
    };

    let processedColorValues = colorValues;
    let processedColorLabels = colorLabels;

    /**
     * make sure external labels include enough values
     * (required in case of a vertical scale; those values are reversed)
     */
    if (labelPosition !== 'internal') {
        while (processedColorLabels.length < colorValues.length + 1) {
            processedColorLabels.push(null);
        }
    }

    // In the vertical orientation the colors/labels must be rendered in reverse order
    if (isVertical) {
        processedColorValues = colorValues.slice(0).reverse();
        processedColorLabels = colorLabels.slice(0).reverse();
    }

    const borderColor = theme.palette.text.primary;
    return (
        <Grid container>
            {/**
             * External labels
             *
             * The order of elements (swatches, tickmarks & labels) in the component is controlled
             * by the css property `order`.
             * When labels are positioned 'start' (left/above), ticks and labels are given order
             * values -1 and -2 (before) their corresponding color swatch, respectively. When
             * ticks and labels are positioned 'end' (right/below),
             * they are given order values +1 and +2 (after) their corresponding color swatch.
             *
             * When labels are internal, these elements do not render and order is moot.
             */}
            {labelPosition !== 'internal' &&
                processedColorLabels.map((label, index) => {
                    return (
                        <Fragment key={`label-tick_${index}`}>
                            {
                                /* 
                                    When in the vertical postions, this will function to add the optional
                                    labels off to the side of colorbar
                                */
                                <>
                                    {/* The label */}
                                    <Grid
                                        item
                                        data-testid={`label-${index}`}
                                        xs={7}
                                        sx={{
                                            order: labelPosition === 'start' ? index * 3 - 2 : index * 3 + 2,
                                            textAlign: labelPosition === 'start' ? 'right' : 'left',
                                            position: 'relative',
                                            top: '-20px',
                                            fontSize: '14px',
                                            ...(isVertical && labelPosition === 'start' && { paddingRight: '6px' }),
                                            ...(isVertical && labelPosition === 'end' && { paddingLeft: '6px' }),
                                            ...(!isVertical && labelPosition === 'start' && { paddingBottom: '6px' }),
                                            ...(!isVertical && labelPosition === 'end' && { paddingTop: '6px' })
                                        }}
                                        key={`label_${index}`}
                                    >
                                        {label}
                                    </Grid>
                                    {/* The tick mark between the labels and the color bar */}
                                    <Grid
                                        item
                                        data-testid={`tickmark-${index}`}
                                        xs={1}
                                        sx={(theme) => ({
                                            order: labelPosition === 'start' ? index * 3 - 1 : index * 3 + 1,
                                            color: theme.palette.text.primary,
                                            ...(border && {
                                                borderColor,
                                                borderStyle: 'solid',
                                                borderWidth: !label ? '0 0 0 0' : isVertical ? '0' : '0 1px 0 0'
                                            })
                                        })}
                                        key={`line_${index}`}
                                    ></Grid>
                                </>
                            }
                        </Fragment>
                    );
                })}

            {/* all color blocks */}
            {processedColorValues.map((colorValue, index) => {
                const rgba = colorArrayToRgb(colorValue);
                const optionalLabel = processedColorLabels[index];
                return (
                    <Grid
                        data-testid={`swatch-${rgba}`}
                        item
                        xs={isVertical ? 4 : true}
                        key={`color_${index}`}
                        sx={(theme) => ({
                            order: index * 3,
                            alignItems: 'center',
                            display: 'flex',
                            fontSize: 'inherit',
                            minHeight: '1.2rem',
                            justifyContent: 'center',
                            padding: '0 0.2rem',
                            background: rgba,
                            '&:first-of-type': {
                                borderRadius: isVertical ? '5px 5px 0 0' : '5px 0 0 5px'
                            },
                            '&:last-of-type': {
                                borderRadius: isVertical ? '0 0 5px 5px' : '0 5px 5px 0'
                            },
                            color: theme.palette.getContrastText(rgba),
                            ...(border && {
                                borderColor,
                                borderStyle: 'solid',
                                borderWidth: isVertical ? '1px 1px 0 1px' : '1px 0 1px 0',
                                borderRadius: getBorderRadius(
                                    index === 0,
                                    isVertical,
                                    index === processedColorValues.length - 1
                                ),
                                '&:first-of-type': {
                                    borderWidth: isVertical ? '1px 1px 0 1px' : '1px 0 1px 1px'
                                },
                                '&:last-of-type': {
                                    borderWidth: isVertical ? '1px 1px 1px 1px' : '1px 1px 1px 0'
                                }
                            })
                        })}
                    >
                        {
                            // This will show the optionalLabel over the colorbar in the horizontal mode
                            labelPosition === 'internal' ? optionalLabel : null
                        }
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ColorScheme;
