import React, { useState } from 'react';
import { ClickableControlOverlay } from './ClickableControlOverlay';
import { ScaleControl, ScaleControlProps } from './ScaleControl';

/**
 * A component combining the ScaleControl from `react-map-gl` and
 * an overlay div to provide the ability to interact with the scale.
 */
export const ClickableScaleControl = (props: ScaleControlProps) => {
    const [unit, setUnit] = useState<ScaleControlProps['unit']>(props.unit || 'metric');
    function handleScaleClick() {
        setUnit((oldUnit) => (oldUnit === 'imperial' ? 'metric' : 'imperial'));
    }

    return (
        <>
            <ScaleControl {...props} unit={unit} />
            <ClickableControlOverlay
                position={props?.position}
                controlType="scale"
                clickHandler={handleScaleClick}
                data-testid="scale-control-clickable-overlay"
            />
        </>
    );
};
