import { ReactElement, useEffect, useRef } from 'react';

import {
    Button,
    CircleIcon,
    ClickAwayListener,
    ClickIcon,
    ColorRangeIcon,
    ColorRangeIconScale,
    DateRangeIcon,
    FilterIcon,
    Grid,
    InfoIcon,
    Link as MSATLink,
    PlumeIcon,
    Tooltip,
    Typography,
    useTheme,
    VisibilityIcon,
    ZoomIcon
} from '@methanesat/ui-components';
import { BelowNavDrawer, BelowNavDrawerProps } from '../BelowNavDrawer';
import { useAppDispatch, useCurrentBreakpoint, useLocaleRoute, useTranslate } from '../../hooks';
import { getColorRangeIcon } from '../EmissionsMapLegend/MiniLegend';
import {
    zoomToPermianAreaEmissions,
    zoomToPermianOilAndGas,
    zoomToPermianPlumeEmissions,
    zoomToPermianTarget,
    zoomToPermianTargetBroad
} from '../../reducers';
import { tooltipSlice } from '../../reducers/tooltip';
import { getBreakpointsDown } from '../../utils/breakpoints';
import { getOgiColor } from '../EmissionsMapLegend/MiniLegend/OgiChip';
import { getBuildInfoForDisplay, GOOGLE_MAPS_API_KEY } from '../../environmentVariables';
import { analytics, getColorRangeColors, getTargetTotalsColors } from '../../utils';
import { colorArrayToRgb, grey } from '@methanesat/colors';

type IntroDrawerProps = Omit<BelowNavDrawerProps, 'children'> & {
    onWidth: (width: number) => void;
};

/**
 * Full-height side drawer for map controls.
 * Under the app bar on larger screens,
 * truly full-height on mobile.
 */
const IntroDrawer = ({ onWidth, ...drawerProps }: IntroDrawerProps): ReactElement => {
    const t = useTranslate();
    const theme = useTheme();
    const faqRoute = useLocaleRoute();
    const dispatch = useAppDispatch();
    const drawerRef = useRef<HTMLDivElement>(null);

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    const ogiInfrastructureColor = getOgiColor(theme, true);

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    return (
        <BelowNavDrawer {...drawerProps} paperRef={drawerRef}>
            <Grid container spacing={1} paddingX={2} marginBottom={2}>
                {/** Header */}
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ letterSpacing: '-0.05rem' }}>
                        {t('emissionsMapPage.introDrawer.introHeader')}
                    </Typography>
                    <Typography fontWeight="bold" variant="body2" sx={{ letterSpacing: '0.03rem' }}>
                        {t('emissionsMapPage.introDrawer.introSubHeader').toUpperCase()}
                    </Typography>
                </Grid>
                {/** Caption */}
                <Grid item xs={12} sx={{ marginBottom: theme.spacing(1), display: 'flex' }}>
                    <Typography variant="caption">{t('emissionsMapPage.introDrawer.description')}</Typography>
                    <Tooltip
                        title={t('emissionsMapPage.introDrawer.descriptionTooltip')}
                        sx={{ marginLeft: theme.spacing(0.5), fontSize: 18 }}
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    padding: '10px 15px',
                                    fontSize: '14px'
                                }
                            }
                        }}
                    >
                        <InfoIcon />
                    </Tooltip>
                </Grid>
                {/** Mini Legend Features */}
                <Grid item xs={12}>
                    {/* regional emissions */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-regional-emissions-button"
                            startIcon={
                                <ColorRangeIcon
                                    colors={getTargetTotalsColors(theme, true)}
                                    scale={ColorRangeIconScale.linear}
                                />
                            }
                            color="primary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.grey[400]
                            }}
                            fullWidth={true}
                            variant="outlined"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroRegionalEmissions();
                                dispatch(zoomToPermianTarget());
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#target-emissions-expand',
                                        parentSelector: '[data-testid="target-emissions"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.regionalEmissions')}
                        </Button>
                    </ClickAwayListener>
                    {/* area emissions */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-area-emissions-button"
                            startIcon={getColorRangeIcon(theme, true, ColorRangeIconScale.linear)}
                            color="primary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.grey[400]
                            }}
                            fullWidth={true}
                            variant="outlined"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroAreaEmissions();
                                dispatch(zoomToPermianAreaEmissions());
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#area-emissions-expand',
                                        parentSelector: '[data-testid="area-emissions"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.areaEmissions')}
                        </Button>
                    </ClickAwayListener>
                    {/* point souces */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-point-source-button"
                            startIcon={
                                <PlumeIcon
                                    sx={{
                                        marginRight: '14px',
                                        marginLeft: '14px'
                                    }}
                                    colors={getColorRangeColors(theme, true)}
                                />
                            }
                            color="primary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.grey[400]
                            }}
                            fullWidth={true}
                            variant="outlined"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroPointSources();
                                dispatch(zoomToPermianPlumeEmissions());
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#plume-emissions-expand',
                                        parentSelector: '[data-testid="point-source-toggle-chip"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.pointSource')}
                        </Button>
                    </ClickAwayListener>
                    {/* ogi/types */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-reported-ogi-button"
                            startIcon={<CircleIcon sx={{ color: ogiInfrastructureColor, stroke: 'grey', width: 48 }} />}
                            color="primary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.grey[400]
                            }}
                            fullWidth={true}
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroOgi();
                                dispatch(zoomToPermianOilAndGas({ filterExpanded: false }));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#ogi-infrastructure-expand',
                                        parentSelector: '[data-testid="oil-and-gas-toggle-chip"]'
                                    })
                                );
                            }}
                            variant="outlined"
                        >
                            {t('emissionsMapPage.introDrawer.filterOGI')}
                        </Button>
                    </ClickAwayListener>
                    {/* filter ogi */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-filter-ogi-button"
                            startIcon={<FilterIcon sx={{ width: 48 }} />}
                            color="primary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.grey[400]
                            }}
                            fullWidth={true}
                            variant="outlined"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroFilterOgi();
                                dispatch(zoomToPermianOilAndGas({ filterExpanded: true }));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#ogi-operator-filter-expand',
                                        parentSelector: '[data-testid="filter-ogi-chip"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.filterOGIOperator')}
                        </Button>
                    </ClickAwayListener>
                </Grid>
                {/** Explore the Map */}
                <Grid item xs={12} marginTop={1}>
                    <Typography variant="h5">{t('emissionsMapPage.introDrawer.explore')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {/* zoom buttons */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-zoom-button"
                            startIcon={<ZoomIcon />}
                            color="secondary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'transparent',
                                borderWidth: '1px',
                                borderStyle: 'solid'
                            }}
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                {
                                    analytics.clickIntroZoom();
                                    dispatch(
                                        tooltipSlice.actions.show({
                                            targetSelector: '#map-zoom-in-button',
                                            text: t('emissionsMapPage.introDrawer.modals.zoomIn')
                                        })
                                    );
                                }
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.zoom')}
                        </Button>
                    </ClickAwayListener>
                    {/* get map info */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-click-button"
                            startIcon={<ClickIcon />}
                            color="secondary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'transparent',
                                borderWidth: '1px',
                                borderStyle: 'solid'
                            }}
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroMapInfo();
                                dispatch(zoomToPermianTargetBroad());
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: GOOGLE_MAPS_API_KEY
                                            ? '#emissions-map-google-container'
                                            : '#emissions-map-deck-container',
                                        text: t('emissionsMapPage.introDrawer.clickMapForInfo')
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.click')}
                        </Button>
                    </ClickAwayListener>
                    {/* layer enable/disable */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-toggle-button"
                            startIcon={<VisibilityIcon />}
                            color="secondary"
                            style={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                marginBottom: theme.spacing(1),
                                fontWeight: 'normal',
                                borderRadius: '10px',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'transparent',
                                borderWidth: '1px',
                                borderStyle: 'solid'
                            }}
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroLayerToggle();
                                dispatch(zoomToPermianAreaEmissions());
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#area-emission-raster-visibility-toggle',
                                        parentSelector: '[data-testid="area-emissions"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.toggle')}
                        </Button>
                    </ClickAwayListener>
                </Grid>

                {/** Data FAQ */}
                <Grid
                    container
                    marginTop={2}
                    padding={2}
                    paddingBottom={1}
                    sx={{ backgroundColor: theme.palette.yellow.main }}
                >
                    <Grid item xs={1} paddingRight={1}>
                        <DateRangeIcon sx={{ float: 'right' }} />
                    </Grid>
                    <Grid item xs={11} sx={{ display: 'flex' }}>
                        <Typography variant="caption">{t('emissionsMapPage.introDrawer.dates')}</Typography>
                        <Tooltip
                            title={t('emissionsMapPage.introDrawer.datesTooltip')}
                            sx={{ marginLeft: theme.spacing(0.5), fontSize: 18 }}
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        padding: '10px 15px',
                                        fontSize: '14px'
                                    }
                                }
                            }}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
                {/** Info Links */}
                <Grid
                    container
                    padding={2}
                    paddingTop={1}
                    sx={{
                        backgroundColor: theme.palette.yellow.main,
                        lineHeight: '0.5rem'
                    }}
                >
                    <Grid item xs={1} paddingRight={1}>
                        <InfoIcon sx={{ float: 'right' }} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="caption" sx={{ lineHeight: '0.5rem' }}>
                            {`${t('emissionsMapPage.introDrawer.forMoreInfo')} `}
                            <MSATLink
                                color={theme.palette.mode === 'dark' ? '#909ad2' : '#3f51b5'}
                                variant="caption"
                                fontWeight="bold"
                                data-testid="faq-mobile"
                                underline="hover"
                                href={faqRoute('/faq')}
                            >
                                {t('emissionsMapPage.introDrawer.methaneSatDotOrg')}
                            </MSATLink>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid paddingTop={2}>
                    <Typography
                        variant="caption"
                        color={colorArrayToRgb(grey)}
                        sx={{
                            lineHeight: '0.5rem',
                            fontSize: '10px'
                        }}
                    >
                        {getBuildInfoForDisplay()}
                    </Typography>
                </Grid>
            </Grid>
        </BelowNavDrawer>
    );
};

export default IntroDrawer;
