import {
    Alert,
    AlertBadge,
    AlertSeverity,
    AlertTitle,
    Grid,
    MarkdownFormatter,
    Skeleton,
    TipsAndUpdatesIcon,
    Typography,
    useTheme
} from '@methanesat/ui-components';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentBreakpoint, useTranslate } from '../../hooks';
import { updateEmissionsMapNotification } from '../../reducers';
import { areNotificationsLoading, NotificationMessage, NotificationsLoading } from '../../types';
import { BelowNavDrawer } from '../BelowNavDrawer';
import { getBreakpointsDown } from '../../utils/breakpoints';

export const NotificationsDrawer = ({
    onClose,
    onWidth,
    open,
    notifications
}: {
    onClose: () => void;
    onWidth: (width: number) => void;
    open: boolean;
    notifications: NotificationMessage[] | NotificationsLoading;
}) => {
    const t = useTranslate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const drawerRef = useRef<HTMLDivElement>(null);

    // memoized callback to mark the first unread notification as 'read'
    const readNextNotification = useCallback(() => {
        if (!open || areNotificationsLoading(notifications)) return;
        const firstUnreadNotification = notifications.find((notification) => !notification.read);
        if (firstUnreadNotification) {
            markRead(firstUnreadNotification);
        }
    }, [notifications, open]);

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    // mark one notification as read
    const markRead = (notification: NotificationMessage) => {
        dispatch(updateEmissionsMapNotification({ message: notification.message, updates: { read: true } }));
    };

    // every 2 seconds after opening, mark another notifications as read
    setTimeout(readNextNotification, 2000);

    return (
        <BelowNavDrawer open={open} onClose={onClose} paperRef={drawerRef}>
            <Grid container spacing={1} paddingX={2} marginBottom={2}>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('emissionsMapPage.notificationsDrawer.title')}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={4} paddingX={2}>
                {areNotificationsLoading(notifications) ? (
                    <Grid item xs={12}>
                        <Skeleton width={'100%'} />
                        <Skeleton width={'100%'} />
                    </Grid>
                ) : notifications.length ? (
                    notifications.map((notification, i) => {
                        return (
                            <Grid item xs={12} key={i}>
                                <AlertBadge
                                    invisible={notification.read}
                                    severity={AlertSeverity.error}
                                    badgeContent={t('emissionsMapPage.notificationsDrawer.unreadBadgeText')}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                >
                                    <Alert
                                        severity={notification.severity}
                                        variant="outlined"
                                        sx={{ position: 'relative' }}
                                        onMouseOver={() => markRead(notification)}
                                        onMouseDown={() => markRead(notification)}
                                        onFocus={() => markRead(notification)}
                                        iconMapping={{
                                            info: <TipsAndUpdatesIcon />
                                        }}
                                    >
                                        {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
                                        <Typography variant="body1" component="div">
                                            <MarkdownFormatter markdown={notification.message} />
                                        </Typography>
                                    </Alert>
                                </AlertBadge>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            {t('emissionsMapPage.notificationsDrawer.noNotificationsMessage')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </BelowNavDrawer>
    );
};
