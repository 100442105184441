import { ReactElement } from 'react';

/**
 * Component to overlay an invisible, clickable div in a position matching a given control.
 * Will need adjusting if we start adding multiple controls to various positions on the map
 * (only accounts for the first control in a position).
 */
export const ClickableControlOverlay = ({
    position = 'bottom-left',
    controlType,
    clickHandler
}: {
    position?: string;
    controlType: string;
    clickHandler: () => void;
}): ReactElement | null => {
    const containerEl = document.getElementsByClassName(`mapboxgl-ctrl-${position}`)[0];
    if (!containerEl) return null;
    const { clientHeight: containerHeight, clientWidth: containerWidth } = containerEl;

    const controlEl = containerEl.getElementsByClassName(`mapboxgl-ctrl-${controlType}`)[0];
    if (!controlEl) return null;
    const { clientHeight: height, clientWidth: width } = controlEl;

    const [ypos, xpos] = position.split('-');

    return (
        <div
            className={`mapboxgl-control-overlay overlay-${position} mapboxgl-ctrl-${position}`}
            style={{ height: containerHeight, width: containerWidth, [xpos]: 0, [ypos]: 0, position: 'absolute' }}
        >
            <div
                data-testid={'clickable-control-overlay'}
                onClick={clickHandler}
                className={`${controlType}-control-overlay mapboxgl-ctrl-${controlType} mapboxgl-ctrl`}
                style={{
                    height,
                    width,
                    borderColor: 'none',
                    color: 'none',
                    opacity: 0,
                    cursor: 'pointer'
                }}
            ></div>
        </div>
    );
};
