import React, { ChangeEvent, FocusEvent, HTMLProps } from 'react';

import { Box } from '../Box';
import { ButtonBase, ButtonBaseProps } from '../Button';
import { useFormControl } from '../Form';
import { useRadioGroup } from '../Radio';

/**
 * This type is a workaround for TypeScript issues with MaterialUI.
 * The muiFormControl expects an event from a div element so a div was chosen
 * for the type.
 * https://mui.com/material-ui/guides/composition/#with-typescript
 */
type DivButtonBaseProps = ButtonBaseProps<'div', { component?: 'div' }>;

type ImageRadioProps = DivButtonBaseProps &
    Pick<HTMLProps<HTMLInputElement>, 'onChange' | 'readOnly' | 'required' | 'autoFocus' | 'name' | 'value'> & {
        checked?: boolean;
        imageSrc: string;
    };

/**
 * This component is a workaround for TypeScript issues with MaterialUI.
 * The muiFormControl expects an event from a div element so a div was chosen
 * for the type.
 * https://mui.com/material-ui/guides/composition/#with-typescript
 */
const DivButtonBase = ({ component = 'div', ...props }: DivButtonBaseProps) => (
    <ButtonBase data-testid="typed-button-base" component={component} {...props} />
);

/**
 * A radio component that is compatible with FormControl and RadioGroup. Uses material-ui implementation
 * of radio as a resource:
 * https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/Radio/Radio.js
 */
const ImageRadio = ({
    autoFocus,
    checked,
    defaultChecked,
    disabled: disabledProp,
    imageSrc,
    name: nameProp,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    required,
    sx,
    tabIndex,
    value,
    ...otherProps
}: ImageRadioProps) => {
    // Get context for FormControl and RadioGroup and call their callbacks and use their
    // values when the element is part of each of these contexts.
    const muiFormControl = useFormControl();
    const radioGroup = useRadioGroup();

    const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        // Notifies other components within this FormControlContext that
        // the form is in focus so that the components can respond accordingly.
        // For example, the FormLabel component will change text color if
        // the form is in focus.
        if (muiFormControl && muiFormControl.onFocus) {
            muiFormControl.onFocus(event);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        // Notifies other components within this FormControlContext that
        // the form is no longer in focus so that the components can respond accordingly.
        if (muiFormControl && muiFormControl.onBlur) {
            muiFormControl.onBlur(event);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event);
        radioGroup?.onChange && radioGroup.onChange(event, event.target.value);
    };

    let disabled = disabledProp;

    if (muiFormControl) {
        if (typeof disabled === 'undefined') {
            disabled = muiFormControl.disabled;
        }
    }

    let name = nameProp;

    if (radioGroup) {
        if (typeof checked === 'undefined') {
            checked = radioGroup.value === value;
        }
        if (typeof name === 'undefined') {
            name = radioGroup.name;
        }
    }
    return (
        <DivButtonBase
            data-testid="image-radio-button"
            disableRipple
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            sx={[
                (theme) => ({
                    cursor: 'pointer',
                    ...(!checked && {
                        border: `calc(${theme.spacing(1)} / 4) solid transparent`
                    }),
                    '&:hover > img': {
                        border: `calc(${theme.spacing(1)} / 4) solid ${theme.palette.primary.main}`,
                        borderRadius: `${theme.shape.borderRadius * 4}px`
                    }
                }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            {...otherProps}
        >
            <Box
                component="input"
                data-testid="image-radio-input"
                autoFocus={autoFocus}
                checked={checked}
                sx={{ appearance: 'none', margin: 0 }}
                defaultChecked={defaultChecked}
                disabled={disabled}
                name={name}
                onChange={handleChange}
                readOnly={readOnly}
                required={required}
                tabIndex={tabIndex}
                type="radio"
                value={value}
            />
            <Box
                component="img"
                sx={(theme) => ({
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1',
                    borderRadius: `${theme.shape.borderRadius * 4}px`,
                    border: `calc(${theme.spacing(1)} / 4) solid transparent`,
                    ...(checked && {
                        padding: `calc(${theme.spacing(1)} / 4)`,
                        border: `calc(${theme.spacing(1)} / 4) solid ${theme.palette.primary.main}`
                    })
                })}
                src={imageSrc}
            />
        </DivButtonBase>
    );
};

export default ImageRadio;
