import React, { PropsWithChildren, ReactNode } from 'react';

import { Grid } from '@methanesat/ui-components';
import { styled } from '../styles';

export interface ChipSelectLabelProps extends PropsWithChildren {
    icon: ReactNode;
}

const Container = styled('div')({
    userSelect: 'none'
});

/**
 * A label made specifically for the ChipSelect component. It is intended to be
 * passed into the Label prop of the ChipSelect component. For usage, search
 * the repository for the ChipSelect component.
 */
const ChipSelectLabel = ({ children, icon }: ChipSelectLabelProps) => {
    return (
        <Container>
            <Grid container spacing={0} sx={{ alignContent: 'center', margin: 0, marginRight: 1.5 }}>
                <Grid item xs="auto" sx={{ alignSelf: 'center', margin: 0 }}>
                    {icon}
                </Grid>
                <Grid
                    item
                    xs="auto"
                    sx={(theme) => {
                        return {
                            alignSelf: 'center',
                            margin: 0,
                            paddingLeft: 1,
                            fontSize: theme.typography.pxToRem(13),
                            justify: 'left'
                        };
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ChipSelectLabel;
