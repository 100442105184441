import { WebMercatorViewport } from '@methanesat/maps';
import type { MapViewState } from '@methanesat/maps';

/**
 * Playwright utility function to add an instance of `Viewport` to window so we can extract x/y position in e2e tests:w
 *
 */
export const playwrightAddViweport = (viewState: MapViewState) => {
    // setting viewState to window for playwright test
    if (window && typeof window !== 'undefined') {
        window._deck_gl_viewPort = new WebMercatorViewport(viewState);
    }
};
