import { Grid, InfoIcon, Tooltip, Typography } from '@methanesat/ui-components';

/** Todo: DP-1001 Enforce label and value key lengths by validating user input */
export const DataRowsModalBody = ({
    dataRows
}: {
    dataRows: {
        label: string | undefined;
        value: number | string | null | boolean | undefined;
        toolTip?: string | undefined;
    }[];
}) => {
    return (
        <>
            {dataRows
                .filter(({ label, value }) => label && value)
                .map(({ label, value, toolTip }) => (
                    <Grid container justifyContent="space-between" alignItems="top" key={label} paddingBottom={1}>
                        <Grid item xs="auto" sx={(theme) => ({ paddingRight: theme.spacing(4) })}>
                            <Typography variant="subtitle1" component="span">
                                {label}
                            </Typography>
                        </Grid>
                        <Grid item xs display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography
                                variant="subtitle1"
                                align="right"
                                sx={{ wordBreak: 'break-word' }}
                                component="div"
                            >
                                {value}
                            </Typography>
                            {toolTip && (
                                <Tooltip title={toolTip} sx={{ marginLeft: 1, fontSize: 18 }}>
                                    <InfoIcon />
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                ))}
        </>
    );
};
