import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { GaugeProps } from '../../types';
import { scaleLinear } from 'd3-scale';
import CenteredMetric from './CenteredMetric';

const DEFAULT_UNITS = 'kg/hr';

/** Two superimposed Responsive Pie charts, creating an Emissions fuel gauge */
const GaugeBase = (
    args: Omit<GaugeProps, 'gradientColors' | 'pinColor' | 'tooltip'> & {
        data: { id: string; value?: number; valueText?: string | number | null }[];
    }
) => {
    const {
        chartHeight,
        data,
        fontSize,
        value,
        valueText,
        min = 0,
        minLabel,
        maxLabel,
        max,
        units,
        style,
        ...remainingArgs
    } = args;

    const isNumeric = !!value && Number.isFinite(value);

    // Use value for the pin, but valueText for the rating
    const chartRating = valueText || '';
    const chartLabel = typeof units !== 'undefined' ? units : DEFAULT_UNITS;

    const gaugeWidth = 2;
    const chartWidth = chartHeight * 2;

    const containerStyle: React.CSSProperties = {
        height: `${chartHeight}px`,
        width: `${chartWidth}px`,
        overflow: 'hidden',
        position: 'relative' /* positioning anchor for position: absolute children */
    };

    const theme = {
        labels: {
            text: {
                fontSize: '11px',
                fontWeight: 'bold',
                textShadow: '0.5px 0.5px 0.5px black',
                dominantBaseline: 'hanging' as const
            }
        }
    };

    /**
     * Note: Pie needs a set width and height in order to render.
     */
    const defaultStyle: React.CSSProperties = {
        fill: 'currentColor',
        height: `${chartHeight * 2}px`,
        left: 0,
        position: 'absolute',
        top: 0,
        width: `${chartWidth}px`,
        ...style
    };

    // The pin showing where the emission falls
    const gaugeCalc = scaleLinear().domain([0, max]).range([-90, 90]).clamp(true);
    let gaugeAngle = 0;
    if (isNumeric) {
        gaugeAngle = gaugeCalc(value);
    }
    const gaugePin = isNumeric ? (
        <div style={defaultStyle}>
            <ResponsivePie
                {...remainingArgs}
                borderColor="white"
                borderWidth={1}
                data={[{ id: '', value: gaugeWidth }]}
                endAngle={gaugeAngle + gaugeWidth}
                innerRadius={0.6}
                layers={['arcs']}
                startAngle={gaugeAngle}
            />
        </div>
    ) : (
        <></>
    );

    // The labeled and decorated range for the viewable Emissions Range
    const gaugeRange = (
        <div style={defaultStyle}>
            <ResponsivePie
                {...remainingArgs}
                arcLabel={({ id: arcPercentage }) => {
                    const isMin = arcPercentage === '10';
                    const isMax = arcPercentage === '100';

                    if (isMax) {
                        return `${maxLabel || max}`;
                    }

                    if (isMin) {
                        return `${minLabel || min}`;
                    }

                    return '';
                }}
                arcLabelsTextColor="white"
                data={data}
                enableArcLabels={isNumeric}
                layers={[
                    'arcs',
                    'arcLabels',
                    CenteredMetric({ chartLabel, chartRating, chartHeight, chartWidth, fontSize })
                ]}
                theme={theme}
            />
        </div>
    );

    return (
        <div style={containerStyle}>
            {gaugeRange}
            {gaugePin}
        </div>
    );
};

export default GaugeBase;
