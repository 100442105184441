import { upperFirst } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { useOGIAPI, useTranslate } from '../../../../../hooks';
import { cleanOgiData, MSatPickInfo, OGITileFeatureProperties, PipelineFeatureProperties } from '../../../../../types';
import { analytics, formatCoordinates } from '../../../../../utils';
import { MapControlAccordionProps } from '../../../../MapControls/MapControlAccordion';
import { InfrastructureModalFooter } from '../../Footers';
import { InfrastructureModalHeader } from '../../Headers';
import { DataRowsModalBody, DataSkeleton, MapDataContainer } from '../../Layout';

export const PipelineDrawer = ({
    info,
    accordion,
    AccordionProps
}: {
    info: MSatPickInfo<GeoJSON.Geometry, OGITileFeatureProperties>;
    accordion?: boolean;
    AccordionProps?: MapControlAccordionProps;
}) => {
    const t = useTranslate();

    const unknownOperatorName = t('emissionsMapPage.mapModals.infrastructure.unknownOperator').toUpperCase();

    const dataAndLoadingState = useOGIAPI<PipelineFeatureProperties>(info);

    const pipelineMetadata = dataAndLoadingState?.data;
    const loadingState = dataAndLoadingState?.loadingState;

    /**
     * `infrastructure_operators` is an array because we may eventually have multiple operators over time.
     */
    const currentOperatorName =
        (pipelineMetadata?.operators && pipelineMetadata?.operators[0]?.operator_name) ?? unknownOperatorName;

    // limits OGIM status to one word so we can use it as a key to get the right translated string from locales
    const ogimStatus = pipelineMetadata?.ogim_status;
    let cleanOgimStatus = undefined;
    if (typeof ogimStatus === 'string') {
        cleanOgimStatus = cleanOgiData(ogimStatus, ' ');
    }

    // limits data source to one item
    const source = pipelineMetadata?.sources[0];

    const metadataForAnalytics = useMemo(
        () =>
            pipelineMetadata && {
                id: `${pipelineMetadata.ogim_id}`,
                latitude: info.coordinate?.[1],
                length: pipelineMetadata.details.pipe_length_km,
                longitude: info.coordinate?.[0],
                operatorName: currentOperatorName
            },
        [pipelineMetadata, info.coordinate, accordion]
    );
    // called from useEffect below or Accordion onChange event
    const logAnalytics = useCallback(
        () => metadataForAnalytics && analytics.openPipelineDetails(metadataForAnalytics),
        [metadataForAnalytics, accordion]
    );

    // tracks user opening details *not* in an accordion
    // viewing details in accordions are tracked in onChange events
    useEffect(() => {
        !accordion && logAnalytics();
    }, [pipelineMetadata, info.coordinate, accordion]);

    // if data has not yet loaded, displays a skeleton
    if (loadingState) {
        return <DataSkeleton />;
    }

    if (!pipelineMetadata) return <></>;

    const {
        properties: { layerName }
    } = info.object;
    const headerProps = {
        layerName: t(`emissionsMapPage.infrastructure.categories.${layerName}`) || upperFirst(layerName),
        operatorName: currentOperatorName,
        sourceDate: source?.src_date
    };

    const dataRows = [
        ...(pipelineMetadata.fac_name
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.infrastructure.facilityLabel'),
                      value: pipelineMetadata.fac_name
                  }
              ]
            : []),
        ...(pipelineMetadata.ogim_status
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.statusLabel')}`,
                      value: upperFirst(t(`emissionsMapPage.mapModals.common.ogimStatus.${cleanOgimStatus}`))
                  }
              ]
            : []),
        ...(pipelineMetadata.install_date
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.installedLabel')}`,
                      value: `${pipelineMetadata.install_date}`
                  }
              ]
            : []),
        ...(pipelineMetadata.details.commodity
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.commodityLabel')}`,
                      value: upperFirst(pipelineMetadata.details.commodity)
                  }
              ]
            : []),
        ...(pipelineMetadata.details.pipe_length_km
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.lengthLabel')}`,
                      value: `${pipelineMetadata.details.pipe_length_km.toLocaleString(undefined, {
                          maximumSignificantDigits: 2
                      })} km`
                  }
              ]
            : []),
        ...(pipelineMetadata.country
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.infrastructure.countryLabel')}`,
                      value: pipelineMetadata.country
                  }
              ]
            : []),
        ...(info.coordinate
            ? [
                  {
                      label: `${t('emissionsMapPage.mapModals.common.locationClickedLabel')}`,
                      // may want to have more significant digits for higher zoom levels
                      value: formatCoordinates(info.coordinate)
                  }
              ]
            : [])
    ];

    return (
        <MapDataContainer
            HeaderItems={<InfrastructureModalHeader {...headerProps} />}
            Body={<DataRowsModalBody dataRows={dataRows} />}
            Footer={<InfrastructureModalFooter />}
            accordion={accordion}
            AccordionProps={{ ...AccordionProps, onChange: (_ev, expanded) => expanded && logAnalytics() }}
        />
    );
};
