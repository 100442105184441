import React from 'react';

import { Grid } from '../Layout';
import { alpha } from '../../utils';

export enum ColorRangeIconScale {
    linear,
    log
}

export interface ColorRangeIconProps {
    colors: string[];
    scale?: ColorRangeIconScale;
}

/**
 * Used to find `a` in the quadratic formula `y = a(x - h)^2 + k`, used by `getLogPercentHeight()`.
 */
export const getLogMultiplier = (minIndex: number, maxIndex: number, minPercent: number, maxPercent: number) => {
    // Solves for `a = (y - k) / (x - h)^2` where h,k is the lowest val and x,y is the highest val.
    // h,k is the low point on the curve, in this case the lowest color (e.g. 1, 20)
    // x,y is a high point on the curve, in this case the highest color (e.g. 5, 100)
    return (maxPercent - minPercent) / Math.pow(maxIndex - minIndex, 2);
};

/**
 * Log scale uses the [quadratic formula](https://en.wikipedia.org/wiki/Quadratic_formula) to approximate an
 * exponential progression.  Values are inexact, the intention is to convey the impression of an exponential
 * series.
 * @param colorIndex Color index (0th-based)
 * @param totalColors Not 0th-based
 */
export const getLogPercentHeight = (colorIndex: number, totalColors: number) => {
    const minColorIndex = 0;
    const maxColorIndex = totalColors - 1; // Second to last value
    const minPercentHeight = 10;
    const maxPercentHeight = 50; // 50% and not 100% since we exaggerate the jump from 50 to 100 (see below).

    const logMultiplier = getLogMultiplier(minColorIndex, maxColorIndex, minPercentHeight, maxPercentHeight);

    // Solves for `y = a(x - h)^2 + k` to find height at `index` position.
    let percentHeight = Math.floor(logMultiplier * Math.pow(colorIndex + 1 - minColorIndex, 2) + minPercentHeight);

    // Exaggerates gap between second highest and highest color to emphasize the exponential nature of the scale.
    const isHighestColorIndex = colorIndex + 1 === totalColors;
    if (isHighestColorIndex) {
        percentHeight = 100;
    }

    return percentHeight;
};

/**
 * Visual representation of a color range shown in a familiar chart style to explicitly communicate low vs high colors.
 * This isn't intended to be numerically accurate (hence no labeled axes), just to convey:
 * 1) low vs high colors
 * 2) log scale grows exponentially and is different than linear
 */
const ColorRangeIcon = ({ colors, scale = ColorRangeIconScale.linear }: ColorRangeIconProps) => {
    return (
        <Grid container aria-hidden={true} sx={{ height: 18, width: 48 }} alignItems={'flex-end'}>
            {colors.map((rawRgb, colorIndex) => {
                const percentHeight =
                    scale === ColorRangeIconScale.linear
                        ? Math.floor(((colorIndex + 1) / colors.length) * 100)
                        : getLogPercentHeight(colorIndex, colors.length);

                const isLowestValue = colorIndex === 0;
                const isLogScale = scale === ColorRangeIconScale.log;
                const shouldReduceOpacityOfLowEmissions = isLowestValue && isLogScale;
                const rgb = shouldReduceOpacityOfLowEmissions ? alpha(rawRgb, 0.5) : rawRgb;

                return (
                    <Grid
                        data-testid={`progress-${colorIndex}`}
                        item
                        xs
                        key={rgb}
                        sx={{
                            height: `${percentHeight}%`,
                            background: rgb
                        }}
                    />
                );
            })}
        </Grid>
    );
};

export default ColorRangeIcon;
