import { Box, CloseIcon, IconButton, keyframes } from '@methanesat/ui-components';
import { TooltipModal } from '../TooltipModal';
import { useAppSelector, useTranslate } from '../../hooks';

// Pulse animation for halo effect.
const pulseAnimation = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`;

/**
 * Draws a halo effect around a region to draw the user's attention.
 */
export const HighlightHalo = () => {
    return (
        <svg viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="70" cy="71" r="58.5" stroke="#FFA829" strokeWidth="5" />
            <circle cx="70.5" cy="70.5" r="69" stroke="#FFA829" strokeWidth="3" />
            <circle cx="70" cy="71" r="48.5" stroke="#FFA829" strokeWidth="3" />
        </svg>
    );
};

/**
 * Intro modal with halo effect and text to highlight interactive app features.
 * Designs: https://www.figma.com/file/rAnzmEyNmM9FqcOyWl8XCl/Mike-O.-Design-Recommendations?type=design&node-id=2050-2&mode=design
 */
export const IntroModal = () => {
    const t = useTranslate();
    const text = useAppSelector((state) => state.tooltip.text);

    const haloSizePx = 50;

    return (
        <TooltipModal modalSelector="#tooltip-modal-container" haloSizePx={haloSizePx}>
            <Box
                component="div"
                data-testid="tooltip-modal-highlight"
                display="flex"
                position="absolute"
                width="max-content"
                zIndex={(theme) => theme.zIndex.tooltip}
            >
                {/* Halo */}
                <Box
                    component="div"
                    width={`${haloSizePx}px`}
                    height={`${haloSizePx}px`}
                    sx={{
                        animation: `${pulseAnimation} ease 2s infinite`,
                        display: 'inline-block'
                    }}
                >
                    <HighlightHalo />
                </Box>
                {text && (
                    <Box
                        component="div"
                        sx={{
                            /** Left side caret */
                            '&::before': {
                                backgroundColor: '#FBF8A4',
                                content: '""',
                                display: 'block',
                                height: '20px',
                                left: '-8px',
                                position: 'absolute',
                                transform: 'rotate(45deg)',
                                width: '20px'
                            },
                            alignItems: 'center',
                            backgroundColor: '#FBF8A4',
                            borderRadius: '5px',
                            color: 'black',
                            cursor: 'pointer',
                            display: 'inline-flex',
                            fontSize: '13px',
                            marginLeft: '1rem',
                            position: 'relative' /* anchor for pos:abs content */,
                            userSelect: 'none',
                            width: '200px'
                        }}
                    >
                        {/* Explanation text */}
                        <Box
                            component="div"
                            sx={{
                                padding: '0.5rem 1rem',
                                textAlign: 'center'
                            }}
                        >
                            {text}
                        </Box>
                        {/* Line separator */}
                        <Box
                            component="div"
                            sx={{
                                height: '100%',
                                borderLeft: '1px solid #C9C683'
                            }}
                        />
                        {/* Close button */}
                        <Box
                            component="div"
                            sx={{
                                padding: '0.25rem'
                            }}
                        >
                            <IconButton
                                aria-label={t('controls.close')}
                                data-testid="tooltip-modal-close"
                                sx={{ color: 'inherit' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </TooltipModal>
    );
};

export default IntroModal;
