import {
    AmbientLight,
    DirectionalLight,
    LightingEffect,
    Material,
    DirectionalLightOptions,
    AmbientLightOptions
} from '@deck.gl/core';
import { white, darkGrey } from '@methanesat/colors';

type MaterialProps = Exclude<Material, boolean>;

const ambientLightProps: AmbientLightOptions = {
    color: white,
    intensity: 2
};

const ambientLight = new AmbientLight(ambientLightProps);

// create directional light sources, relative to map's center
// light source in the SE
const lightPropsSE: DirectionalLightOptions = {
    color: white,
    intensity: 1.5,
    direction: [-2, 4, -0.5] // pointing down and NNW
};
const directionalLightSE = new DirectionalLight(lightPropsSE);

// light source in the NW
const lightPropsNW: DirectionalLightOptions = {
    color: white,
    intensity: 1.5,
    direction: [2, -4, -0.5] // pointing down and SSE
};
const directionalLightNW = new DirectionalLight(lightPropsNW);

// light source directly overhead, relatively weak
const lightPropsOverhead = {
    color: white,
    intensity: 0.25,
    direction: [0, 0, -1]
};
const overheadLight = new DirectionalLight(lightPropsOverhead);

export const lightingEffect = new LightingEffect({
    ambientLight,
    directionalLightSE,
    directionalLightNW,
    overheadLight
});

// specularColor needs `as [number, number, number]` because the typings for
// specularColor are used in deck.gl's shaderlib and imported from @luma.gl
export const hexMaterial: MaterialProps = {
    ambient: 0.3,
    diffuse: 0.65,
    shininess: 64,
    specularColor: darkGrey as [number, number, number]
};
