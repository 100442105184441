import { DateOption } from '@methanesat/ui-components';
import { MAIR_GLOBAL_DATE_OPTIONS, MSAT_GLOBAL_DATE_OPTIONS } from '../consts';
import { isPlatform, STACCollection, Platforms } from '../types';
import { UrlFeatureParams } from '../types';

/**
 * Checks if the URL date parameter is a valid date, is in the format MM-DD-YYYY,
 * and is not before 2020 or in the future.
 */
export function validateUrlDateString(
    unsanitizedDateString: string,
    dateOptions: DateOption[] = [...MAIR_GLOBAL_DATE_OPTIONS, ...MSAT_GLOBAL_DATE_OPTIONS]
) {
    // Regular expression pattern for YYYY-MM-DD
    const regex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    const utcDate = new Date(`${unsanitizedDateString} UTC`);
    // Check if valid date before calling toISOString
    if (isNaN(utcDate.getTime())) return false;
    const sanitizedDateString = utcDate.toISOString().split('T')[0];

    const isValidDateFormat = regex.test(sanitizedDateString);
    const isInTimeRange = 2020 <= utcDate.getFullYear() && utcDate.getTime() <= Date.now();
    const isInDatePicker = dateOptions.some(
        (dateOption) => new Date(dateOption.value).toISOString().split('T')[0] === sanitizedDateString
    );

    return isValidDateFormat && isInTimeRange && isInDatePicker;
}

/**
 * Checks if the platform parameter is either "MethaneSAT or MethaneAIR"
 */
export function validateUrlPlatformParam(unsanitzedPlatform: string) {
    return isPlatform(unsanitzedPlatform);
}

/**
 * Checks if the URL collection-id parameter is a contained within the STACCollection enum.
 */
export function validateUrlCollectionId(unsanitzedCollectionId: string) {
    return Object.values(STACCollection).includes(unsanitzedCollectionId as STACCollection);
}

/**
 * Currently, this only checks that the item-id parameter is a non-empty string since
 * naming conventions for STAC items are still in the works. Once those are finalized
 * stricter validation should be implemented.
 */
export function validateUrlItemId(unsanitizedItemId: string) {
    return typeof unsanitizedItemId === 'string' && unsanitizedItemId.length > 0;
}

/**
 * Checks if the URL feature-type parameter is a valid feature type.
 */
export function validateUrlFeatureType(unsanitizedFeatureType: string) {
    return Object.values(UrlFeatureParams).includes(unsanitizedFeatureType as UrlFeatureParams);
}
