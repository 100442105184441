import { BaseSyntheticEvent } from 'react';
import { Checkbox, FormControlLabel } from '@methanesat/ui-components';
import { useTranslate } from '../../hooks';

export const ToggleableLayerHeader = ({
    checked,
    disabled,
    indeterminate,
    label,
    onChange
}: {
    checked: boolean;
    disabled: boolean;
    indeterminate: boolean;
    label: string;
    onChange: (input: boolean) => void;
}) => {
    const t = useTranslate();
    return (
        <FormControlLabel
            data-testid="map-control-accordion-form-control-label"
            aria-label={t('mapControls.ariaLabelForToggle', { layer: label })}
            onClick={(event: BaseSyntheticEvent) => {
                event.stopPropagation();
                const isChecked = event.target.checked;

                const isLabelClick = isChecked === undefined;
                if (!isLabelClick) onChange(isChecked);
            }}
            onFocus={(event) => {
                event.stopPropagation();
            }}
            control={<Checkbox checked={checked} indeterminate={indeterminate} />}
            value={checked}
            label={label}
            disabled={disabled}
        />
    );
};
