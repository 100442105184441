import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Divider, Grid, Typography, Link as MSATLink, InfoIcon, MarkdownFormatter } from '@methanesat/ui-components';

import { selectMethaneProductIsL4, selectPlatform } from '../../../../../reducers';
import { useTranslate } from '../../../../../hooks';
import { AreaEmissionDrawerProps, MethaneLayerIds } from '../../../../../types';
import { analytics, formatCoordinates, formatMeasurementTime, getFeatureLinkLabel } from '../../../../../utils';
import { MethaneModalHeader } from '../../Headers';
import { DataRowsModalBody, MapDataContainer, FeatureLink } from '../../Layout';
import { EmissionsHeadline } from './EmissionsHeadline';
import { MoreInfoSection } from './MoreInfoSection';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { METHANESAT_FAQ_URL } from '../../../../../consts';

// TODO:  DP-1892 MethaneRasterDataDrawer and PlumeEmissionsDrawer are nearly identical and can
// likely be refactored to consolidate code
export const MethaneRasterDataDrawer = ({ info, accordion, AccordionProps }: AreaEmissionDrawerProps) => {
    const { coordinate, object } = info;

    const id = object.id;
    const collection = object.properties.collectionId;
    const collectionEndTime = object.properties.collectionEndTime;
    const collectionStartTime = object.properties.collectionStartTime;
    const platform = useSelector(selectPlatform);
    const label = getFeatureLinkLabel(platform, id, object.properties.sceneId);

    const isL4 = useSelector(selectMethaneProductIsL4);
    useEffect(() => {
        analytics.openAreaEmissionDetails({
            emissionRate: info.object.properties.methane,
            /**
             * Likely unnecessary to find centroid.
             * For permian latitudes, if area is approx 1 km sq, there is no
             * difference in formatted coordinates of the centroid (calculated by turf.js)
             * and the coordinates of the point the user clicked
             */
            latitude: info.coordinate?.[1],
            longitude: info.coordinate?.[0]
        });
    }, [info.object, info.coordinate]);
    const t = useTranslate();

    const headerProps = {
        description: isL4 ? undefined : t('emissionsMapPage.concentrations.areaDescription'),
        title: isL4
            ? t('emissionsMapPage.mapModals.emissions.areaTitle')
            : t('emissionsMapPage.mapModals.concentrations.areaTitle')
    };

    const moreInfoProps = {
        header: t('emissionsMapPage.dispersedSourceDrawer.moreInfo.header'),
        description: t('emissionsMapPage.dispersedSourceDrawer.moreInfo.algorithm'),
        bullets: [
            t('emissionsMapPage.dispersedSourceDrawer.moreInfo.bullet1'),
            t('emissionsMapPage.dispersedSourceDrawer.moreInfo.bullet2')
        ],
        forMoreInfo: t('emissionsMapPage.dispersedSourceDrawer.moreInfo.forMoreInfo', { link: METHANESAT_FAQ_URL })
    };

    const { methane: methaneKgHrKm2 } = info.object.properties;

    const dataRows = [
        ...(info.coordinate
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.common.coordinatesLabel'),
                      value: formatCoordinates(info.coordinate)
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.emissions.measurementTimeLabel'),
            value: formatMeasurementTime(collectionStartTime, collectionEndTime)
        },
        ...(isL4
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.common.dimensionsLabel'),
                      value: '~1km²'
                  }
              ]
            : [])
    ];
    return (
        <MapDataContainer
            HeaderItems={<MethaneModalHeader {...headerProps} />}
            Body={
                <>
                    <EmissionsHeadline
                        emissions={methaneKgHrKm2}
                        shouldFormatEmissions={isL4}
                        units={isL4 ? t('areaEmissions.l4Units') : t('areaEmissions.l3Units')}
                    />
                    <Grid container paddingTop={4} paddingBottom={1}>
                        <Typography variant="h5">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
                    </Grid>
                    <DataRowsModalBody {...{ dataRows }} />
                    {/** Info Links */}
                    <MoreInfoSection {...moreInfoProps} />
                    {/* Shareable link to feature */}
                    {SHOW_SHAREABLE_FEATURE_LINK && coordinate && (
                        <FeatureLink
                            coordinates={coordinate}
                            itemId={id}
                            collectionId={collection}
                            featureType={MethaneLayerIds.areaEmissionRaster}
                            date={collectionEndTime}
                            label={label}
                            platform={platform}
                        />
                    )}
                </>
            }
            accordion={accordion}
            AccordionProps={AccordionProps}
        />
    );
};
