import { BaseSyntheticEvent, ReactElement, useState, useRef, cloneElement } from 'react';
import isEqual from 'lodash/isEqual';
import {
    Autocomplete,
    AutocompleteProps,
    Button,
    Chip,
    Grid,
    InputAdornment,
    SearchIcon,
    TextField,
    TextFieldProps
} from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';

interface Option {
    id: string | number;
    label: string | number;
}

interface AutocompleteWithChipsProps<
    Multiple extends boolean | undefined,
    _DisableClearable extends boolean,
    FreeSolo extends boolean | undefined
> extends Omit<
        AutocompleteProps<Option, Multiple, boolean, FreeSolo>,
        'renderInput' | 'onChange' | 'value' | 'disableClearable'
    > {
    clearAllButton?: boolean;
    TextFieldProps?: TextFieldProps;
    onChange: (elements: Option[]) => void;
    value: Option[];
    maxChips?: number;
    chipComponent?: ReactElement;
}

/**
 * An autocomplete component that displays select values as chips underneath the input.
 */
const AutocompleteWithChips = ({
    blurOnSelect = true,
    chipComponent = <Chip />,
    clearAllButton = false,
    disableCloseOnSelect = true,
    disabled,
    maxChips = 1000,
    onChange,
    options,
    TextFieldProps = { InputProps: {} },
    value,
    ...restOfProps
}: AutocompleteWithChipsProps<true, false, false>): ReactElement => {
    const [inputValue, setInputValue] = useState<string>('');
    const textInputRef = useRef<HTMLInputElement>(null);
    const t = useTranslate();
    const { InputProps: inputFieldProps, ...restOfTFProps } = TextFieldProps;

    const clearAll = () => {
        onChange([]);
    };

    const handleItemSelected = (_event: BaseSyntheticEvent, newItems: Option[], updateReason: string) => {
        const valueIds = value.map(({ id }) => id);
        const hasNewItem = updateReason === 'selectOption';
        const newItemsToAdd = newItems.filter((item) => !valueIds.includes(item.id));
        if (hasNewItem && newItemsToAdd.length) {
            onChange([...value, ...newItemsToAdd].sort((a, b) => (a.label < b.label ? -1 : 1)));
        }
    };

    const handleChipDeleted = (newItem: Option) => {
        onChange(value.filter((item) => !isEqual(item, newItem)));
    };

    const handleInputChange = (_event: BaseSyntheticEvent, newValue: string) => {
        setInputValue(newValue);
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Autocomplete
                    data-testid="autocomplete-with-chips"
                    openOnFocus
                    blurOnSelect={blurOnSelect}
                    disableCloseOnSelect={disableCloseOnSelect}
                    disabled={disabled || value.length >= maxChips}
                    disableClearable={!clearAllButton}
                    filterSelectedOptions
                    getOptionLabel={(option) => `${option.label}`}
                    inputValue={inputValue}
                    multiple
                    onChange={handleItemSelected}
                    onInputChange={handleInputChange}
                    options={options}
                    value={value}
                    renderInput={({ InputProps, ...params }) => (
                        <TextField
                            data-testid="autocomplete-with-chips-text-field"
                            variant="outlined"
                            ref={textInputRef}
                            InputProps={{
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                ...inputFieldProps
                            }}
                            onClick={() => {
                                /**
                                 * Workaround for iPad, see https://methanesat.atlassian.net/browse/DP-961
                                 * Makes sure the textbox is scrolled to the top of the screen to maximize
                                 * virtual keyboard space.
                                 */
                                textInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }}
                            {...params}
                            {...restOfTFProps}
                        />
                    )}
                    {...restOfProps}
                />
            </Grid>
            {clearAllButton && value.length > 0 && (
                <Grid item xs={12} paddingTop={2}>
                    <Button color="primary" variant="outlined" data-testid="autocomplete-clear-all" onClick={clearAll}>
                        {t('emissionsMapPage.mapControls.infrastructureOperatorFilter.clearAll', {
                            count: value.length
                        })}
                    </Button>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                container
                spacing={1}
                component="ul"
                sx={{ listStyle: 'none', padding: 0, marginTop: 0.5 }}
            >
                {value.map((item) => (
                    <Grid item xs="auto" component="li" key={item.id}>
                        {cloneElement(chipComponent, {
                            color: 'primary',
                            ...item,
                            onDelete: () => handleChipDeleted(item),
                            ...chipComponent.props
                        })}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default AutocompleteWithChips;
