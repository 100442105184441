import React from 'react';
import { TimeSelectorDay } from './TimeSelectorDay';
import { Box } from '../Box';
import Typography from '../Typography';
import { HorizontalScroll } from '../HorizontalScroll';

type TimeSelectorProps = {
    'data-testid': string;
    data: {
        color: string;
        selected: boolean;
        label: string;
        key: string | number;
        boxHeight: string;
        onClick: () => void;
        disabled?: boolean;
    }[];
    centerLabel?: boolean;
    platform?: string;
    enableResizeObserver?: boolean;
};

/**
 *
 * Takes in date/time values to render a list of elements that will update map data when clicked
 *
 */
export const TimeSelector = ({
    'data-testid': dataTestId,
    data,
    centerLabel,
    platform,
    enableResizeObserver
}: TimeSelectorProps) => {
    return (
        <Box key={platform} display="flex" data-testid={dataTestId}>
            <HorizontalScroll
                enableResizeObserver={enableResizeObserver}
                centerAlignArrows
                elements={data.map((item) => {
                    return (
                        <Box
                            key={item.key}
                            width="60px"
                            textAlign="left"
                            gap={1}
                            display="flex"
                            flexDirection="column"
                            onClick={!item.disabled ? item.onClick : undefined}
                            marginRight={0.5}
                        >
                            <TimeSelectorDay
                                height={item.boxHeight}
                                color={item.color}
                                selected={item.selected}
                                date={centerLabel ? item.label : undefined}
                                disabled={item.disabled}
                            />
                            {!centerLabel && (
                                <Typography
                                    sx={{
                                        fontWeight: item.selected ? 700 : 400
                                    }}
                                >
                                    {item.label}
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            ></HorizontalScroll>
        </Box>
    );
};
