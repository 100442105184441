import { Typography } from '@methanesat/ui-components';
import { useTranslate } from '../../hooks';

interface CustomizeDownloadHeaderProps {
    fileName: string;
}

/**
 * A header for the CustomizeDownload flow.
 */
const CustomizeDownloadHeader = ({ fileName }: CustomizeDownloadHeaderProps) => {
    const t = useTranslate();

    return (
        <>
            <Typography variant="h1">{t('emissionsMapPage.downloadDataDrawer.customizationFlowTitle')}</Typography>
            <Typography variant="subtitle1">{fileName}</Typography>
        </>
    );
};

export default CustomizeDownloadHeader;
