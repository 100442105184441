import { Breakpoint, Theme } from '@methanesat/ui-components';

export const getBreakpointsDown = (breakpoint: Breakpoint, theme: Theme) => {
    const breakpointKeys = Object.entries(theme.breakpoints.values)
        .sort(([_aKey, aValue]: [string, number], [_bKey, bValue]: [string, number]) => {
            return aValue < bValue ? -1 : 1;
        })
        .map(([key]) => key);
    return breakpointKeys.slice(0, breakpointKeys.indexOf(breakpoint) + 1);
};
