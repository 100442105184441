import React, { ReactElement } from 'react';
import { default as MuiSnackbar } from '@mui/material/Snackbar';

import { SnackbarProps } from '../../types';

const Snackbar = ({
    autoHideDuration = 6000,
    ClickAwayListenerProps = { mouseEvent: false, touchEvent: false },
    ...otherProps
}: SnackbarProps): ReactElement => {
    const props = {
        autoHideDuration,
        ClickAwayListenerProps,
        ...otherProps
    };
    return <MuiSnackbar {...props} />;
};

export default Snackbar;
