import { BaseSyntheticEvent } from 'react';
import { darken, lighten } from '@methanesat/ui-components';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from '@methanesat/ui-components';
import { useTranslate } from '../../hooks';
import { MethaneDiffuseRangeFilterKey, MethaneDistinctRangeFilterKey } from '../../types';

export const MethaneRateFilter = ({
    id,
    'data-testid': dataTestId,
    disabled,
    label,
    layerEnabled,
    onSelectionsChange,
    onEnabledChange,
    options,
    prompt,
    selections,
    subFiltersEnabled = true,
    variant
}: {
    id: string;
    'data-testid': string;
    disabled: boolean;
    label?: string;
    layerEnabled: boolean;
    onSelectionsChange: (selections: MethaneDiffuseRangeFilterKey[] | MethaneDistinctRangeFilterKey[]) => void;
    onEnabledChange: (enabled: boolean) => void;
    options: MethaneDiffuseRangeFilterKey[] | MethaneDistinctRangeFilterKey[];
    prompt?: string;
    selections?: string[];
    subFiltersEnabled: boolean;
    variant: 'distinct' | 'diffuse';
}) => {
    const theme = useTheme();
    const t = useTranslate();
    const isSmallAndUp = useMediaQuery(theme.breakpoints.up('drawer'));

    const handleFilterSelection = (
        _ev: BaseSyntheticEvent,
        newSelections: MethaneDiffuseRangeFilterKey[] | MethaneDistinctRangeFilterKey[]
    ) => {
        onSelectionsChange(newSelections);
    };

    const handleChecked = (_ev: BaseSyntheticEvent, newChecked: boolean) => {
        if (newChecked && subFiltersEnabled) {
            onSelectionsChange(options);
            onEnabledChange(newChecked);
        } else {
            onEnabledChange(newChecked);
        }
    };
    return (
        <>
            <FormControlLabel
                data-testid={`${dataTestId}-checkbox`}
                control={<Checkbox />}
                onChange={handleChecked}
                label={label}
                checked={layerEnabled}
                disabled={disabled}
            ></FormControlLabel>
            {subFiltersEnabled && (
                <Grid
                    container
                    flexDirection={'column'}
                    spacing={2}
                    alignItems="flexStart"
                    flexWrap="nowrap"
                    paddingLeft={label ? 4 : 0}
                >
                    {prompt && (
                        <Grid item xs="auto">
                            <Typography color={disabled ? 'text.disabled' : 'text.primary'}>{prompt}</Typography>
                        </Grid>
                    )}
                    <Grid item xs>
                        <ToggleButtonGroup
                            data-testid={`${dataTestId}-value-buttons`}
                            value={selections}
                            aria-label={`${id}-buttons`}
                            onChange={handleFilterSelection}
                            size="small"
                            disabled={disabled}
                            orientation={isSmallAndUp ? 'horizontal' : 'vertical'}
                            sx={{
                                width: '100%',
                                flexWrap: {
                                    xs: 'wrap',
                                    drawer: 'nowrap'
                                }
                            }}
                        >
                            {options.map((key) => (
                                <ToggleButton
                                    data-testid={`${dataTestId}-${key}-button`}
                                    key={key}
                                    value={key}
                                    sx={(theme) => ({
                                        whiteSpace: 'nowrap',
                                        width: {
                                            xs: '100%'
                                        },
                                        '&:hover': {
                                            backgroundColor: lighten(theme.palette.primary.main, 0.7),
                                            color: theme.palette.primary.dark
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            '&:hover': {
                                                backgroundColor: darken(theme.palette.primary.main, 0.3)
                                            }
                                        }
                                    })}
                                >
                                    {t(`emissionsMapPage.mapControls.methaneFilters.${variant}.${key}`)}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
