import { ReactNode } from 'react';

import { Grid, styled } from '@methanesat/ui-components';

import { ONE_HUNDRED_VH_STYLING } from '../../consts';

interface CustomizeDownloadProps {
    header: ReactNode;
    description: ReactNode;
    body: ReactNode;
    footer: ReactNode;
}

const Grid100Vh = styled(Grid)({
    height: ONE_HUNDRED_VH_STYLING,
    padding: 8
});

/**
 * Positions other components on the page for customizing data download.
 */
const CustomizeDownload = ({ header, description, body, footer }: CustomizeDownloadProps) => {
    return (
        <Grid100Vh container spacing={2} direction="column" wrap="nowrap">
            <Grid item xs="auto">
                {header}
            </Grid>
            <Grid item xs="auto">
                {description}
            </Grid>
            <Grid item xs sx={{ width: '100%', overflowY: { xs: 'auto', md: 'visible' } }}>
                {body}
            </Grid>
            <Grid item xs="auto">
                {footer}
            </Grid>
        </Grid100Vh>
    );
};

export default CustomizeDownload;
