// for basins and targets
import { Grid, Typography } from '@methanesat/ui-components';

export const TargetModalHeader = ({ name, source, flightId }: { name: string; source: string; flightId: string }) => {
    return (
        <>
            <Grid container justifyContent="space-between" flexDirection="column">
                <Grid item xs>
                    <Typography variant="h5">{name}</Typography>
                    <Typography variant="h6" paddingTop={1}>
                        {source} {flightId}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};
