import React from 'react';
import { Grid, InfoIcon, MarkdownFormatter, Typography, Link as MSATLink } from '@methanesat/ui-components';
import { useTranslate } from '../../../../../../src/hooks';

interface forMoreInfoSectionProps {
    header?: string;
    description: string;
    bullets?: string[];
    forMoreInfo?: string;
}

/** This component is used in the dispersed source and distinct source drawers to give the user more information about the data type they've clicked on.
 * It is the text within the yellow box.
 */
export const MoreInfoSection = ({ header, description, bullets, forMoreInfo }: forMoreInfoSectionProps) => {
    const t = useTranslate();
    return (
        <Grid
            container
            padding={2}
            paddingTop={1}
            sx={{ backgroundColor: 'rgba(237, 251, 89, 0.12)', marginBottom: '3px', marginTop: '12px' }}
        >
            <Grid item xs={1} paddingRight={1}>
                <InfoIcon sx={{ float: 'right' }} />
            </Grid>
            <Grid item xs={11}>
                {header && (
                    <Typography variant="caption" fontWeight="bold">
                        {header}
                    </Typography>
                )}
                <Grid>
                    <Typography variant="caption">{description}</Typography>
                </Grid>
                {bullets && (
                    <ul>
                        {bullets.map((point, index) => (
                            <li key={index}>
                                <Typography variant="caption">{point}</Typography>
                            </li>
                        ))}
                    </ul>
                )}
                {forMoreInfo && (
                    <Typography variant="caption">
                        <MarkdownFormatter
                            markdown={forMoreInfo}
                            components={{
                                a: ({ href, children }) => (
                                    <MSATLink
                                        color="#3f51b5"
                                        variant="caption"
                                        fontWeight="bold"
                                        data-testid="faq-mobile"
                                        underline="hover"
                                        href={href}
                                        target="_blank"
                                    >
                                        {children}
                                    </MSATLink>
                                )
                            }}
                        />
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
