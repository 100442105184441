import { ContentCopyIcon, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@methanesat/ui-components';

import { calculateMapZoomLevelForFeature, useLocaleRoute, useTranslate } from '../../../../hooks';
import { MethaneLayerIds, UrlFeatureToId } from '../../../../types';
import { getDatetimeOneMonthRangeString } from '../../../../utils';

export const FeatureLink = ({
    coordinates,
    date,
    collectionId,
    itemId,
    featureType,
    label,
    platform
}: {
    coordinates: GeoJSON.Position;
    date: string;
    collectionId: string;
    itemId: string | number;
    featureType: MethaneLayerIds;
    label: string;
    platform: string;
}) => {
    const t = useTranslate();
    const generateLocaleRoute = useLocaleRoute();

    // Formats the date parameter to use either the date of the reading, or the end date of the month of the reading.
    const formatDateParam = (date: string) => {
        return getDatetimeOneMonthRangeString(new Date(date)).split('/')[1].split('T')[0];
    };

    const dateParam = formatDateParam(date);
    const featureParam = [...UrlFeatureToId.entries()].find(([_key, value]) => value === featureType)?.[0] || '';

    const baseUrl = window.location.origin;
    const mapPath = generateLocaleRoute('/emissions-map/');
    const queryParams = new URLSearchParams({
        'view-latitude': coordinates[1].toFixed(5),
        'view-longitude': coordinates[0].toFixed(5),
        'view-zoom': calculateMapZoomLevelForFeature(featureType).toString(),
        date: dateParam,
        platform: platform,
        'collection-id': collectionId,
        'item-id': itemId.toString(),
        'feature-type': featureParam
    });
    const shareableURL = `${baseUrl}${mapPath}?${queryParams.toString()}`;

    return (
        <>
            <Grid item xs={12} sx={(theme) => ({ paddingTop: theme.spacing(2) })}>
                <Typography variant="h5" sx={{ marginBottom: 0.5 }}>
                    {t(`emissionsMapPage.mapModals.linksToFeatures.${featureType}`)}
                </Typography>
                <Paper variant={'elevation'} sx={{ position: 'relative', padding: 2, display: 'flex' }}>
                    <Typography variant="body2" sx={{ fontSize: 14.5, marginRight: 1, fontStyle: 'italic' }}>
                        {`${label}, ${date.split('T')[0].split('-').join('/')}`}
                    </Typography>
                    <Tooltip title={t('emissionsMapPage.mapModals.linksToFeatures.tooltipTitle')}>
                        <IconButton
                            data-testid="copy-feature-link"
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                            onClick={() => navigator.clipboard.writeText(shareableURL)}
                        >
                            <ContentCopyIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                </Paper>
            </Grid>
        </>
    );
};
