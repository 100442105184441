import { Skeleton } from '@methanesat/ui-components';

export const PlumeDataSkeleton = ({ dataRows }: { dataRows: { label: string | undefined }[] }) => {
    return (
        <>
            {dataRows.map(({ label }) => (
                <Skeleton data-testid="plume-loading-skeleton" key={label} height={35}></Skeleton>
            ))}
        </>
    );
};
