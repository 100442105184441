import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, Grid, Typography } from '@methanesat/ui-components';

import { INFRASTRUCTURE_API_BASE_URL, SHOW_OGI_NEAR_PLUMES } from '../../../../../environmentVariables';
import { getOGIMetadataFetchOptions, useClearedPreviousData, useDataAPI, useTranslate } from '../../../../../hooks';
import {
    OGILayerIds,
    PipelineFeatureProperties,
    PlumeDrawerProps,
    PointOGIMGeoJSONProperties,
    MethaneLayerIds
} from '../../../../../types';
import { analytics, formatCoordinates, formatMeasurementTime, getFeatureLinkLabel } from '../../../../../utils';
import { MethaneModalHeader } from '../../Headers';
import { DataRowsModalBody, MapDataContainer, FeatureLink } from '../../Layout';
import { OGIDetails, OGIDetailsExitButton, OGIMoreInfoButton, OGISummary } from '../OGINearEmissions';
import { OGINearPlumesAlert } from '../OGINearEmissions/OGINearPlumesAlert';
import { EmissionsHeadline } from './EmissionsHeadline';
import { MoreInfoSection } from './MoreInfoSection';
import {
    resetOgiNearPlumes,
    selectEmissionsMapFilters,
    selectPlatform,
    setOgiNearPlumes
} from '../../../../../reducers';
import { log } from '@methanesat/log';
import { RootState } from 'packages/app/src/store';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { METHANESAT_FAQ_URL } from '../../../../../consts';

// TODO:  DP-1892 MethaneRasterDataDrawer and PlumeEmissionsDrawer are nearly identical and can
// likely be refactored to consolidate code

const RADIUS_M = 500;

/**
 * Distinct point source drawer.
 * Warning: info.coordinate is the coordinate of where the user clicked to activate the drawer, NOT the coordinate of
 * the underlying data.
 */
export const PlumeEmissionsDrawer = ({ info, accordion, AccordionProps }: PlumeDrawerProps) => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const [longitude, latitude] = info.object.geometry.coordinates;
    const plumeFlux = info.object.properties.flux;
    const methaneLowerBounds = info.object.properties.flux_lo;
    const methaneUpperBounds = info.object.properties.flux_hi;
    const collectionStartTime = info.object.properties.start_datetime;
    const collectionEndTime = info.object.properties.end_datetime;
    const instrument = info.object.properties.instrument;
    const location = info.object.properties.location;
    const itemId = info.object.itemId;
    const collection = info.object.collection;

    const platform = useSelector(selectPlatform);
    const label = collection && itemId && getFeatureLinkLabel(instrument, itemId, location);

    // Based on whether we are filtering by ogim data, we want to show the details
    const showOgiDetails = useSelector((state: RootState) => selectEmissionsMapFilters(state).ogimFeatures.length > 0);

    useEffect(() => {
        if (plumeFlux !== undefined && plumeFlux >= 0) {
            analytics.openPlumeEmissionDetails({
                id: `${info.object.id}`,
                emissionRate: plumeFlux,
                latitude,
                longitude
            });
        }
    }, [info.object.id, plumeFlux]);

    // Closes the drawer and undoes the ogiNearPlumes action
    const resetFixedState = () => {
        dispatch(resetOgiNearPlumes());
    };

    // Fetch the OGIM data near plumes
    const fetchedNearPlumes = useDataAPI<{ ogim_id: number; layer_name: OGILayerIds; distance_m: number }[] | []>(
        SHOW_OGI_NEAR_PLUMES
            ? `${INFRASTRUCTURE_API_BASE_URL}/rpc/features_within_radius?lng_center=${longitude}&lat_center=${latitude}&radius_m=${RADIUS_M}`
            : null
    );

    // When params change, return undefined so we know when we are fetching data
    const nearPlumes = useClearedPreviousData(fetchedNearPlumes, { longitude, latitude, RADIUS_M });

    const ogimIdsToFetch = nearPlumes?.map(({ ogim_id }) => ogim_id);

    // Get additional metadata for the OGIM data near plumes
    const fetchedOgiCloseToPlumes = useDataAPI<PipelineFeatureProperties[] | PointOGIMGeoJSONProperties[]>(
        ogimIdsToFetch && ogimIdsToFetch.length > 0 ? `${INFRASTRUCTURE_API_BASE_URL}/rpc/feature_meta_data` : null,
        getOGIMetadataFetchOptions(ogimIdsToFetch || [])
    );

    // When params change, return undefined so we know when we are fetching data
    const ogiCloseToPlumes = useClearedPreviousData(fetchedOgiCloseToPlumes, {
        // sort ids so the list can be compared regardless of order,
        // and use a string, so testing equality is straightforward
        ogimIdsToFetch: ogimIdsToFetch?.sort().join(',')
    });

    // Get the layer types of the OGIM data that is near plumes indexed by the ogim id
    const nearPlumesTypes = nearPlumes?.reduce<{ [key: string]: OGILayerIds }>((accum, { ogim_id, layer_name }) => {
        accum[ogim_id] = layer_name;
        return accum;
    }, {});

    // Add the layer type of ogim data to the remaining metadata
    const ogiNearPlumes =
        nearPlumesTypes && ogiCloseToPlumes?.map((ogi) => ({ ...ogi, layerName: nearPlumesTypes[ogi.ogim_id] }));

    const headerProps = {
        title: showOgiDetails
            ? t('emissionsMapPage.mapModals.emissions.nearbyInfrastructureTitle')
            : t('emissionsMapPage.mapModals.emissions.plumeTitle'),
        description: undefined
    };

    const moreInfoProps = {
        header: t('emissionsMapPage.plumeDrawer.defaultMoreInfo.header'),
        description: t('emissionsMapPage.plumeDrawer.defaultMoreInfo.algorithm'),
        bullets: [
            t('emissionsMapPage.plumeDrawer.defaultMoreInfo.bullet1'),
            t('emissionsMapPage.plumeDrawer.defaultMoreInfo.bullet2')
        ],
        forMoreInfo: t('emissionsMapPage.plumeDrawer.defaultMoreInfo.forMoreInfo', { link: METHANESAT_FAQ_URL })
    };

    const dataRows = [
        {
            label: t('emissionsMapPage.mapModals.common.locationLabel'),
            value: location
        },
        ...(info.object.geometry.coordinates
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.common.coordinatesLabel'),
                      value: formatCoordinates(info.object.geometry.coordinates)
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.emissions.measurementTimeLabel'),
            value: formatMeasurementTime(collectionStartTime, collectionEndTime)
        }
    ];

    const handleShowOgiDetails = () => {
        if (longitude !== undefined && latitude !== undefined && ogiNearPlumes && !!plumeFlux) {
            // We add 0.01 degree to the longitude to make the map look centered when the drawer is open
            dispatch(setOgiNearPlumes({ longitude: longitude + 0.01, latitude, ogimFeatures: ogiNearPlumes }));
            analytics.showOgiNearPlumes({
                id: `${info.object.id}`,
                count: ogiNearPlumes.length,
                emissionRate: plumeFlux,
                latitude: info.coordinate?.[1],
                longitude: info.coordinate?.[0]
            });
        }
    };

    // Verify plumeFlux was set,
    // NOTE: this is intentionanly called after all the hooks are created
    if (plumeFlux === undefined || plumeFlux < 0) {
        log.error('Plume data does not contain valid methane value: flux undefined on plume');
        throw Error('Plume data does not contain valid methane value');
        //TODO: Ticket to analyse analytics and recovery after an error state
    }

    return (
        <MapDataContainer
            data-testid="plume-emissions-drawer"
            HeaderItems={<MethaneModalHeader {...headerProps} />}
            Body={
                <>
                    <EmissionsHeadline
                        emissions={plumeFlux}
                        lowEmissions={methaneLowerBounds}
                        highEmissions={methaneUpperBounds}
                        units={t('plumeEmissions.units')}
                    />
                    {/* Show plume metadata & OGI summary */}
                    {!showOgiDetails && (
                        <>
                            {/* Plume metadata */}
                            <Grid container paddingTop={4} paddingBottom={1}>
                                <Grid item xs>
                                    <Typography variant="h5">
                                        {t('emissionsMapPage.mapModals.common.dataTableTitle')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <DataRowsModalBody {...{ dataRows }} />
                            <Divider flexItem sx={{ width: '100%', marginTop: 2 }} />
                            {/* OGI info */}
                            {SHOW_OGI_NEAR_PLUMES && (
                                <>
                                    {nearPlumes && <OGISummary features={nearPlumes} />}
                                    {/* This is very important that it is ogiNearPlumes!! Otherwise button won't work */}
                                    {ogiNearPlumes ? (
                                        <Grid container marginTop={4} rowSpacing={2}>
                                            <Grid item xs>
                                                <OGINearPlumesAlert />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                display="flex"
                                                justifyContent="center"
                                                sx={{ marginBottom: 1 }}
                                            >
                                                <OGIMoreInfoButton
                                                    onClick={handleShowOgiDetails}
                                                    data-testid="ogi-near-plume-prompt-button"
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs sx={{ marginTop: '48px' }}>
                                            <OGINearPlumesAlert />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {/* Show OGI details */}
                    {showOgiDetails && SHOW_OGI_NEAR_PLUMES && (
                        <Grid container rowSpacing={4}>
                            {ogiNearPlumes && (
                                <Grid item xs={12}>
                                    <OGIDetails features={ogiNearPlumes} />
                                </Grid>
                            )}
                            <Grid item xs>
                                <OGINearPlumesAlert />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" sx={{ marginBottom: 3 }}>
                                <OGIDetailsExitButton
                                    data-testid="ogi-near-plumes-exit-button"
                                    onClick={resetFixedState}
                                    text={t(
                                        'emissionsMapPage.infrastructure.ogiNearEmissions.plumes.returnToEmissions'
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {/** Info Links */}
                    {!showOgiDetails && <MoreInfoSection {...moreInfoProps}></MoreInfoSection>}
                    {/* Shareable link to feature */}
                    {SHOW_SHAREABLE_FEATURE_LINK && label && (
                        <FeatureLink
                            coordinates={[longitude, latitude]}
                            itemId={itemId}
                            collectionId={collection}
                            featureType={MethaneLayerIds.plumeEmissionRate}
                            date={collectionStartTime}
                            label={label}
                            platform={platform}
                        />
                    )}
                </>
            }
            accordion={accordion}
            AccordionProps={AccordionProps}
        />
    );
};
