function buildPinDef(color = 'rgba(0,0,0,1)') {
    return {
        id: 'line',
        type: 'linearGradient',
        colors: [
            { offset: 0, color },
            { offset: 100, color }
        ]
    };
}

/**
 * This uses Nivo linearGradient type to create a semi-radial gradient.
 * Nivo does not yet provide a radial gradient option.
 *
 * Adds a def for the gauge's pin, black by default.
 */
function buildGaugeDefs(colors: string[], pinColor?: string) {
    const defs = [];

    // start at i = 1 to build a gradient 0-1, 1-2, 2-3, etc
    for (let i = 1; i < colors.length; i++) {
        const startColor = colors[i - 1];
        const endColor = colors[i];
        const angleOfSection = 180 / (colors.length - 1); // constant for all sections
        const gradientAngle = 180 + angleOfSection * i - angleOfSection / 2; // sets the angle of the gradient to bisect its section
        defs.push({
            id: `gradient${i * 10}`,
            type: 'linearGradient',
            gradientTransform: `rotate(${gradientAngle} 0.5 0.5)`,
            colors: [
                { offset: 0, color: startColor },
                { offset: 100, color: endColor }
            ]
        });
    }
    // add pin def
    const pinDef = buildPinDef(pinColor);
    return [...defs, pinDef];
}

/**
 * Returns data for the Pie chart, evenly spaced, that aligns with range of displayed emissions values.
 * Value corresponds to the proportion of space taken on the chart.
 */
function buildGaugeData(length: number) {
    const data = [];
    for (let i = 0; i < length; i++) {
        data.push({ id: `${10 * (i + 1)}`, value: 10 });
    }
    return data;
}

/** Returns and array that matches data in Pie chart to correct segment fill pattern/color/gradient */
function buildGaugeFill(length: number) {
    const fill = [];
    for (let i = 0; i < length; i++) {
        const id = 10 * (i + 1);
        fill.push({
            match: { id: `${id}` },
            id: `gradient${id}`
        });
    }
    const pinFill = { match: { id: '' }, id: 'line' };
    return [...fill, pinFill];
}

/**
 * Builds pie chart gradient data and segment fill values needed to create emissions fuel gauge.
 *
 * Given 11 colors, builds a set of 10 graded color segments and their corresponding values.
 */
export function buildGaugeGradients(colors: string[], pinColor?: string) {
    const defs = buildGaugeDefs(colors, pinColor);
    const data = buildGaugeData(colors.length - 1);
    const fill = buildGaugeFill(colors.length - 1);
    return {
        data,
        defs,
        fill
    };
}
