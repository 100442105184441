import 'mapbox-gl/dist/mapbox-gl.css';

import React, { ForwardedRef, forwardRef, ReactElement, useCallback, useRef, useState } from 'react';
import Map from 'react-map-gl';

import DeckGL from '@deck.gl/react';
import { GoogleMapsProvider } from '@ubilabs/google-maps-react-hooks';

import { defaults } from '../../consts';
import { lightingEffect } from '../../effects';
import { BaseMapConfig, DeckGLRef, ReactDeckProps } from '../../types';
import GoogleBaseMap from './GoogleBaseMap';

/**
 * Base deck-gl or Google Map React component for all maps.
 * Includes a configurable static map and accepts deck.gl map layers
 * as an array of objects passed to the layers prop.
 */
export const BaseMap = forwardRef<DeckGLRef, ReactDeckProps & BaseMapConfig>(function BaseMapForwardRef(
    props: BaseMapConfig,
    ref: ForwardedRef<DeckGLRef>
): ReactElement {
    // Setup for getting the Deck.gl object through reference
    const deck = useRef<DeckGLRef | null>(null);
    const deckRefFn = useCallback((r: DeckGLRef | null) => {
        if (r) {
            deck.current = r;
            if (ref) {
                typeof ref === 'function' ? ref(r) : (ref.current = r);
            }
        }
    }, []);

    // Setup for getting the DOM element that Google Maps will
    // be loaded into.
    const [mapContainer, setMapContainer] = useState<HTMLDivElement>();
    const mapRef = useCallback((node: HTMLDivElement) => {
        setMapContainer(node);
    }, []);

    // Tooltip picking logic
    const { layers = [], pickingRadiusPixels = 5 } = props;

    const includes3dHex: boolean = layers.some(
        (layer) => layer && 'props' in layer && 'extruded' in layer.props && layer.props.extruded,
        false
    );

    if ('googleMapsAPIKey' in props) {
        // Google Maps specific logic
        const {
            gestureHandling,
            getTooltip,
            googleMapsAPIKey,
            initialViewState,
            mapId,
            onClick,
            onViewStateChange,
            viewState,
            restriction,
            maxZoom
        } = props;
        const lat = viewState?.latitude || initialViewState?.latitude || defaults.basemap.initialViewState.latitude;

        const lng = viewState?.longitude || initialViewState?.longitude || defaults.basemap.initialViewState.longitude;

        const googleViewState = viewState || initialViewState || defaults.basemap.initialViewState;

        return (
            <GoogleMapsProvider
                googleMapsAPIKey={googleMapsAPIKey}
                mapContainer={mapContainer}
                mapOptions={{
                    /** Set the default latitude and longitude */
                    center: { lat, lng },
                    /** Whether to allow clicking on Google points of interest, which opens a popup. */
                    clickableIcons: false,
                    /** Remove UI buttons on the Google Map in favor for our UI */
                    disableDefaultUI: true,
                    /** When a Google map is part of a page, gestureHandling specifies how
                     * a user can zoom the map.
                     * https://developers.google.com/maps/documentation/javascript/interaction
                     */
                    gestureHandling,
                    /** Allow the user to zoom by fractions instead of whole numbers */
                    isFractionalZoomEnabled: true,
                    /** Optional map id used for custom maps. */
                    mapId,
                    /** The default base map style.
                     * https://developers.google.com/maps/documentation/javascript/maptypes#BasicMapTypes
                     */
                    mapTypeId: mapId ? undefined : 'hybrid',
                    /** Show the distance scale */
                    scaleControl: true,
                    /**
                     * Map style customizations for decluttering and fine-tuning (only applied when there is no custom
                     * mapId).
                     *
                     * This styling is needed to fine-tune the satellite basemap specifically, as Cloud Styling
                     * (currently in Preview) isn't yet available for these tiles.
                     * See https://developers.google.com/maps/documentation/javascript/style-reference
                     */
                    ...(!mapId && {
                        styles: [
                            {
                                featureType: 'administrative',
                                elementType: 'labels',
                                stylers: [{ weight: '2' }]
                            },
                            {
                                featureType: 'administrative.neighborhood',
                                stylers: [{ visibility: 'off' }]
                            },
                            {
                                featureType: 'landscape.man_made',
                                stylers: [{ visibility: 'off' }]
                            },
                            {
                                featureType: 'poi',
                                stylers: [{ visibility: 'off' }]
                            },
                            {
                                featureType: 'road',
                                stylers: [{ visibility: 'off' }]
                            },
                            {
                                featureType: 'transit',
                                stylers: [{ visibility: 'off' }]
                            }
                        ]
                    }),
                    /** Set the tilt of the map in 3D view */
                    tilt: viewState?.pitch,
                    /** Set the default zoom */
                    zoom: viewState?.zoom,
                    /** Limit pan */
                    restriction,
                    maxZoom
                }}
            >
                <GoogleBaseMap
                    data-testid="google-base-map"
                    gestureHandling={gestureHandling}
                    getTooltip={getTooltip}
                    effects={includes3dHex ? [lightingEffect] : undefined}
                    pickingRadius={pickingRadiusPixels}
                    ref={mapRef}
                    onClick={onClick}
                    layers={layers}
                    viewState={googleViewState}
                    onViewStateChange={onViewStateChange}
                    deckRef={deckRefFn}
                />
            </GoogleMapsProvider>
        );
    }

    // Mapbox specific logic
    const {
        getTooltip,
        initialViewState,
        layerFilter,
        mapboxApiAccessToken = process.env.MAPBOX_PUBLIC_TOKEN,
        mapStyle = defaults.basemap.mapStyle,
        onViewStateChange,
        Scale,
        viewState,
        ...otherProps
    } = props;

    // build props for the DeckGL component, including layers if applicable
    const deckProps: Partial<ReactDeckProps> = {
        controller: true,
        effects: includes3dHex ? [lightingEffect] : undefined,
        layers: layers,
        layerFilter,
        onViewStateChange,
        pickingRadius: pickingRadiusPixels,
        useDevicePixels: false, // Provides render optimization
        ...otherProps
    };

    if (viewState) {
        deckProps.viewState = viewState;
    } else if (initialViewState) {
        deckProps.initialViewState = {
            ...defaults.basemap.initialViewState,
            ...initialViewState
        };
    } else {
        deckProps.initialViewState = defaults.basemap.initialViewState;
    }

    return (
        <Map reuseMaps {...viewState} mapboxAccessToken={mapboxApiAccessToken} mapStyle={mapStyle} styleDiffing={false}>
            <DeckGL {...deckProps} ref={deckRefFn} getTooltip={getTooltip} />
            {Scale}
        </Map>
    );
});

export default BaseMap;
