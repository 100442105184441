import { Box, Grid } from '@methanesat/ui-components';
import { ReactNode } from 'react';
import { MapControlAccordion } from '../../../MapControls';
import { MapControlAccordionProps } from '../../../MapControls/MapControlAccordion';
import { DrawerDivider } from '.';

export const MapDataContainer = ({
    HeaderItems,
    Body,
    Footer,
    accordion,
    AccordionProps
}: {
    HeaderItems?: ReactNode;
    Body?: ReactNode;
    Footer?: ReactNode;
    accordion?: boolean;
    AccordionProps?: MapControlAccordionProps;
}) => {
    const Header = (
        <Grid container justifyContent="space-between" flexDirection="column">
            {HeaderItems}
        </Grid>
    );

    const FooterWithDivider = (
        <>
            {Footer && (
                <>
                    <DrawerDivider />
                    {Footer}
                </>
            )}
        </>
    );
    const BodyWithDivider = (
        <>
            {Body && (
                <>
                    <DrawerDivider />
                    {Body}
                </>
            )}
        </>
    );
    return (
        <>
            {accordion ? (
                <MapControlAccordion HeaderContents={<Box>{Header}</Box>} {...AccordionProps}>
                    {BodyWithDivider}
                    {FooterWithDivider}
                </MapControlAccordion>
            ) : (
                <>
                    {Header}
                    {BodyWithDivider}
                    {FooterWithDivider}
                </>
            )}
        </>
    );
};
