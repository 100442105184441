import {
    Box,
    CSSColor,
    Grid,
    isMainPaletteKey,
    MainPaletteKeys,
    Typography,
    useTheme
} from '@methanesat/ui-components';

interface OneValueBarBaseProps {
    backgroundColor?: CSSColor | MainPaletteKeys;
    percentFill: number;
    fillColor?: MainPaletteKeys;
}

const OneValueBarBase = ({ backgroundColor = 'lightgrey', percentFill, fillColor = 'info' }: OneValueBarBaseProps) => {
    const theme = useTheme();
    const currentMode = theme.palette.mode;

    // handle fill color
    let themeFillColor;
    if (fillColor in theme.palette && theme.palette[fillColor]) {
        themeFillColor = theme.palette[fillColor][currentMode === 'dark' ? currentMode : 'main'];
    }

    // handle background color
    let themeBackgroundColor;
    if (isMainPaletteKey(backgroundColor)) {
        themeBackgroundColor = theme.palette[backgroundColor][currentMode === 'dark' ? currentMode : 'main'];
    }

    return (
        <Box height="10px" width="100%" sx={{ backgroundColor: themeBackgroundColor || backgroundColor }}>
            <Box height="100%" width={`${percentFill}%`} sx={{ backgroundColor: themeFillColor || fillColor }}></Box>
        </Box>
    );
};

interface OneValueBarLabelProps {
    displayValue?: boolean;
    text?: number | string;
    valueLabel?: number | string;
}

const OneValueBarLabeled = ({
    text,
    percentFill,
    valueLabel,
    ...baseProps
}: OneValueBarLabelProps & OneValueBarBaseProps) => {
    return (
        <Grid container>
            {/* labels */}
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="subtitle2">{text}</Typography>
                    </Grid>
                    <Grid item>{valueLabel && <Typography variant="subtitle2">{valueLabel}</Typography>}</Grid>
                </Grid>
            </Grid>
            {/* bar */}
            <Grid item xs={12}>
                <OneValueBarBase percentFill={percentFill} {...baseProps} />
            </Grid>
        </Grid>
    );
};

export const OneValueBar = ({
    backgroundColor,
    fillColor,
    percentFill,
    text,
    valueLabel
}: OneValueBarBaseProps & OneValueBarLabelProps) => {
    if (valueLabel || text) {
        const labeledBarProps: OneValueBarLabelProps = {
            ...(text && { text }),
            ...(valueLabel && { valueLabel })
        };
        return (
            <OneValueBarLabeled
                {...labeledBarProps}
                backgroundColor={backgroundColor}
                percentFill={percentFill}
                fillColor={fillColor}
            />
        );
    }
    return <OneValueBarBase backgroundColor={backgroundColor} percentFill={percentFill} fillColor={fillColor} />;
};
