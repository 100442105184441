import { ReactElement, ReactNode } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    ExpandMoreIcon
} from '@methanesat/ui-components';

export interface MapControlAccordionProps extends Omit<AccordionProps, 'children'> {
    /** Child elements to display inside an expanded accordion. */
    children?: ReactNode;
    /** Whether the accordion is initially shown expanded. */
    defaultExpanded?: boolean;
    /** Component(s)for a given layer's control's header. Built by MapLayerControl component. Includes label & optional checkbox to turn layer on/off */
    HeaderContents?: ReactNode;
}

/** Wrapper for expandable forms that update map layers.
 * Used in MapControlsDrawer and MultipleLayerDataDrawer.
 */
export const MapControlAccordion = ({
    children,
    HeaderContents,
    ...otherProps
}: MapControlAccordionProps): ReactElement => {
    return (
        <Accordion elevation={0} {...otherProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 1 }}>
                {HeaderContents}
            </AccordionSummary>
            {children && <AccordionDetails sx={{ paddingX: 1 }}>{children}</AccordionDetails>}
        </Accordion>
    );
};
