// for basins and targets
import { Grid, Typography } from '@methanesat/ui-components';

import { useTranslate } from '../../../../hooks';

export const GeoFeatureModalHeader = ({
    name,
    infraCategory,
    infraId
}: {
    name: string;
    infraCategory?: string;
    infraId?: string;
}) => {
    const t = useTranslate();
    return (
        <>
            <Grid item xs>
                <Typography variant="h5">{name}</Typography>
            </Grid>
            {(infraCategory || infraId) && (
                <>
                    {infraCategory && (
                        <Grid item xs="auto">
                            <Typography variant="caption" fontStyle="italic">
                                {t('emissionsMapPage.mapModals.common.categoryLabel')}: {infraCategory}
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};
