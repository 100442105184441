/**
 * Container element that lives outside of normal React app tree (implemented as a React Portal).
 */
export const TooltipModalContainer = () => {
    return (
        <button
            id="tooltip-modal-container"
            data-testid="tooltip-modal-container"
            aria-hidden="true"
            style={{
                display: 'none'
            }}
        >
            {/* Inner container for positioned tooltip. */}
            <div />
        </button>
    );
};

export default TooltipModalContainer;
