import { Alert, TipsAndUpdatesIcon, Typography } from '@methanesat/ui-components';
import { useTranslate } from '../../../../../hooks';

export const OGINearPlumesAlert = () => {
    const t = useTranslate();
    return (
        <Alert icon={<TipsAndUpdatesIcon />} severity="info">
            <Typography>{t('emissionsMapPage.infrastructure.ogiNearEmissions.plumes.uncertaintyNote')}</Typography>
        </Alert>
    );
};
