import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';

import { DeckGLRef, DeckProps, MapViewState } from '../../types';

interface GoogleBaseMapProps {
    deckRef: (r: DeckGLRef | null) => void;
    effects?: DeckProps['effects'];
    gestureHandling?: google.maps.MapOptions['gestureHandling'];
    getTooltip?: DeckProps['getTooltip'];
    layers: DeckProps['layers'];
    onClick?: DeckProps['onClick'];
    onViewStateChange?: (args: { viewState: Partial<MapViewState> }) => void;
    pickingRadius?: DeckProps['pickingRadius'];
    viewState: MapViewState;
}

/**
 * The component that renders the Google Base Map. It requires to be rendered
 * within the provider from the google-maps-react-hooks package.
 */
const GoogleBaseMap = forwardRef(function GoogleBaseMapForwardRef(
    {
        deckRef,
        effects,
        gestureHandling,
        getTooltip,
        layers,
        onClick,
        onViewStateChange,
        pickingRadius,
        viewState
    }: GoogleBaseMapProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const [overlay, setOverlay] = useState<GoogleMapsOverlay>();
    const map = useGoogleMap();
    overlay?.setProps({
        ...(effects && { effects }),
        getTooltip,
        layers,
        onClick,
        pickingRadius,
        // Possibly related to
        // https://developers.google.com/maps/documentation/javascript/reference/marker#Marker.getCursor
        getCursor: ({ isHovering }) => {
            if (map) {
                if (isHovering && gestureHandling !== 'none') {
                    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.draggableCursor
                    map.setOptions({ draggableCursor: 'pointer' });
                } else {
                    map.setOptions({ draggableCursor: '' });
                }
            }
            return 'grab';
        }
    });

    const updateViewState = (map: google.maps.Map) => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        const { clientHeight: height, clientWidth: width } = map.getDiv();
        const newViewState = {
            ...(center ? { latitude: center.lat(), longitude: center.lng() } : {}),
            ...(zoom ? { zoom: zoom - 1 } : {}),
            ...(height && width ? { height, width } : {})
        };
        if (center || zoom || (height && width)) {
            onViewStateChange?.({ viewState: newViewState });
        }
    };
    useEffect(() => {
        if (map) {
            // Fired when the viewport bounds have changed
            // https://developers.google.com/maps/documentation/javascript/reference/map#Map.bounds_changed
            const boundsListener = map.addListener('bounds_changed', () => updateViewState(map));
            // Fired when the map center property changes
            // https://developers.google.com/maps/documentation/javascript/reference/map#Map.center_changed
            const centerListener = map.addListener('center_changed', () => updateViewState(map));
            // Fired when the map zoom property changes
            // https://developers.google.com/maps/documentation/javascript/reference/map#Map.zoom_changed
            const zoomListener = map.addListener('zoom_changed', () => updateViewState(map));

            /**
             * The Proxy allows us to provide a common interface between this base map and the
             * Mapbox base map by redirecting the .deck call to the ._deck variable. The Proxy
             * also allows us to set the reference once we know that the _deck value as been
             * populated.
             *
             * For getCursor, change the cursor to click if there is something
             * clickable on the map. getCursor code is based on this pr comment:
             * https://github.com/visgl/deck.gl/issues/4548#issuecomment-1542622959
             */
            const localOverlay = new Proxy(new GoogleMapsOverlay({}), {
                get(target, prop, receiver) {
                    // Redirect any get calls to deck to the _deck value.
                    if (prop === 'deck') {
                        return target['_deck'];
                    }
                    return Reflect.get(target, prop, receiver);
                },
                set(target, prop, value, receiver) {
                    // When the _deck value is set with a truthy value, then we
                    // know that the deck overlay is ready to be interacted with.
                    if (prop === '_deck' && value) {
                        deckRef(receiver);
                    }
                    Reflect.set(target, prop, value, receiver);
                    return true;
                }
            });
            setOverlay(localOverlay);
            localOverlay.setMap(map);

            return () => {
                if (google.maps) {
                    google.maps.event.removeListener(boundsListener);
                    google.maps.event.removeListener(centerListener);
                    google.maps.event.removeListener(zoomListener);

                    if (localOverlay) {
                        localOverlay.finalize();
                    }
                }
            };
        }
    }, [map]);

    /**
     * This use effect is used to keep the redux store and google map in sync. When
     * we use the buttons to zoom on the map, this logic gets executed.
     */
    useEffect(() => {
        if (map) {
            let moveCameraOptions: google.maps.CameraOptions = {};
            const { latitude, longitude, zoom } = viewState;
            const center = map.getCenter();
            const lat = center?.lat();
            const lng = center?.lng();
            const mapZoom = map.getZoom();
            // If the values passed into the component are different than Google Map's
            // internal state, we add to an object to update Google Map's internal state.
            if (latitude !== undefined && longitude !== undefined && (latitude !== lat || longitude !== lng)) {
                moveCameraOptions = { ...moveCameraOptions, center: { lat: latitude, lng: longitude } };
            }
            if (mapZoom !== undefined && zoom !== mapZoom - 1) {
                moveCameraOptions = { ...moveCameraOptions, zoom: zoom + 1 };
            }
            // We only call moveCamera if the states are different. Otherwise,
            // if a user is panning or zooming and we call moveCamera, it stops
            // the pan or zoom action and creates a clunky experience.
            if (Object.keys(moveCameraOptions).length > 0) {
                map.moveCamera(moveCameraOptions);
            }
        }
    }, [map, viewState]);

    // Gesture handling allows the user to interact with the map. Google maps
    // will only respond to an updated gestureHandling value if we call
    // map.setOptions on it.
    useEffect(() => {
        map?.setOptions({ gestureHandling });
    }, [map, gestureHandling]);

    return <div ref={ref} style={{ width: '100%', height: '100%' }} />;
});

export default GoogleBaseMap;
