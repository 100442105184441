import { ReactNode } from 'react';

import { Box, Theme, Typography } from '@methanesat/ui-components';

import { MapControlAccordion } from './MapControlAccordion';
import { ToggleableLayerHeader } from './ToggleableLayerHeader';

/** Props passed to MapLayerControl component. */
interface MapLayerControlProps {
    /** Determines if a given layer should have the option to be toggled on/off. */
    alwaysEnabled?: boolean;
    /** Child elements to display inside an expanded accordion. */
    children?: ReactNode;
    /** Whether the control is initially shown expanded. */
    defaultExpanded?: boolean;
    /** Whether the control is disabled or interactive. */
    disabled?: boolean;
    /** Determines if a given layer's controls should be expandable. */
    expandable?: boolean;
    /** Whether or not the layer is currently selected. */
    checked?: boolean;
    /** optional property, can be used there are multiple sublayers that can be controlled separately */
    indeterminate?: boolean;
    /** Label for a layer's controls. Associated with its expanding accordion of controls, if applicable. */
    label: string;
    /** Reducer to update a given layer. */
    setLayerConfig: (config: { enabled: boolean }) => void;
}

/**
 * A wrapper component for map layer controls.
 *
 *   Handles logic of
 *
 *      * whether layer should have a checkbox or not
 *      * whether layer has additional controls or not
 *
 * and renders the appropriate components.
 *
 * MapControlsDrawer wraps any controls *not* already wrapped.
 */
export const MapLayerControl = ({
    alwaysEnabled,
    children,
    defaultExpanded = false,
    disabled = false,
    expandable = true,
    label,
    setLayerConfig,
    checked,
    indeterminate
}: MapLayerControlProps) => {
    /** Updates the layer(s)' config using `setLayerConfig`, updating the attribute specified */
    const updateLayerEnabled = (value: boolean) => {
        setLayerConfig({ enabled: value });
    };

    // header component to render if the layer must always stay enabled
    const AlwaysEnabledLayerHeader = (
        <Typography
            variant="body1"
            sx={{
                marginLeft: 4,
                marginY: 1.5
            }}
        >
            {label}
        </Typography>
    );

    const HeaderContents = !alwaysEnabled ? (
        <ToggleableLayerHeader
            data-testid={`id-${label}-toggle`}
            checked={!!checked}
            disabled={disabled}
            indeterminate={!!indeterminate}
            label={label}
            onChange={updateLayerEnabled}
        />
    ) : (
        AlwaysEnabledLayerHeader
    );

    const wrapperProps = {
        children,
        defaultExpanded,
        HeaderContents,
        label
    };

    if (expandable) return <MapControlAccordion {...wrapperProps} />;
    return (
        <Box
            {...{
                label
            }}
            sx={{
                paddingX: 1,
                paddingY: 1,
                // with :before pseudo-element, position style duplicates the accordion's divider
                position: 'relative',
                '&::before': {
                    position: 'absolute',
                    left: 0,
                    top: '-1px',
                    right: 0,
                    height: '1px',
                    content: '""',
                    opacity: 1,
                    backgroundColor: (theme: Theme) => theme.palette.divider
                }
            }}
        >
            {HeaderContents}
        </Box>
    );
};
