import { Divider, Skeleton } from '@methanesat/ui-components';

export const DataSkeleton = () => {
    return (
        <>
            <Skeleton data-testid="ogi-loading-skeleton" variant="text" height={70} sx={{ marginTop: 6 }}></Skeleton>
            <Divider sx={{ margin: 1, marginBottom: 2 }}></Divider>
            <Skeleton variant="rectangular" height={80}></Skeleton>
            <Divider sx={{ margin: 1, marginTop: 2 }}></Divider>
            <Skeleton variant="text" height={60}></Skeleton>
        </>
    );
};
