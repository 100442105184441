import { useDispatch } from 'react-redux';

import { Grid, GridProps, AddIcon, RemoveIcon, MapButton } from '@methanesat/ui-components';

import { decreaseEmissionsMapViewStateZoom, increaseEmissionsMapViewStateZoom } from '../../reducers';

export const EmissionsPageZoomControls = (props: GridProps) => {
    const dispatch = useDispatch();

    const zoomIn = () => {
        dispatch(increaseEmissionsMapViewStateZoom(1));
    };

    const zoomOut = () => {
        dispatch(decreaseEmissionsMapViewStateZoom(1));
    };

    return (
        <Grid container rowSpacing={1} textAlign="center" {...props}>
            <Grid item xs={12}>
                <MapButton onClick={zoomIn} size="small" data-testid="zoom-in-button" id="map-zoom-in-button">
                    <AddIcon />
                </MapButton>
            </Grid>
            <Grid item xs={12}>
                <MapButton size="small" onClick={zoomOut} data-testid="zoom-out-button" id="map-zoom-out-button">
                    <RemoveIcon />
                </MapButton>
            </Grid>
        </Grid>
    );
};
