import { ReactElement, useEffect, useRef } from 'react';

import { Box, CardContent, Grid, Tooltip, Typography, useTheme } from '@methanesat/ui-components';

import { useCurrentBreakpoint, useTranslate } from '../../hooks';
import { MapStyleRadioProps } from '../../types';
import { BelowNavDrawer, BelowNavDrawerProps } from '../BelowNavDrawer';
import { MapStyleRadio } from './MapStyleRadio';
import { getBreakpointsDown } from '../../utils/breakpoints';

/**
 * Props for MapControlsDrawer
 */
interface MapControlsDrawerProps extends BelowNavDrawerProps {
    /** Props passed on to radio buttons selecting the map style */
    StyleButtonProps?: MapStyleRadioProps;
    onWidth: (width: number) => void;
}

/**
 * Full-height side drawer for map controls.
 * Under the app bar on larger screens,
 * truly full-height on mobile.
 */
const MapControlsDrawer = ({
    children,
    StyleButtonProps,
    onWidth,
    ...drawerProps
}: MapControlsDrawerProps): ReactElement => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const t = useTranslate();
    const theme = useTheme();

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    return (
        <BelowNavDrawer {...drawerProps} paperRef={drawerRef}>
            {/* Extra bottom padding for iPad, see https://methanesat.atlassian.net/browse/DP-961 */}
            <Grid container spacing={1} paddingX={2} marginBottom={2}>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('mapControls.title')}</Typography>
                </Grid>
                <CardContent>
                    <Box>
                        <Typography variant="caption">{t('mapControls.exploreEmissions')}</Typography>
                        <Box
                            display="flex"
                            justifyContent="left"
                            columnGap="2rem"
                            sx={(theme) => ({ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(3) })}
                        >
                            <Typography variant="body1">{t('platforms.MethaneAIR.long')}</Typography>
                            <Tooltip title={t('mapControls.comingSoon')} disableFocusListener enterTouchDelay={0} arrow>
                                <Typography color="text.disabled">{t('platforms.MethaneSAT.long')}</Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                    {StyleButtonProps && <MapStyleRadio {...StyleButtonProps} />}
                    {children}
                </CardContent>
            </Grid>
        </BelowNavDrawer>
    );
};

export default MapControlsDrawer;
