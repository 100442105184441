import dynamic from 'next/dynamic';
import { Polygon } from 'geojson';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Gauge } from '@methanesat/charts';
import { colorArrayToRgb } from '@methanesat/colors';
import {
    Divider,
    Grid,
    Box,
    Typography,
    getFormattedDate,
    CalendarData,
    BarGradient,
    PlumeIcon,
    ColorRangeIcon,
    ColorRangeIconScale,
    useTheme
} from '@methanesat/ui-components';

import { TARGET_PALETTE_MISSING_DATA, TARGET_PALETTE_RANGES } from '../../../../../consts';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import {
    calculateMapZoomLevelForFeature,
    calculatePolygonCenter,
    useGetHistoricalSamplesForTarget,
    useTranslate
} from '../../../../../hooks';
import {
    selectPlatform,
    setEmissionsMapViewState,
    setSelectedFeatureParams,
    setTargetDate
} from '../../../../../reducers';
import { CaptureFeatureProperties, MethaneLayerIds } from '../../../../../types';
import { analytics, getFeatureLinkLabel, getColorRangeColors } from '../../../../../utils';
import {
    formatEmissions,
    formatPercentage,
    roundUsingSigFigs,
    formatTotalEmissionsMinified,
    isNumber
} from '../../../../../utils/numbers';
import { formatMeasurementTime } from '../../../../../utils/time';
import { TargetModalHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';

const greyRGB = TARGET_PALETTE_MISSING_DATA.map(colorArrayToRgb);

export interface TargetDrawerProps {
    properties: CaptureFeatureProperties | null;
    geometry: Polygon;
    id: string;
}

/**
 *  Implementing next/dynamic here allows us to use enableResizeObserver within the HorizontalScroll component.
 *  enableResizeObserver helps with right scrolling issues within the TargertDrawer
 */
const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

export const TargetDrawer = ({ properties, id }: TargetDrawerProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const t = useTranslate();
    useEffect(() => {
        analytics.openTargetDetails({
            name: `${id}`
        });
    }, [id]);

    const {
        basin: location,
        size_km2: sizeKm2,
        start_datetime: timeStart,
        end_datetime: timeEnd,
        net_total: totalKgHr,
        ps_count: pointSourceCount,
        ps_total: pointSourceTotalKgHr,
        target_id: targetId,
        coordinates: coordinates,
        collectionId: collectionId
    }: Partial<CaptureFeatureProperties> = properties as CaptureFeatureProperties;
    const allTargetDates = useGetHistoricalSamplesForTarget(targetId);
    const selectorDates = allTargetDates?.toReversed() ?? [];

    const shouldShowTotalEmissions = isNumber(totalKgHr);
    const platform = useSelector(selectPlatform);
    const label = collectionId && location && getFeatureLinkLabel(platform, id, location);
    const hasLinkProps = coordinates && timeEnd && label;
    const flightId = id.split('_')[0];

    const detailDataRows = [
        {
            label: t('emissionsMapPage.mapModals.target.measurementTaken'),
            value: formatMeasurementTime(timeStart, timeEnd)
        },
        ...(isNumber(sizeKm2)
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.target.sizeLabel'),
                      value: roundUsingSigFigs(sizeKm2, 'km²')
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.common.locationLabel'),
            value: location
        }
    ];

    const emissionCompositionDataRows = [
        {
            label: t(`emissionsMapPage.mapModals.target.pointSources.plural`, {
                pointSourceCount: pointSourceCount
            }),
            value: t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                kgHr: formatEmissions(pointSourceTotalKgHr || 0)
            })
        },
        {
            label: 'Dispersed sources',
            value: t('emissionsMapPage.mapModals.target.pendingData.general')
        },
        {
            label: t('emissionsMapPage.mapModals.target.totalLabel'),
            value: t('emissionsMapPage.mapModals.target.pendingData.total')
        }
    ];

    // Updating the necessary state variables to open the drawer for the selected target
    const handleDayClick = (day: CalendarData) => {
        // setting date to dayAtMidnight
        const dayAtMidnight = day.date.setUTCHours(0, 0, 0, 0);

        // since selector dates also include the features, get feature position information
        const selectedFeature = selectorDates.filter((t) => t.date.getTime() === dayAtMidnight)[0];
        // TODO: replace with turf.js centroid function
        const [lng, lat] = calculatePolygonCenter(selectedFeature.coordinates);
        dispatch(
            setEmissionsMapViewState({
                latitude: lat,
                longitude: lng,
                zoom: calculateMapZoomLevelForFeature(MethaneLayerIds.targets)
            })
        );

        // update target-specific date
        dispatch(setTargetDate({ date: dayAtMidnight, targetId }));

        // update URL
        dispatch(
            setSelectedFeatureParams({
                itemId: selectedFeature.id,
                collectionId: selectedFeature.collection,
                feature: MethaneLayerIds.targets,
                coordinates: [lng, lat]
            })
        );
    };

    const data = selectorDates.map((date) => ({
        color: date.color,
        selected: date.id === id,
        label: getFormattedDate(date.date),
        key: date.id,
        boxHeight: '50px',
        onClick: () => handleDayClick(date)
    }));

    const colorRangeIconColors = getColorRangeColors(theme, true);

    return (
        <Grid container sx={(theme) => ({ paddingBottom: theme.spacing(10) })}>
            {/* TargetModalHeader only reflects methaneAir right now, this needs looking at in the future*/}
            <TargetModalHeader
                name={`${t('emissionsMapPage.mapModals.target.title')}`}
                source={platform}
                flightId={flightId}
            />
            {/* Total emissions gauge */}
            <Grid
                container
                flexDirection="column"
                alignContent="center"
                sx={(theme) => ({ paddingTop: theme.spacing(1) })}
            >
                {shouldShowTotalEmissions ? (
                    <BarGradient
                        min={TARGET_PALETTE_RANGES.MAIR.MIN}
                        max={TARGET_PALETTE_RANGES.MAIR.MAX}
                        minLabel={formatTotalEmissionsMinified(TARGET_PALETTE_RANGES.MAIR.MIN)}
                        maxLabel={formatTotalEmissionsMinified(TARGET_PALETTE_RANGES.MAIR.MAX)}
                        value={totalKgHr}
                        valueText={formatEmissions(totalKgHr)}
                    />
                ) : (
                    <>
                        {/** Leaving old gauge component for pending data */}
                        <Gauge
                            gradientColors={greyRGB}
                            valueText={t('emissionsMapPage.mapModals.target.pendingData.total')}
                            units={null}
                            max={TARGET_PALETTE_RANGES.MAIR.MAX}
                            fontSize={16}
                            chartHeight={100}
                        />
                    </>
                )}
            </Grid>

            {/* Target emissions composition */}
            <Grid container sx={(theme) => ({ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) })}>
                <Typography variant="h4">{t('emissionsMapPage.mapModals.target.emissionComposition')}</Typography>
            </Grid>
            {totalKgHr && (
                <Grid
                    sx={(theme) => ({
                        width: '100%',
                        paddingLeft: theme.spacing(1),
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1)
                    })}
                >
                    {/** Updated composition of emissions section */}
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '6px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px'
                            }}
                        >
                            <ColorRangeIcon colors={colorRangeIconColors} scale={ColorRangeIconScale.linear} />
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 700
                                }}
                            >
                                {formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)}%
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 700
                                }}
                            >
                                {formatPercentage(pointSourceTotalKgHr || 0, totalKgHr)}%
                            </Typography>
                            <PlumeIcon sx={{ height: 24 }} colors={colorRangeIconColors} />
                        </Box>
                    </Box>
                    {/** Custom progress bar to match design.  This can be converted to a re-usable component if needed */}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            gap: '4px',
                            marginBottom: '8px'
                        }}
                    >
                        <Box
                            sx={(theme) => ({
                                height: '12px',
                                width: `${formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)}%`,
                                background: theme.palette.progressBar.dark
                            })}
                        />
                        <Box
                            sx={(theme) => ({
                                height: '12px',
                                width: `${formatPercentage(pointSourceTotalKgHr || 0, totalKgHr)}%`,
                                background: theme.palette.progressBar.light
                            })}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '6px'
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'start'
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 700
                                }}
                            >
                                Dispersed Sources
                            </Typography>
                            <Typography variant="subtitle1">
                                {t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                    kgHr: formatEmissions(totalKgHr - (pointSourceTotalKgHr || 0))
                                })}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'end'
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 700
                                }}
                            >
                                {t(`emissionsMapPage.mapModals.target.pointSources.plural`, {
                                    pointSourceCount: pointSourceCount
                                })}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                    kgHr: formatEmissions(pointSourceTotalKgHr || 0)
                                })}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
            {/** Leaving in Modal body for pending data */}
            {!shouldShowTotalEmissions && <DataRowsModalBody dataRows={emissionCompositionDataRows} />}

            {/** Historical Time Selector */}
            <Grid
                container
                sx={(theme) => ({
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(1)
                })}
            >
                <Grid item paddingBottom={1} xs={12}>
                    <Typography display={'inline-block'} variant="h4">
                        {t('emissionsMapPage.mapModals.common.readingsOverTime')}
                    </Typography>
                    <Typography marginLeft={2} display={'inline-block'} variant="h6">
                        {t('emissionsMapPage.mapModals.common.tapToView')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DynamicTimeSelector data-testid="time-selector-day-picker" data={data} enableResizeObserver />
                </Grid>
            </Grid>
            {/* At a glance */}
            <Grid container sx={(theme) => ({ paddingTop: theme.spacing(3), paddingBottom: theme.spacing(1) })}>
                <Typography variant="h4">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
            </Grid>
            <DataRowsModalBody dataRows={detailDataRows} />
            {/* Shareable link to feature */}
            {SHOW_SHAREABLE_FEATURE_LINK && hasLinkProps && (
                <>
                    <Divider flexItem sx={{ width: '100%', marginTop: 2 }} />
                    <FeatureLink
                        coordinates={calculatePolygonCenter(coordinates)}
                        itemId={id}
                        collectionId={collectionId}
                        featureType={MethaneLayerIds.targets}
                        date={timeEnd}
                        label={label}
                        platform={platform}
                    />
                </>
            )}
        </Grid>
    );
};
