import { Button, ArrowBackIcon } from '@methanesat/ui-components';

export const OGIDetailsExitButton = ({ text, onClick }: { text: string; onClick: () => void }) => {
    return (
        <Button
            data-testid="ogi-details-exit-button"
            variant="outlined"
            color="info"
            sx={{ backgroundColor: 'white' }}
            onClick={onClick}
            startIcon={<ArrowBackIcon />}
        >
            {text}
        </Button>
    );
};
