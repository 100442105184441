import { Grid, Typography } from '@methanesat/ui-components';
import { isNumber } from 'lodash';
import { formatEmissions } from '../../../../../utils/numbers';

import { useTranslate } from '../../../../../hooks';
import { getConfidencePercentage } from '../../../../../utils';
import { ReactNode } from 'react';

export const EmissionsHeadline = ({
    emissions,
    highEmissions,
    icon,
    lowEmissions,
    units,
    shouldFormatEmissions = true
}: {
    emissions: number;
    highEmissions?: number;
    icon?: ReactNode;
    lowEmissions?: number;
    shouldFormatEmissions?: boolean;
    units: string;
}) => {
    const t = useTranslate();
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={(theme) => ({ paddingTop: theme.spacing(3) })}
        >
            {icon && (
                <Grid item xs="auto" display="flex" marginRight={2}>
                    {icon}
                </Grid>
            )}
            <Grid item xs="auto">
                <Typography variant="h2" component="div" data-testid="emissionsheadline-value">
                    {shouldFormatEmissions ? formatEmissions(emissions) : Math.round(emissions)}
                </Typography>
            </Grid>
            <Grid item xs="auto">
                <Typography variant="h4" component="div">
                    {units}
                </Typography>
            </Grid>
            {(isNumber(lowEmissions) || isNumber(highEmissions)) && (
                <Grid item xs="auto">
                    <Typography variant="h4" component="div">
                        {t('emissionsMapPage.mapModals.emissions.confidence', {
                            confidencePercentage:
                                getConfidencePercentage(emissions, (lowEmissions ?? highEmissions) as number) || 24
                        })}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
