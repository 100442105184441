import React, { useRef, useEffect } from 'react';
import { chroma } from '@methanesat/maps';
import { Box, InfoIcon, Tooltip } from '@methanesat/ui-components';
import type { ReactElement } from 'react';

export interface ColorThermometerProps {
    accentuateLowestValue?: boolean;
    colors: { percent: number; color: string }[];
    height?: string;
    labels?: { percent: number; label: string; tooltip?: string }[];
}

const ColorThermometer = ({
    accentuateLowestValue = false,
    colors,
    labels,
    height = '150px'
}: ColorThermometerProps): ReactElement => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                canvas.height = parseFloat(height);
                canvas.width = 25;
                const domain = [0, canvas.height];

                // Using chroma.scale combined with chroma.domain allows us to snap the color scale
                // to a custom domain. Using the LCH color space allows for smoother color transitions.
                const customColorScale = chroma
                    .scale(colors.map((c) => c.color))
                    .mode('lch')
                    .domain(domain);

                // Draw the gradient
                for (let i = 0; i < canvas.height; i++) {
                    ctx.fillStyle = customColorScale(i).css();
                    ctx.fillRect(0, canvas.height - i, canvas.width, 1);
                }
            }
        }
    }, [colors]);

    const labelHeightPx = 18;

    return (
        <Box paddingTop={1} paddingBottom={1}>
            <Box display="flex" alignContent="center">
                <canvas
                    ref={canvasRef}
                    aria-label="Color legend"
                    role="presentation"
                    style={{
                        flexBasis: '25px'
                    }}
                />
                <Box display="flex" position="relative" fontSize="12px" flex="1">
                    {labels?.map(({ percent, label, tooltip }) => {
                        return (
                            <Box
                                top={`calc(${100 - percent}% - ${labelHeightPx / 2}px)`}
                                paddingLeft="1rem"
                                width="100%"
                                position="absolute"
                                key={percent}
                                sx={{
                                    /** Tick mark */
                                    '&::before': {
                                        content: '"—"',
                                        position: 'absolute',
                                        left: '-2px'
                                    },
                                    display: 'flex'
                                }}
                            >
                                {label}
                                {tooltip && (
                                    <Tooltip title={tooltip} sx={{ marginLeft: 0.5, fontSize: 20 }}>
                                        <InfoIcon />
                                    </Tooltip>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            {/* Slightly heightens the lowest value for visibility without disturbing the color/label alignment (see DP-4029). */}
            {!!accentuateLowestValue && (
                <Box
                    width="25px"
                    sx={{
                        color: 'white',
                        background: colors[0].color,
                        height: '10px'
                    }}
                />
            )}
        </Box>
    );
};

export default ColorThermometer;
