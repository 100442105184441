import React from 'react';

/** Contains the parameters for labeling the chart center */
type CenteredChartType = {
    chartHeight: number;
    chartLabel?: string | null;
    chartRating: number | string;
    chartWidth: number;
    fontSize?: number;
};

/** Contains the center location of the chart*/
type ChartCentersType = {
    centerX: number;
    centerY: number;
};

/** Centered Fuel Gauge Label */
const CenteredMetric =
    ({ chartLabel, chartRating, chartHeight, chartWidth, fontSize }: CenteredChartType) =>
    // eslint-disable-next-line react/display-name
    ({ centerX, centerY }: ChartCentersType) => {
        const fontSizeValue = fontSize || chartHeight / 5;
        return (
            <>
                <text
                    x={centerX}
                    y={centerY - chartHeight / 5}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: `${fontSizeValue}px`,
                        fontWeight: 600,
                        width: `${(chartWidth * 2) / 3}px`
                    }}
                >
                    {chartRating}
                </text>
                {chartLabel && (
                    <text
                        x={centerX}
                        y={centerY - chartHeight / 20}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                            fontSize: `${chartHeight / 10}px`,
                            fontWeight: 300
                        }}
                    >
                        {chartLabel}
                    </text>
                )}
            </>
        );
    };

export default CenteredMetric;
