import { useRef, useEffect, useState, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { tooltipSlice } from '../../reducers/tooltip';
import { useAppDispatch, useAppSelector, useTranslate } from '../../hooks';
import { log } from '@methanesat/log';

export interface TooltipModalProps {
    /** Modal content (halo and text).  */
    children: NonNullable<ReactNode>;
    /** Size of highlight halo. */
    haloSizePx: number;
    /**
     * CSS selector for the modal container.
     * Note: targeting non-DOM elements (e.g. canvas containers) not yet implemented.
     */
    modalSelector: string;
}

/**
 * Container for tooltips which can be dismissed by clicking anywhere outside the tooltip.
 * Implemented as a React Portal which lives outside the normal app DOM hierarchy.
 * With special handling needed for creating a client-only React Portal in Next.js
 * See https://github.com/vercel/next.js/blob/v14.2.0-canary.0/examples/with-portals/components/ClientOnlyPortal.js
 *
 * Note: targeting non-DOM elements inside deck.gl canvas not yet supported.
 */
export default function TooltipModal({ children, modalSelector, haloSizePx = 50 }: TooltipModalProps) {
    const dispatch = useAppDispatch();
    const t = useTranslate();

    const containerRef = useRef<Element>();
    const targetRef = useRef<Element>();
    const parentRef = useRef<Element>();

    const [mounted, setMounted] = useState(false);

    const targetSelector = useAppSelector((state) => state.tooltip.targetSelector);
    const parentSelector = useAppSelector((state) => state.tooltip.parentSelector);

    const hideContainer = useCallback(() => {
        dispatch(tooltipSlice.actions.hide());
    }, []);

    const centerHaloOnTarget = useCallback(
        (target: Element) => {
            const modalElement = containerRef.current?.firstElementChild;
            if (modalElement) {
                // Get target dimensions and positioning in order to center the halo.
                const {
                    top: targetTop,
                    left: targetLeft,
                    height: boundingHeight,
                    width: boundingWidth
                } = target.getBoundingClientRect();
                const computedStyle = getComputedStyle(target);

                let targetWidth: number, targetHeight: number;
                if (parseInt(computedStyle.width) && parseInt(computedStyle.height)) {
                    targetWidth = parseInt(computedStyle.width);
                    targetHeight = parseInt(computedStyle.height);
                } else {
                    targetWidth = boundingWidth;
                    targetHeight = boundingHeight;
                }

                /**
                 * Centers halo over target element.  Strategy is to first add half the target
                 * dimension, then subtract half the analogous halo dimension.  E.g. add
                 * halfTargetHeight then subtract halfHaloSizePx.
                 */
                const halfTargetHeight = targetHeight / 2;
                const halfTargetWidth = targetWidth / 2;
                const halfHaloSizePx = haloSizePx / 2;
                modalElement.setAttribute(
                    'style',
                    `position: absolute; top: ${targetTop + halfTargetHeight - halfHaloSizePx}px; left: ${targetLeft + halfTargetWidth - halfHaloSizePx}px;`
                );
            }
        },
        [containerRef.current, haloSizePx]
    );

    useEffect(() => {
        /**
         * Use DOM query methods since the modal lives outside the app's React DOM hierarchy.
         */
        containerRef.current = document.querySelector(modalSelector) ?? undefined;
        targetRef.current = document.querySelector(targetSelector) ?? undefined;
        parentRef.current = parentSelector ? document.querySelector(parentSelector) ?? undefined : undefined;

        if (typeof containerRef.current === 'undefined') {
            log.error(`TooltipModal: cannot find DOM element '${modalSelector}'`);
            return;
        }

        if (typeof targetRef.current === 'undefined') {
            log.error(`TooltipModal: cannot find DOM element '${targetSelector}'`);
            return;
        }

        const modalElement = containerRef.current.firstElementChild ?? undefined;

        if (typeof modalElement === 'undefined') {
            log.error(`TooltipModal: cannot find modal div inside container '${modalSelector}'`);
            return;
        }

        // Setup part 1: add event listeners.
        window.addEventListener('resize', hideContainer);
        const observer = new ResizeObserver((entries) => {
            entries.forEach(({ target }) => centerHaloOnTarget(target));
        });
        observer.observe(targetRef.current);

        // Setup part 2: add CSS styles and attributes.

        /**
         * Sets modal container to 100% cover the window, to provide a click target which dismisses the modal.
         */
        containerRef.current.setAttribute(
            'style',
            'display: block; position: absolute; top: 0; left: 0; z-index: 1500; background: transparent; border: 0;'
        );
        containerRef.current?.setAttribute('aria-hidden', 'false');
        containerRef.current?.setAttribute('aria-label', t('controls.close'));

        // Before displaying the element, scroll it into view.
        // If a parent is available, scroll the parent into view.
        if (parentRef.current) {
            parentRef.current.scrollIntoView({ behavior: 'instant' });
        } else {
            targetRef.current.scrollIntoView({ behavior: 'instant' });
        }

        // A hack that allows us to center the halo on the element once
        // animations are done. In order to undo this hack, we would need more
        // information about the element or its parent.
        let count = 0;
        // Keep centering the halo for half a second after the halo has been triggered
        const intervalId = setInterval(() => {
            count += 1;
            targetRef.current && centerHaloOnTarget(targetRef.current);
            if (count > 5) {
                count = 0;
                clearInterval(intervalId);
            }
        }, 100);
        // Show the halo
        setMounted(true);

        return () => {
            // Cleanup part 1: remove event listeners.
            clearInterval(intervalId);
            window.removeEventListener('resize', hideContainer);
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }

            // Cleanup part 2: remove CSS styles and attributes.
            containerRef.current?.setAttribute('style', 'display: none;');
            containerRef.current?.setAttribute('aria-hidden', 'true');
            modalElement?.firstElementChild?.setAttribute('style', '');
        };
    }, [modalSelector, centerHaloOnTarget, targetSelector]);

    const modalElement = containerRef.current?.firstElementChild ?? undefined;
    if (mounted && typeof modalElement === 'undefined') {
        log.error(`TooltipModal: cannot find modal div inside container.`);
        return;
    }

    return mounted && modalElement ? createPortal(children, modalElement) : null;
}
