import { Divider, Grid, Typography } from '@methanesat/ui-components';

import { useTranslate } from '../../../../../hooks';

/**
 * The drawer of information when clustered infrastructure is selected.
 */
export const ClusteredInfrastructureDrawer = ({ count, type }: { count: number; type: string }) => {
    const t = useTranslate();

    return (
        <>
            {/* Header */}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ display: 'inline' }}>
                        {t('emissionsMapPage.mapModals.infrastructure.clusteredTitle')}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            {/* Body */}
            <Typography>
                {t('emissionsMapPage.mapModals.infrastructure.clusteredBody', {
                    count,
                    type: t(`emissionsMapPage.infrastructure.categoriesPlural.${type}`).toLowerCase()
                })}
            </Typography>
        </>
    );
};
