import { ReactElement, useEffect, useRef, useState } from 'react';

import { Button, Container, Grid, Typography, useTheme } from '@methanesat/ui-components';
import { BelowNavDrawer, BelowNavDrawerProps } from '../BelowNavDrawer';
import { useCurrentBreakpoint, useTranslate } from '../../hooks';
import { ClosableDialog } from '../ClosableDialog';
import { CustomizeAreaEmissionsDownload } from '../CustomizeDownload';
import { getBreakpointsDown } from '../../utils/breakpoints';

type DownloadDataDrawerProps = Omit<BelowNavDrawerProps, 'children'> & {
    onWidth: (width: number) => void;
};

/**
 * Full-height side drawer for map controls.
 * Under the app bar on larger screens,
 * truly full-height on mobile.
 */
const DownloadDataDrawer = ({ onWidth, ...drawerProps }: DownloadDataDrawerProps): ReactElement => {
    const t = useTranslate();
    const theme = useTheme();
    const drawerRef = useRef<HTMLDivElement>(null);

    const [isCustomizationOpen, setIsCustomizationOpen] = useState(false);

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    const handleClose = () => setIsCustomizationOpen(false);

    return (
        <BelowNavDrawer {...drawerProps}>
            <Grid container spacing={1} paddingX={2} marginBottom={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ marginBottom: 4 }}>
                        {t('emissionsMapPage.downloadDataDrawer.title')}
                    </Typography>
                    <Typography variant="body1">{t('emissionsMapPage.downloadDataDrawer.description')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button data-testid="download-drawer-quick-download" variant="contained" disabled>
                        {t('emissionsMapPage.downloadDataDrawer.downloadComingSoon')}
                    </Button>
                </Grid>

                <ClosableDialog fullScreen open={isCustomizationOpen} onClose={handleClose}>
                    <Container maxWidth="xl">
                        <CustomizeAreaEmissionsDownload />
                    </Container>
                </ClosableDialog>
            </Grid>
        </BelowNavDrawer>
    );
};

export default DownloadDataDrawer;
