import { MapStyles } from '@methanesat/maps';
import { FormControl, FormControlLabel, ImageRadio, RadioGroup } from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';
import { MapStyleRadioProps } from '../../types';

/**
 * Base map layer control.
 */
export const MapStyleRadio = ({ mapStyle, onChange }: MapStyleRadioProps) => {
    const t = useTranslate();

    const styles: {
        value: (typeof MapStyles)[keyof typeof MapStyles];
        imageSrc: string;
        label: string;
    }[] = [
        {
            value: MapStyles.satelliteMuted,
            imageSrc: '/img/map-style-satellite.jpg',
            label: 'mapControls.satelliteMapStyle'
        },
        {
            value: MapStyles.dark,
            imageSrc: '/img/map-style-satellite-greyscale.jpg',
            label: 'mapControls.darkMapStyle'
        }
    ];

    return (
        <FormControl fullWidth component="fieldset">
            <RadioGroup
                aria-label="Select map style"
                data-testid="map-style-radio-group"
                name="map-style"
                value={mapStyle}
                onChange={onChange}
                sx={{ justifyContent: 'center' }}
                row
            >
                {styles.map((style) => (
                    <FormControlLabel
                        key={style.value}
                        labelPlacement="bottom"
                        value={style.value}
                        control={<ImageRadio sx={{ width: '70px' }} imageSrc={style.imageSrc} />}
                        label={t(style.label)}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default MapStyleRadio;
