import { Grid, Typography } from '@methanesat/ui-components';

import { useTranslate } from '../../../../hooks';

export const InfrastructureModalHeader = ({
    layerName,
    operatorName,
    sourceDate
}: {
    layerName?: string;
    operatorName?: string;
    sourceDate?: string;
}) => {
    const t = useTranslate();
    return (
        <>
            <Grid item xs>
                {layerName && (
                    <Grid item xs>
                        <Typography variant="h5" display="inline">
                            {layerName}
                        </Typography>
                        <Typography
                            variant="caption"
                            component="span"
                            marginLeft={1}
                            sx={{ verticalAlign: 'text-top' }}
                        >
                            ({t('emissionsMapPage.mapModals.common.featureTypeLabel')})
                        </Typography>
                    </Grid>
                )}
                {operatorName ? (
                    <>
                        <Typography variant="h5" sx={{ display: 'inline' }}>
                            {operatorName.toUpperCase()}
                        </Typography>
                        <Typography
                            variant="caption"
                            marginLeft={1}
                            sx={{ display: 'inline', verticalAlign: 'text-bottom' }}
                        >
                            ({t('emissionsMapPage.mapModals.infrastructure.operatorLabel')})
                        </Typography>
                    </>
                ) : (
                    <Typography variant="h5">
                        {t('emissionsMapPage.mapModals.infrastructure.unknownOperator').toUpperCase()}
                    </Typography>
                )}
                {sourceDate && (
                    <Grid item xs="auto">
                        <Typography variant="caption" fontStyle="italic">
                            {`${t('emissionsMapPage.mapModals.common.dataUpdatedLabel')}: ${sourceDate}`}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
