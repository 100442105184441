import { ClickableScaleControl, ScaleControlProps } from '@methanesat/maps';
import { useTheme } from '@methanesat/ui-components';

import { useCurrentBreakpoint } from '../../hooks';

const EmissionsPageMapScale = () => {
    const theme = useTheme();

    // get current breakpoint - for setting max width of the scale
    const breakpoint = useCurrentBreakpoint();

    const ScaleControlProps: ScaleControlProps = {
        maxWidth: theme.breakpoints.values[breakpoint] * 0.4 || document.body.clientWidth * 0.4,
        style: {
            borderColor: '#000000bf',
            color: '#000000bf',
            fontSize: 12,
            fontWeight: 500
        },
        position: 'bottom-right'
    };
    return <ClickableScaleControl {...ScaleControlProps} />;
};

export default EmissionsPageMapScale;
